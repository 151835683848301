// Import necessary libraries and components for the flight search functionality
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  searchFlights,
  fetchAirportsList,
} from '../../../services/flightService';
import { useTranslation } from 'react-i18next'; // For internationalization
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JourneyTypeDropdown from '../../../components/journeyTypeDropdown/JourneyTypeDropdown';
import FlightClassDropdown from '../../../components/flightClassDropdown/FlightClassDropdown';
import PassengerSelector from '../../../components/passengerSelector/PassengerSelector';
import { TextField, Button } from '@mui/material';
import AutocompleteResults from '../../../components/autocompleteResults/AutocompleteResults';
import { useBooking } from '../../../contexts/BookingContext';
import './FlightSearch.scss'; // Styles specific to the FlightSearch component

// The FlightSearch component handles flight search form input and logic.
const FlightSearch = () => {
  const { t } = useTranslation(); // Internationalization hook
  const navigate = useNavigate(); // React Router hook to navigate between pages
  const { bookingData, updateBookingData, resetFlightResults } = useBooking(); // Custom hook to handle booking context

  // Initialize state for form data with either the values from bookingData or default values.
  const [formData, setFormData] = useState({
    journeyType: bookingData.searchCriteria?.journeyType || 'OneWay', // Defaults to 'OneWay' if not available
    origin: bookingData.searchCriteria?.origin || '', // Empty string if no origin is selected yet
    destination: bookingData.searchCriteria?.destination || '', // Empty string for destination
    departureDate: bookingData.searchCriteria?.departureDate || '',
    returnDate: bookingData.searchCriteria?.returnDate || '',
    class: bookingData.searchCriteria?.class || 'Economy',
    adults: bookingData.searchCriteria?.adults || 1, // Default to 1 adult
    children: bookingData.searchCriteria?.children || 0,
    infants: bookingData.searchCriteria?.infants || 0,
  });

  // Separate states to handle airport codes and airport data fetched via API
  const [airportOriginCode, setAirportOriginCode] = useState(
    bookingData.searchCriteria?.airportOriginCode || ''
  );
  const [airportDestinationCode, setAirportDestinationCode] = useState(
    bookingData.searchCriteria?.airportDestinationCode || ''
  );

  const [loading, setLoading] = useState(false); // Loading state for when fetching data
  const [errors, setErrors] = useState({}); // Object to hold form validation errors
  const [isPassengerDropdownOpen, setPassengerDropdownOpen] = useState(false); // Control the passenger dropdown
  const [airportOptions, setAirportOptions] = useState([]); // Store fetched airports list
  const [filteredAirports, setFilteredAirports] = useState([]); // Filtered airports based on user input
  const [searchTerm, setSearchTerm] = useState(''); // User's input for searching airports
  const [anchorEl, setAnchorEl] = useState(null); // Reference to the anchor element for autocomplete results

  const passengerDropdownRef = useRef(null); // Reference for passenger dropdown
  const autocompleteRef = useRef(null); // Reference for airport search autocomplete results

  // Fetch the list of airports once the component mounts
  useEffect(() => {
    const fetchAirports = async () => {
      try {
        const airports = await fetchAirportsList(); // Fetch airports list via the API
        setAirportOptions(airports); // Save fetched airport list to state
      } catch (error) {
        console.error('Error fetching airports:', error); // Log any errors
      }
    };

    fetchAirports(); // Trigger fetching airports
  }, []);

  // Handle clicks outside the autocomplete dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target)
      ) {
        setAnchorEl(null); // Close autocomplete results if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle form input change and perform validation for specific fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value }); // Update form data state
    setErrors({ ...errors, [name]: '' }); // Clear any existing errors for the changed field
    validateField(name, value); // Trigger validation for the current field
  };

  // Validate individual fields based on the input name and value
  const validateField = (fieldName, value) => {
    const newErrors = { ...errors };
    const today = new Date().toISOString().split('T')[0]; // Get today's date in ISO format

    switch (fieldName) {
      case 'origin':
        if (!value || !airportOriginCode) {
          newErrors.origin = t('Please select a valid origin airport.');
        } else {
          delete newErrors.origin;
        }
        break;

      case 'destination':
        if (!value || !airportDestinationCode) {
          newErrors.destination = t(
            'Please select a valid destination airport.'
          );
        } else {
          delete newErrors.destination;
        }
        break;

      case 'departureDate':
        if (!value) {
          newErrors.departureDate = t('Departure date is required.');
        } else if (value < today) {
          newErrors.departureDate = t('Departure date cannot be in the past.');
        } else {
          delete newErrors.departureDate;
        }
        break;

      case 'returnDate':
        if (formData.journeyType === 'Return') {
          if (!value) {
            newErrors.returnDate = t(
              'Return date is required for a round trip.'
            );
          } else if (value < formData.departureDate) {
            newErrors.returnDate = t(
              'Return date cannot be before departure date.'
            );
          } else {
            delete newErrors.returnDate;
          }
        }
        break;

      default:
        break;
    }

    setErrors(newErrors); // Update error state with new or cleared errors
  };

  // Handle journey type change (OneWay, Return, etc.)
  const handleJourneyTypeChange = (journeyType) => {
    setFormData({ ...formData, journeyType });
    setErrors({ ...errors, journeyType: '' });
  };

  // Handle the change of flight class (Economy, Business, etc.)
  const handleClassChange = (flightClass) => {
    setFormData({ ...formData, class: flightClass });
    setErrors({ ...errors, class: '' });
  };

  // Handle changes to the passenger count
  const handlePassengersChange = (passengers) => {
    setFormData({ ...formData, ...passengers });
  };

  // Full form validation when submitting the form
  const validate = () => {
    const newErrors = {};
    const today = new Date().toISOString().split('T')[0]; // Get current date in ISO format

    // Maps for journey types and flight classes
    const journeyTypeMap = { OneWay: 1, Return: 2, 'Multi-city': 3 };
    const flightClassMap = {
      Economy: 3,
      'Premium Economy': 4,
      Business: 2,
      First: 1,
    };

    if (!formData.origin || !airportOriginCode) {
      newErrors.origin = t('Please select a valid origin airport.');
    }

    if (!formData.destination || !airportDestinationCode) {
      newErrors.destination = t('Please select a valid destination airport.');
    }

    if (!formData.departureDate) {
      newErrors.departureDate = t('Departure date is required.');
    } else if (formData.departureDate < today) {
      newErrors.departureDate = t('Departure date cannot be in the past.');
    }

    if (formData.journeyType === 'Return') {
      if (!formData.returnDate) {
        newErrors.returnDate = t('Return date is required for a round trip.');
      } else if (formData.returnDate < formData.departureDate) {
        newErrors.returnDate = t(
          'Return date cannot be before departure date.'
        );
      }
    }

    setErrors(newErrors); // Set errors state

    // Return validation result and mapped values for journey type and flight class
    return {
      isValid: Object.keys(newErrors).length === 0,
      journeyTypeId: journeyTypeMap[formData.journeyType],
      flightClassId: flightClassMap[formData.class],
    };
  };

  // Handle the submission of the flight search form
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setErrors({}); // Clear any existing errors

    const { isValid, journeyTypeId, flightClassId } = validate(); // Validate form and get mapped IDs

    if (isValid) {
      const submissionData = {
        ...formData,
        journeyType: journeyTypeId,
        class: flightClassId,
        airportOriginCode,
        airportDestinationCode,
      };

      console.log('Form data on submit:', submissionData);

      // Store search criteria in the booking context but don't fetch flights yet
      await updateBookingData({ searchCriteria: submissionData });

      // Reset flight results to trigger a new search in SelectFlight
      resetFlightResults();

      // Navigate to the flight selection page immediately
      navigate('/flights/select');
    }
  };

  // Effect to log booking data updates
  useEffect(() => {
    if (bookingData.flightResults) {
      console.log('Booking data after update:', bookingData);
    }
  }, [bookingData]);

  // Handle search for airport suggestions based on user input
  const handleAirportSearch = (inputValue, fieldName) => {
    setSearchTerm(inputValue); // Store user's input in state

    if (inputValue.length >= 3) {
      // Start filtering when input has at least 3 characters
      const filtered = airportOptions.filter((airport) =>
        `${airport.AirportCode} ${airport.AirportName} ${airport.City}`
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      );

      const mappedResults = filtered.map((airport) => ({
        name: airport.AirportName,
        code: airport.AirportCode,
        cityCountry: `${airport.City}, ${airport.Country}`,
        type: airport.AirportName.includes('All Airports') ? 'city' : 'airport',
      }));

      setFilteredAirports(mappedResults); // Set filtered airports to be displayed
      setAnchorEl(document.querySelector(`[name="${fieldName}"]`)); // Set anchor element for autocomplete
    } else {
      setFilteredAirports([]); // Clear the filtered airports if input is too short
      setAnchorEl(null);
    }
  };

  // Handle selection of an airport from the autocomplete list
  const handleSelectAirport = (result) => {
    const fieldName = anchorEl.getAttribute('name'); // Get which field (origin or destination) triggered the search

    if (fieldName === 'origin') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        origin: `${result.name} (${result.code})`,
      }));
      setAirportOriginCode(result.code); // Set the selected airport code for origin
    } else if (fieldName === 'destination') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        destination: `${result.name} (${result.code})`,
      }));
      setAirportDestinationCode(result.code); // Set the selected airport code for destination
    }

    setFilteredAirports([]); // Clear the autocomplete results after selection
    setSearchTerm(''); // Clear the search input
    setAnchorEl(null); // Close the autocomplete results

    validateField(fieldName, result.code); // Validate the selected field
  };

  // Calculate the total number of passengers for display
  const totalPassengers =
    formData.adults + formData.children + formData.infants;

  return (
    <div className="flight-search-container">
      <form className="flight-search-form" onSubmit={handleSubmit}>
        {/* Render dropdowns for selecting journey type and flight class */}
        <div className="search-options">
          <JourneyTypeDropdown
            selectedOption={formData.journeyType}
            onChange={handleJourneyTypeChange}
          />
          <FlightClassDropdown
            selectedClass={formData.class}
            onClassChange={handleClassChange}
          />

          {/* Passenger dropdown with toggle button */}
          <div className="dropdown" ref={passengerDropdownRef}>
            <button
              type="button"
              className="dropdown-toggle"
              onClick={() => setPassengerDropdownOpen(!isPassengerDropdownOpen)}
            >
              <PersonIcon />
              {totalPassengers}{' '}
              <span className="passenger-text">{t('Passenger')}</span>
              <ExpandMoreIcon />
            </button>
            {isPassengerDropdownOpen && (
              <PassengerSelector
                passengers={{
                  adults: formData.adults,
                  children: formData.children,
                  infants: formData.infants,
                }}
                onPassengersChange={handlePassengersChange}
              />
            )}
          </div>
        </div>

        {/* Input fields for origin, destination, and dates */}
        <div className="input-group">
          <div className="autocomplete-container">
            <TextField
              label={t('From City, airport or place')}
              name="origin"
              variant="outlined"
              className="custom-textfield"
              fullWidth
              error={!!errors.origin}
              helperText={errors.origin}
              onFocus={() => {
                setFilteredAirports([]);
                setAnchorEl(document.querySelector(`[name="origin"]`));
              }}
              onChange={(e) => {
                handleAirportSearch(e.target.value, 'origin');
                handleChange(e);
              }}
              value={formData.origin}
              required
            />
          </div>

          <div className="autocomplete-container">
            <TextField
              label={t('To City, airport or place')}
              name="destination"
              variant="outlined"
              className="custom-textfield"
              fullWidth
              error={!!errors.destination}
              helperText={errors.destination}
              onFocus={() => {
                setFilteredAirports([]);
                setAnchorEl(document.querySelector(`[name="destination"]`));
              }}
              onChange={(e) => {
                handleAirportSearch(e.target.value, 'destination');
                handleChange(e);
              }}
              value={formData.destination}
              required
            />
          </div>
        </div>

        {/* Display filtered airport suggestions in the autocomplete dropdown */}
        {filteredAirports.length > 0 && (
          <AutocompleteResults
            results={filteredAirports}
            searchTerm={searchTerm}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            onSelect={handleSelectAirport}
            ref={autocompleteRef}
          />
        )}

        {/* Date pickers for departure and return dates */}
        <div className="input-group">
          <TextField
            label={t('Departure')}
            name="departureDate"
            type="date"
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: new Date().toISOString().split('T')[0] }} // Prevent past date selection
            value={formData.departureDate}
            onChange={(e) => {
              handleChange(e);
              validateField('departureDate', e.target.value);
            }}
            variant="outlined"
            className="custom-textfield"
            fullWidth
            error={!!errors.departureDate}
            helperText={errors.departureDate}
            required
          />
          {formData.journeyType === 'Return' && (
            <TextField
              label={t('Return')}
              name="returnDate"
              type="date"
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: formData.departureDate }} // Return date must be after departure date
              value={formData.returnDate}
              onChange={(e) => {
                handleChange(e);
                validateField('returnDate', e.target.value);
              }}
              variant="outlined"
              className="custom-textfield"
              fullWidth
              error={!!errors.returnDate}
              helperText={errors.returnDate}
              required
            />
          )}
        </div>

        {/* Submit button for searching flights */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          className="explore-button"
          disabled={loading}
        >
          {loading ? t('Searching...') : t('Explore')}
        </Button>

        {errors.general && <p className="error-message">{errors.general}</p>}
      </form>
    </div>
  );
};

export default FlightSearch;
