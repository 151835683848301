// src/pages/NotFound.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>{t('404 Not found')}</h1>
      <p>{t('This page does not exist!')}</p>
      <Button variant="contained" color="primary" onClick={handleGoHome}>
        {t('Go Home')}
      </Button>
    </div>
  );
};

export default NotFound;
