// src/pages/Home/Home.js
import React from 'react';
import styles from './Home.module.scss';
import { useAuth } from '../../contexts/AuthContext';

const Home = () => {
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };
  return (
    <div className={styles['home-container']}>
      <h1>Welcome, {user && user.name}</h1>
      <p className={styles['welcome-message']}>This is the home page.</p>
      <button className={styles['logout-button']} onClick={handleLogout}>
        Logout
      </button>
      <div className={styles['content']}>Welcome to the Home Page!</div>
    </div>
  );
};

export default Home;
