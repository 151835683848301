// src/components/navbar/Navbar.js
import React from 'react';
import LanguageSwitcher from '../languageSwitcher/LanguageSwitcher';
import './Navbar.scss';
import { useTranslation } from 'react-i18next';
import Icons from '../../utils/icons/Icons';

const Navbar = () => {
  const { t } = useTranslation();
  return (
    <div className="navbar">
      <div className="logo">
        <img src="logo.svg" alt="Logo" />
        <span>{t('app_name')}</span>
      </div>
      <div className="icons">
        <Icons.SearchIcon className="icon" />
        <Icons.NotificationsIcon className="icon" />
        <div className="user">
          <img
            src="https://images.pexels.com/photos/11038549/pexels-photo-11038549.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load"
            alt="User"
          />
          <span>Jane</span>
        </div>
        <Icons.SettingsIcon className="icon" />
        <LanguageSwitcher /> {/* Add the language switcher here */}
      </div>
    </div>
  );
};

export default Navbar;
