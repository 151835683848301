// src/components/Layout/Layout.js
import React from 'react';
import Navbar from '../navbar/Navbar';
import Menu from '../menu/Menu';
import Footer from '../footer/Footer';
import './Layout.scss';

const Layout = ({ children }) => {
  return (
    <div className="main">
      <Navbar />
      <div className="container">
        <div className="menuContainer">
          <Menu />
        </div>
        <div className="contentContainer">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
