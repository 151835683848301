// src/data.ts
export const menu = [
  {
    id: 1,
    title: 'menu.main',
    roles: [
      'system_admin',
      'support_manager',
      'support_agent',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.dashboard',
        url: '/home',
        icon: 'HomeIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'menu.bookings_management',
    roles: [
      'system_admin',
      'support_manager',
      'support_agent',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.book_flight',
        url: '/flights/search',
        icon: 'FlightIcon',
        roles: [
          'system_admin',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
      {
        id: 2,
        title: 'menu.view_bookings',
        url: '/home',
        icon: 'FormatListBulletedIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'menu.budgets_management',
    roles: [
      'system_admin',
      'support_manager',
      'support_agent',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.create_budget',
        url: '/budgets/create',
        icon: 'AttachMoneyIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
      /*
      {
        id: 2,
        title: 'menu.allocate_budget',
        url: '/budgets/:id/allocate',
        icon: 'AssignmentTurnedInIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
      */
      {
        id: 3,
        title: 'menu.list_budget',
        url: '/budgets',
        icon: 'FormatListBulletedIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
  {
    id: 4,
    title: 'menu.expenses_management',
    roles: [
      'system_admin',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.submit_expense',
        url: '/expenses/create',
        icon: 'CreateIcon',
        roles: [
          'system_admin',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
      {
        id: 2,
        title: 'menu.list_expenses',
        url: '/expenses',
        icon: 'FormatListBulletedIcon',
        roles: [
          'system_admin',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
  {
    id: 5,
    title: 'menu.policies',
    roles: [
      'system_admin',
      'support_manager',
      'support_agent',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.create_policy',
        url: '/policies/create',
        icon: 'ListAltIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
      {
        id: 2,
        title: 'menu.list_policy',
        url: '/policies',
        icon: 'FormatListBulletedIcon',
        roles: [
          'system_admin',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
  {
    id: 6,
    title: 'menu.reports',
    roles: [
      'system_admin',
      'support_manager',
      'support_agent',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.bookings_reports',
        url: '/home',
        icon: 'DescriptionIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
      {
        id: 2,
        title: 'menu.budgets_reports',
        url: '/home',
        icon: 'BarChartIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
      {
        id: 3,
        title: 'menu.expenses_reports',
        url: '/home',
        icon: 'TrendingUpIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
  {
    id: 7,
    title: 'menu.users_management',
    roles: ['system_admin', 'corporate_admin', 'corporate_manager'],
    listItems: [
      {
        id: 1,
        title: 'menu.add_new_user',
        url: '/users/create',
        icon: 'PersonAddIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
      {
        id: 2,
        title: 'menu.list_users',
        url: '/users',
        icon: 'PeopleIcon',
        roles: ['system_admin', 'corporate_admin', 'corporate_manager'],
      },
    ],
  },
  {
    id: 8,
    title: 'menu.corporates_management',
    roles: ['system_admin', 'corporate_admin'],
    listItems: [
      {
        id: 1,
        title: 'menu.add_new_corporate',
        url: '/corporates/create',
        icon: 'PersonAddIcon',
        roles: ['system_admin'],
      },
      {
        id: 2,
        title: 'menu.list_corporates',
        url: '/corporates',
        icon: 'PeopleIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
    ],
  },
  {
    id: 9,
    title: 'menu.departments_management',
    roles: ['system_admin', 'corporate_admin'],
    listItems: [
      {
        id: 1,
        title: 'menu.add_new_department',
        url: '/departments/create',
        icon: 'PersonAddIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
      {
        id: 2,
        title: 'menu.list_departments',
        url: '/departments',
        icon: 'PeopleIcon',
        roles: ['system_admin', 'corporate_admin'],
      },
    ],
  },
  {
    id: 10,
    title: 'menu.settings',
    roles: [
      'system_admin',
      'support_manager',
      'support_agent',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
    listItems: [
      {
        id: 1,
        title: 'menu.profile',
        url: '/home',
        icon: 'PersonIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
      {
        id: 2,
        title: 'menu.settings',
        url: '/home',
        icon: 'SettingsIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
      {
        id: 3,
        title: 'menu.logout',
        url: '/home',
        icon: 'ExitToAppIcon',
        roles: [
          'system_admin',
          'support_manager',
          'support_agent',
          'corporate_admin',
          'corporate_manager',
          'corporate_employee',
        ],
      },
    ],
  },
];
