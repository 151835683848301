// src/services/budgetService.js
import axiosInstance from './axiosConfig';

/**
 * Fetch all budgets.
 *
 * @returns {Promise} A promise that resolves to the list of all budgets.
 */
export const fetchBudgets = () => {
  return axiosInstance
    .get('/v1/budgets')
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Fetch budget by ID.
 *
 * @param {number} id - The ID of the budget to fetch.
 * @returns {Promise} A promise that resolves to the budget data.
 */
export const fetchBudgetById = (id) => {
  return axiosInstance
    .get(`/v1/budgets/${id}`)
    .then((response) => {
      //console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Create a new budget.
 *
 * @param {object} budget - The budget data to create.
 * @returns {Promise} A promise that resolves to the created budget data.
 */
export const createBudget = (budget) => {
  return axiosInstance
    .post('/v1/budgets', budget)
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Update a budget by ID.
 *
 * @param {number} id - The ID of the budget to update.
 * @param {object} budget - The updated budget data.
 * @returns {Promise} A promise that resolves to the updated budget data.
 */
export const updateBudget = (id, budget) => {
  return axiosInstance
    .put(`/v1/budgets/${id}`, budget)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Allocate budget to departments and users.
 *
 * @param {number} id - The ID of the corporate budget to allocate.
 * @param {object} allocations - The allocation data for departments and users.
 * @returns {Promise} A promise that resolves to the allocation result.
 */
export const allocateBudget = (id, allocations) => {
  return axiosInstance
    .post(`/v1/budgets/${id}/allocate`, { allocations })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Fetch detailed budget by ID.
 *
 * @param {number} id - The ID of the budget to fetch detailed information.
 * @returns {Promise} A promise that resolves to the detailed budget data.
 */
export const fetchDetailedBudgetById = (id) => {
  return axiosInstance
    .get(`/v1/budgets/${id}/detailed`)
    .then((response) => {
      //console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
