import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchBudgetById, updateBudget } from '../../../services/budgetService';
import {
  fetchCorporate,
  fetchCorporates,
} from '../../../services/corporateService';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/AuthContext';
import './BudgetEdit.scss';

import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import Swal from 'sweetalert2';
import './BudgetEdit.scss';

const BudgetEdit = () => {
  const { t } = useTranslation();
  const { user } = useAuth(); // Get the logged-in user info
  const { id } = useParams();
  const navigate = useNavigate();
  const [budgetData, setBudgetData] = useState({
    title: '',
    description: '',
    amount: '',
    start_date: '',
    end_date: '',
    corporate_id: '',
    type: 'corporate',
  });
  const [errors, setErrors] = useState({});
  const [corporateName, setCorporateName] = useState('');
  const [corporates, setCorporates] = useState([]);

  useEffect(() => {
    fetchBudgetById(id)
      .then((response) => {
        if (user.role_id === 4 && response.corporate_id !== user.corporate_id) {
          navigate('/unauthorized');
          return;
        }
        setBudgetData(response);
        if (user.role_id === 4) {
          fetchCorporate(user.corporate_id)
            .then((response) => {
              if (response && response.company_name) {
                setCorporateName(response.company_name);
              } else {
                console.error('Error: Invalid response format:', response);
              }
            })
            .catch((error) =>
              console.error('Error fetching corporate:', error)
            );
        } else {
          fetchCorporates()
            .then((response) => {
              const filteredCorporates = response; // Changed to include the first corporate
              setCorporates(filteredCorporates);
            })
            .catch((error) =>
              console.error('Error fetching corporates:', error)
            );
        }
      })
      .catch((error) => {
        console.error('Error fetching budget:', error);
        navigate('/404');
      });
  }, [id, navigate, user.role_id, user.corporate_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBudgetData({ ...budgetData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!budgetData.title || !/^[a-zA-Z0-9\s]{4,}$/.test(budgetData.title)) {
      newErrors.title = t('budgetEdit.validation.titleInvalid');
    }
    if (!budgetData.description || budgetData.description.length < 10) {
      newErrors.description = t('budgetEdit.validation.descriptionRequired');
    }
    if (
      !budgetData.amount ||
      isNaN(budgetData.amount) ||
      budgetData.amount <= 0
    ) {
      newErrors.amount = t('budgetEdit.validation.amountInvalid');
    }
    if (!budgetData.start_date) {
      newErrors.startDate = t('budgetEdit.validation.startDateRequired');
    }
    if (!budgetData.end_date) {
      newErrors.endDate = t('budgetEdit.validation.endDateRequired');
    }
    if (
      budgetData.start_date &&
      budgetData.end_date &&
      budgetData.start_date >= budgetData.end_date
    ) {
      newErrors.endDate = t('budgetEdit.validation.endDateBeforeStartDate');
    }
    if (!budgetData.corporate_id) {
      newErrors.corporateId = t('budgetEdit.validation.corporateRequired');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});

    if (validate()) {
      updateBudget(id, budgetData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: t('budgetEdit.successTitle'),
            text: t('budgetEdit.successMessage'),
          });
          navigate('/budgets');
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            const newErrors = {};
            error.response.data.errors.forEach((err) => {
              switch (err.code) {
                case 'title_invalid':
                  newErrors.title = t('budgetEdit.validation.titleInvalid');
                  break;
                case 'description_required':
                  newErrors.description = t(
                    'budgetEdit.validation.descriptionRequired'
                  );
                  break;
                case 'amount_invalid':
                  newErrors.amount = err.message;
                  break;
                case 'start_date_required':
                  newErrors.startDate = t(
                    'budgetEdit.validation.startDateRequired'
                  );
                  break;
                case 'start_date_invalid':
                  newErrors.startDate = err.message;
                  break;
                case 'end_date_required':
                  newErrors.endDate = t(
                    'budgetEdit.validation.endDateRequired'
                  );
                  break;
                case 'end_date_invalid':
                  newErrors.endDate = t('budgetEdit.validation.endDateInvalid');
                  break;
                case 'end_date_before_start_date':
                  newErrors.endDate = t(
                    'budgetEdit.validation.endDateBeforeStartDate'
                  );
                  break;
                case 'corporate_required':
                  newErrors.corporateId = err.message;
                  break;
                case 'overlap':
                  newErrors.general = t(
                    'budgetEdit.validation.overlappingDatesError'
                  );
                case 'Updating_Budget_failed':
                  newErrors.general = err.message;
                case 'corporate_budget_invalid':
                  newErrors.general = err.message;
                  break;
                default:
                  newErrors.general = t('budgetEdit.validation.unknownError');
              }
            });
            setErrors(newErrors);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setErrors({ general: error.response.data.message });
          } else {
            console.error('Error updating budget:', error);
          }
        });
    }
  };

  const handleEndDateChange = (e) => {
    const { name, value } = e.target;
    if (budgetData.start_date && value < budgetData.start_date) {
      setErrors({
        ...errors,
        endDate: t('budgetEdit.validation.endDateBeforeStartDate'),
      });
    } else {
      setErrors({
        ...errors,
        endDate: '',
      });
      setBudgetData({ ...budgetData, [name]: value });
    }
  };

  const handleDateInput = (e) => {
    const { value } = e.target;
    if (/^\d{0,4}-\d{0,2}-\d{0,2}$/.test(value)) {
      e.target.value = value;
    } else {
      e.target.value = value.slice(0, 10);
    }
  };

  return (
    <Container className="edit-budget-container" maxWidth="sm">
      <Paper elevation={3} className="paper">
        <Typography variant="h4" component="h2" gutterBottom>
          {t('budgetEdit.editBudget')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label={t('budgetEdit.title')}
            name="title"
            value={budgetData.title}
            onChange={handleChange}
            error={!!errors.title}
            helperText={errors.title}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('budgetEdit.description')}
            name="description"
            value={budgetData.description}
            onChange={handleChange}
            multiline
            rows={4}
            error={!!errors.description}
            helperText={errors.description}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('budgetEdit.amount')}
            name="amount"
            type="number"
            value={budgetData.amount}
            onChange={handleChange}
            error={!!errors.amount}
            helperText={errors.amount}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('budgetEdit.startDate')}
            name="start_date"
            type="date"
            value={budgetData.start_date}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            onInput={handleDateInput}
            error={!!errors.startDate}
            helperText={errors.startDate}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('budgetEdit.endDate')}
            name="end_date"
            type="date"
            value={budgetData.end_date}
            onChange={handleEndDateChange}
            InputLabelProps={{ shrink: true }}
            onInput={handleDateInput}
            error={!!errors.endDate}
            helperText={errors.endDate}
            required
            fullWidth
            margin="normal"
            inputProps={{
              min: budgetData.start_date,
            }}
          />
          {user.role_id === 4 ? (
            <TextField
              label={t('budgetEdit.corporate')}
              value={corporateName}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              margin="normal"
            />
          ) : (
            <FormControl fullWidth margin="normal" error={!!errors.corporateId}>
              <InputLabel>{t('budgetEdit.corporate')}</InputLabel>
              <Select
                name="corporate_id"
                value={budgetData.corporate_id}
                disabled // Corporate name should not be editable
                onChange={handleChange}
                required
              >
                {corporates.map((corporate) => (
                  <MenuItem key={corporate.id} value={corporate.id}>
                    {corporate.company_name}
                  </MenuItem>
                ))}
                {budgetData.corporate_id &&
                  !corporates.some((c) => c.id === budgetData.corporate_id) && (
                    <MenuItem value={budgetData.corporate_id}>
                      {budgetData.corporate_name}
                    </MenuItem>
                  )}
              </Select>
              {errors.corporateId && (
                <Typography variant="body2" className="errorText">
                  {errors.corporateId}
                </Typography>
              )}
            </FormControl>
          )}
          {errors.general && (
            <Typography variant="body2" className="errorText">
              {errors.general}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className="submit-button"
          >
            {t('budgetEdit.update')}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default BudgetEdit;
