import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { fetchAllExpenses } from '../../../services/expenseService'; // Adjust import as needed
import EditIcon from '@mui/icons-material/Edit';
import ApproveIcon from '@mui/icons-material/CheckCircle';
import './ExpenseList.scss';

const ExpenseList = () => {
  const navigate = useNavigate();
  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    fetchAllExpenses().then((data) => setExpenses(data)); // Adjust based on your service structure
  }, []);

  const columns = [
    { field: 'full_name', headerName: 'Full Name', flex: 1.5 },
    { field: 'amount', headerName: 'Amount', flex: 1 },
    { field: 'expense_date', headerName: 'Date', flex: 1.5 },
    { field: 'company_name', headerName: 'Company Name', flex: 2 },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 3 },
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 1,
      renderCell: (params) => (
        <EditIcon
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/expenses/${params.row.id}/edit`)}
        />
      ),
    },
    {
      field: 'approve',
      headerName: 'Approve',
      flex: 1.2,
      renderCell: (params) => (
        <ApproveIcon
          style={{ cursor: 'pointer', color: 'green' }}
          onClick={() => navigate('/')}
        />
      ),
    },
  ];

  return (
    <div className="expense-list-container">
      <h2>Expense List</h2>
      <div className="expense-list">
        <DataGrid
          rows={expenses}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight
        />
      </div>
    </div>
  );
};

export default ExpenseList;
