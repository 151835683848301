import { useEffect } from 'react';

// Custom hook to log when a component is wrapped by a context
const useLogProvider = (providerName) => {
  useEffect(() => {
    console.log(`Component is wrapped by ${providerName}`);
  }, [providerName]);
};

export default useLogProvider;
