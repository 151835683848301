import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import './Login.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Toast from '../../components/common/Toast';

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError(t('validation.invalidEmail'));
      return;
    }

    if (!password) {
      setError(t('validation.weakPassword'));
      return;
    }

    try {
      await login(email, password);
      navigate('/'); // Redirect to home page after login
    } catch (error) {
      setError(t('loginError'));
    }
  };

  const handleCloseToast = () => {
    setError('');
  };

  return (
    <div className="login-container">
      <h2 className="login-header">asfar.app</h2>
      <form className="login-form" onSubmit={handleSubmit} noValidate>
        {error && <Toast message={error} onClose={handleCloseToast} />}
        <input
          type="email"
          value={email}
          placeholder={t('email')}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder={t('password')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">{t('login')}</button>
      </form>
    </div>
  );
};

export default Login;
