import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  fetchUser,
  updateUser,
  fetchUsersRoles,
  fetchReportingManagers,
  checkEmailExists,
  fetchUserLevels,
} from '../../../services/userService';
import { useTranslation } from 'react-i18next';
import './UserEdit.scss';
import { generatePassword } from '../../../utils/passwordGenerator/PasswordGenerator';
import { useAuth } from '../../../contexts/AuthContext';

const UserEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: loggedInUser } = useAuth();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    userType: '',
    userTypeId: '',
    corporateId: '',
    corporateName: '',
    managerId: '',
    levelId: '',
  });
  const [userTypes, setUserTypes] = useState([]);
  const [userLevels, setUserLevels] = useState([]);
  const [managers, setManagers] = useState([]);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [initialEmail, setInitialEmail] = useState('');

  useEffect(() => {
    fetchUser(id)
      .then((user) => {
        if (
          loggedInUser.role_id === 4 &&
          user.corporate_id !== loggedInUser.corporate_id
        ) {
          navigate('/unauthorized');
        } else {
          setFormData({
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            password: '',
            userType: user.role,
            userTypeId: user.role_id,
            corporateId: user.corporate_id,
            corporateName: user.corporate_name,
            managerId: user.manager_id || '',
            levelId: user.level_id || '',
          });
          setInitialEmail(user.email);
          setIsManager(user.is_manager);
          console.log('Fetched user data:', user);

          if (user.role_id !== 1 && user.role_id !== 4 && user.corporate_id) {
            fetchReportingManagers(user.role_id, user.corporate_id)
              .then((data) => {
                const filteredManagers = data.filter(
                  (manager) => manager.id !== user.id
                );
                setManagers(filteredManagers);
                console.log('Fetched managers:', filteredManagers);
              })
              .catch((error) =>
                console.error('Error fetching managers:', error)
              );
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching user:', error);
        navigate('/unauthorized');
      });

    fetchUsersRoles()
      .then((data) => {
        setUserTypes(data);
        console.log('Fetched user types:', data);
      })
      .catch((error) => console.error('Error fetching user types:', error));

    fetchUserLevels()
      .then((data) => {
        setUserLevels(data);
        console.log('Fetched user levels:', data);
      })
      .catch((error) => console.error('Error fetching user levels:', error));
  }, [id, navigate, loggedInUser.role_id, loggedInUser.corporate_id]);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
  };

  const validateName = (name) => {
    const re = /^[A-Za-z]{3,}$/;
    return re.test(name);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let newErrors = { ...errors };

    if (name === 'email') {
      if (!validateEmail(value)) {
        newErrors.email = t('userEdit.validation.invalidEmail');
      } else if (value !== initialEmail) {
        const emailExists = await checkEmailExists(value);
        if (emailExists) {
          newErrors.email = t('userEdit.validation.emailExists');
        } else {
          delete newErrors.email;
        }
      } else {
        delete newErrors.email;
      }
    }

    if (name === 'password' && !validatePassword(value)) {
      newErrors.password = t('userEdit.validation.weakPassword');
    } else {
      delete newErrors.password;
    }

    if ((name === 'firstName' || name === 'lastName') && !validateName(value)) {
      newErrors[name] = t('userEdit.validation.invalidName');
    } else {
      delete newErrors[name];
    }

    setErrors(newErrors);

    if (name === 'userTypeId') {
      setFormData((prevData) => ({ ...prevData, managerId: '' }));
      setManagers([]);
      if (
        parseInt(value) !== 1 &&
        parseInt(value) !== 4 &&
        formData.corporateId
      ) {
        fetchReportingManagers(value, formData.corporateId)
          .then((data) => {
            console.log('Type of manager.id:', typeof data[0]?.id);
            console.log('Type of id:', typeof id);

            const filteredManagers = data.filter(
              (manager) => manager.id !== parseInt(id, 10)
            );

            setManagers(filteredManagers);
            console.log(
              'Fetched managers on userType change:',
              filteredManagers
            );
          })
          .catch((error) => console.error('Error fetching managers:', error));
      }
    }
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setFormData({ ...formData, password: newPassword });

    if (!validatePassword(newPassword)) {
      setErrors({
        ...errors,
        password: t('userEdit.validation.weakPassword'),
      });
    } else {
      let newErrors = { ...errors };
      delete newErrors.password;
      setErrors(newErrors);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      password,
      userType,
      corporateId,
      managerId,
      levelId,
    } = formData;
    let newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = t('userEdit.validation.invalidEmail');
    }

    if (email !== initialEmail) {
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        newErrors.email = t('userEdit.validation.emailExists');
      }
    }

    if (password && !validatePassword(password)) {
      newErrors.password = t('userEdit.validation.weakPassword');
    }
    if (!validateName(firstName)) {
      newErrors.firstName = t('userEdit.validation.invalidName');
    }
    if (!validateName(lastName)) {
      newErrors.lastName = t('userEdit.validation.invalidName');
    }
    if (!userType) {
      newErrors.userType = t('userEdit.validation.userTypeRequired');
    }
    if (!levelId) {
      newErrors.levelId = t('userEdit.validation.levelIdRequired');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Stop the submission if there are errors
    }

    try {
      await updateUser(id, {
        ...formData,
        name: `${firstName} ${lastName}`,
        password: password || undefined, // Do not include password if it's empty
      });
      navigate('/users');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const newErrors = {};
        error.response.data.errors.forEach((err) => {
          switch (err.code) {
            case 'email_invalid':
              newErrors.email = t('userEdit.validation.invalidEmail');
              break;
            case 'email_exists':
              newErrors.email = t('userEdit.validation.emailExists');
              break;
            case 'password_weak':
              newErrors.password = t('userEdit.validation.weakPassword');
              break;
            case 'firstName_invalid':
              newErrors.firstName = t('userEdit.validation.invalidName');
              break;
            case 'lastName_invalid':
              newErrors.lastName = t('userEdit.validation.invalidName');
              break;
            case 'levelId_required':
              newErrors.levelId = t('userEdit.validation.levelIdRequired');
              break;
            default:
              newErrors.general = t('userEdit.validation.unknown_error');
          }
        });
        console.log('Errors:', newErrors);
        setErrors(newErrors);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrors({ general: error.response.data.message });
      } else {
        console.error('Error updating user:', error);
      }
    }
  };

  // Filter user types based on corporate
  const filteredUserTypes =
    formData.corporateId === 1
      ? userTypes.filter((type) => [1, 2, 3].includes(type.id)) // system_admin, support_manager, support_agent
      : userTypes.filter((type) => ![1, 2, 3].includes(type.id)); // All others

  return (
    <Paper className="user-edit-root">
      <Typography variant="h4" className="form-title">
        {t('userEdit.editUser')}
      </Typography>
      <form onSubmit={handleSubmit} className="user-edit-form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('userEdit.firstName')}
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.firstName}
              helperText={errors.firstName}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('userEdit.lastName')}
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.lastName}
              helperText={errors.lastName}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('userEdit.email')}
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.email}
              helperText={errors.email}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('userEdit.password')}
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              fullWidth
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
            <Button
              onClick={handleGeneratePassword}
              variant="contained"
              color="primary"
              className="generate-button"
            >
              {t('userEdit.generatePassword')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!!errors.userType}
              className="form-control"
            >
              <InputLabel
                className="textField-label select-label"
                sx={{ backgroundColor: 'white', px: 1 }}
              >
                {t('userEdit.userType')}
              </InputLabel>
              <Tooltip title={isManager ? t('userEdit.roleChangeTooltip') : ''}>
                <Select
                  name="userTypeId"
                  value={formData.userTypeId}
                  onChange={handleChange}
                  disabled={isManager} // Disable if the user is a manager
                >
                  {filteredUserTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.role_name}
                    </MenuItem>
                  ))}
                  {!userTypes.some(
                    (type) => type.id === formData.userTypeId
                  ) && (
                    <MenuItem value={formData.userTypeId}>
                      {formData.userType}
                    </MenuItem>
                  )}
                </Select>
              </Tooltip>
              <FormHelperText>{errors.userType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!!errors.corporateId}
              className="form-control"
            >
              <InputLabel
                className="textField-label select-label"
                sx={{ backgroundColor: 'white', px: 1 }}
              >
                {t('userEdit.corporateId')}
              </InputLabel>
              <Select
                name="corporateId"
                value={formData.corporateId}
                disabled // Corporate name should not be editable
              >
                <MenuItem
                  key={formData.corporateId}
                  value={formData.corporateId}
                >
                  {formData.corporateName}
                </MenuItem>
              </Select>
              <FormHelperText>{errors.corporateId}</FormHelperText>
            </FormControl>
          </Grid>
          {formData.userTypeId &&
            formData.userTypeId !== 1 &&
            formData.userTypeId !== 4 &&
            formData.corporateId && (
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  required
                  error={!!errors.managerId}
                  className="form-control"
                >
                  <InputLabel
                    className="textField-label select-label"
                    sx={{ backgroundColor: 'white', px: 1 }}
                  >
                    {t('userEdit.managerId')}
                  </InputLabel>
                  <Select
                    name="managerId"
                    value={formData.managerId}
                    onChange={handleChange}
                  >
                    {managers.map((manager) => (
                      <MenuItem key={manager.id} value={manager.id}>
                        {`${manager.first_name} ${manager.last_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.managerId}</FormHelperText>
                </FormControl>
              </Grid>
            )}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!!errors.levelId}
              className="form-control"
            >
              <InputLabel
                className="textField-label select-label"
                sx={{ backgroundColor: 'white', px: 1 }}
              >
                {t('userEdit.level')}
              </InputLabel>
              <Select
                name="levelId"
                value={formData.levelId}
                onChange={handleChange}
              >
                {userLevels.map((level) => (
                  <MenuItem key={level.id} value={level.id}>
                    {level.level_name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.levelId}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {errors.general && (
          <Typography color="error" variant="body2">
            {errors.general}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="submit-button"
        >
          {t('userEdit.updateUser')}
        </Button>
      </form>
    </Paper>
  );
};

export default UserEdit;
