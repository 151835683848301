import axiosInstance from './axiosConfig';

/**
 * Fetch all policies.
 *
 * @returns {Promise} A promise that resolves to the list of all policies.
 */
export const fetchPolicies = () => {
  return axiosInstance
    .get('/v1/policies')
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Fetch policy by ID.
 *
 * @param {number} id - The ID of the policy to fetch.
 * @returns {Promise} A promise that resolves to the policy data.
 */
export const fetchPolicyById = (id) => {
  return axiosInstance
    .get(`/v1/policies/${id}`)
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Create a new policy.
 *
 * @param {object} policy - The policy data to create.
 * @returns {Promise} A promise that resolves to the created policy data.
 */
export const createPolicy = (policy) => {
  return axiosInstance
    .post('/v1/policies', policy)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Update a policy by ID.
 *
 * @param {number} id - The ID of the policy to update.
 * @param {object} policy - The updated policy data.
 * @returns {Promise} A promise that resolves to the updated policy data.
 */
export const updatePolicy = (id, policy) => {
  return axiosInstance
    .put(`/v1/policies/${id}`, policy)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Assign a policy to users or departments.
 *
 * @param {number} id - The ID of the policy to assign.
 * @param {object} level - The level (group of users) to assign the policy to.
 * @returns {Promise} A promise that resolves to the assignment result.
 */
export const assignPolicy = (id, level) => {
  return axiosInstance
    .post(`/v1/policies/${id}/assign`, level)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Fetch fields with values.
 *
 * @returns {Promise} A promise that resolves to the list of fields with their values.
 */
export const fetchFieldsWithValues = () => {
  return axiosInstance
    .get('/v1/utils/fields')
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Fetch all available policy types.
 *
 * This method makes a GET request to the backend to retrieve the list of policy types.
 * The policy types include corporate, department, and personal policies.
 *
 * @returns {Promise} A promise that resolves to the list of policy types.
 */
export const fetchPolicyTypes = () => {
  return axiosInstance
    .get('/v1/utils/policy-types') // Fetch policy types from the backend
    .then((response) => response.data) // Return the response data (list of policy types)
    .catch((error) => {
      throw error; // Throw any error encountered during the request
    });
};
/**
 * Fetch the assignment data of a policy by its ID.
 *
 * This method makes a GET request to retrieve the policy assignment data,
 * including any exclusions related to the policy.
 *
 * @param {number} policyId - The ID of the policy to fetch the assignment for.
 * @returns {Promise} A promise that resolves to the policy assignment data.
 */
export const fetchPolicyAssignmentByPolicyId = (policyId) => {
  return axiosInstance
    .get(`/v1/policies/${policyId}/assignment`)
    .then((response) => {
      console.log('policy assignments:', response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching policy assignment:', error); // Log error for debugging
      throw error; // Throw the error to be handled by the caller
    });
};
