import axiosInstance from './axiosConfig';

export const fetchDepartments = () => {
  return axiosInstance
    .get('/v1/departments')
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const fetchDepartment = (id) => {
  return axiosInstance
    .get(`/v1/departments/${id}`)
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createDepartment = (department) => {
  return axiosInstance
    .post('/v1/departments', department)
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateDepartment = (id, department) => {
  return axiosInstance
    .put(`/v1/departments/${id}`, department)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Fetch departments by corporate ID.
 *
 * @param {string} corporateId - The ID of the corporate.
 * @returns {Promise} - A promise that resolves to the list of departments.
 */
export const fetchDepartmentsByCorporateId = (corporateId) => {
  return (
    axiosInstance
      .get(`/v1/corporates/${corporateId}/departments`)
      //.then((response) => response.data)
      .then((response) => {
        //console.log(response.data); // Log response data
        return response.data;
      })
      .catch((error) => {
        throw error;
      })
  );
};
