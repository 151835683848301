import React from 'react';
import './PassengerCounter.scss';

const PassengerCounter = ({
  type,
  ageRange,
  count,
  onDecrement,
  onIncrement,
  icon,
}) => {
  return (
    <div className="passenger-counter">
      <div className="passenger-info">
        <span className="passenger-icon">{icon}</span>
        <div>
          <p className="passenger-type">{type}</p>
          <p className="passenger-age">{ageRange}</p>
        </div>
      </div>
      <div className="passenger-controls">
        <button
          onClick={onDecrement}
          disabled={count === 0 || (type === 'Adults' && count === 1)}
          className="counter-button"
          type="button"
        >
          −
        </button>
        <span className="counter-value">{count}</span>
        <button onClick={onIncrement} className="counter-button" type="button">
          +
        </button>
      </div>
    </div>
  );
};

export default PassengerCounter;
