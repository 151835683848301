import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  InputLabel,
  Grid,
  Card,
  CardContent,
  IconButton,
  Modal,
  Box,
  Switch,
  FormHelperText,
} from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import Swal from 'sweetalert2';
import {
  fetchPolicyById,
  updatePolicy,
  fetchFieldsWithValues,
  fetchPolicyTypes, // Import the new fetch function for policy types
} from '../../../services/policyService';
import { fetchCorporates } from '../../../services/corporateService';
import { useAuth } from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import './PolicyEdit.scss';

const steps = [
  'policyEdit.policyInfo',
  'policyEdit.addRules',
  'policyEdit.reviewPolicy',
];

const PolicyEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [policy, setPolicy] = useState({
    title: '',
    description: '',
    corporateId: '',
    policyTypeId: '', // Add policy_type_id to the state
    rules: [],
  });
  const [newRules, setNewRules] = useState([]);
  const [deletedRules, setDeletedRules] = useState([]);
  const [modifiedRules, setModifiedRules] = useState([]);
  const [corporates, setCorporates] = useState([]);
  const [fields, setFields] = useState([]);
  const [policyTypes, setPolicyTypes] = useState([]); // State for policy types
  const [openRuleModal, setOpenRuleModal] = useState(false);
  const [newRule, setNewRule] = useState({
    field: '',
    operator: '',
    value: '',
  });
  const { user } = useAuth();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchPolicyById(id)
      .then((data) => {
        // Redirect to 404 if corporate admin tries to access policy not in their corporate
        if (user.role_id === 4 && data.corporate_id !== user.corporate_id) {
          navigate('/404');
          return;
        }
        setPolicy({
          title: data.title,
          description: data.description,
          corporateId: data.corporate_id,
          policyTypeId: data.policy_type_id, // Set policy type from fetched data
          rules: data.rules.map((rule) => ({
            ...rule,
            status: 'existing',
          })),
        });
      })
      .catch((error) => {
        console.error('Error fetching policy:', error);
        navigate('/404');
      });

    fetchCorporateData();
    fetchFieldsData();
    fetchPolicyTypesData(); // Fetch the policy types
  }, [id, navigate, user.role_id, user.corporate_id]);

  const fetchCorporateData = async () => {
    const response = await fetchCorporates();
    if (user.role_id === 1) {
      response.shift(); // Remove the first corporate if user is system_admin
    }
    setCorporates(response);
  };

  const fetchFieldsData = async () => {
    try {
      const fieldsData = await fetchFieldsWithValues();
      setFields(fieldsData);
    } catch (error) {
      console.error('Error fetching fields data:', error);
    }
  };

  const fetchPolicyTypesData = async () => {
    try {
      const policyTypesData = await fetchPolicyTypes(); // Fetch policy types from backend
      setPolicyTypes(policyTypesData);
    } catch (error) {
      console.error('Error fetching policy types:', error);
    }
  };

  const handleNext = () => {
    const newErrors = {};
    if (activeStep === 0) {
      if (!policy.title || policy.title.length < 4) {
        newErrors.title = t('policyEdit.validation.policyTitleInvalid');
      }
      if (!policy.description || policy.description.length < 10) {
        newErrors.description = t(
          'policyEdit.validation.policyDescriptionInvalid'
        );
      }
      if (!policy.corporateId) {
        newErrors.corporateId = t('policyEdit.validation.corporateRequired');
      }
      if (!policy.policyTypeId) {
        newErrors.policyTypeId = t('policyEdit.validation.policyTypeRequired');
      }
    }
    if (activeStep === 1 && policy.rules.length === 0) {
      newErrors.rules = t('policyEdit.validation.atLeastOneRule');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPolicy({ ...policy, [name]: value });
  };

  const handleRuleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedRules = policy.rules.map((rule, i) => {
      if (i === index) {
        const modifiedRule = { ...rule, [name]: value };
        if (rule.status !== 'new') {
          setModifiedRules([...modifiedRules, modifiedRule]);
        }
        return modifiedRule;
      }
      return rule;
    });
    setPolicy({ ...policy, rules: updatedRules });
  };

  const handleRemoveRule = (index) => {
    const ruleToRemove = policy.rules[index];
    if (ruleToRemove.status !== 'new') {
      setDeletedRules([...deletedRules, ruleToRemove]);
    } else {
      setNewRules(newRules.filter((rule) => rule !== ruleToRemove));
    }
    const updatedRules = policy.rules.filter((_, i) => i !== index);
    setPolicy({ ...policy, rules: updatedRules });
  };

  const handleSubmit = async () => {
    const updatedPolicy = {
      ...policy,
      newRules,
      deletedRules,
      modifiedRules,
    };

    try {
      await updatePolicy(id, updatedPolicy);
      Swal.fire(
        t('policyEdit.validation.success'),
        t('policyEdit.validation.policyUpdatedSuccess'),
        'success'
      );
      setActiveStep(0);
      navigate(`/policies`);
    } catch (error) {
      Swal.fire(
        t('policyEdit.validation.error'),
        t('policyEdit.validation.policyUpdatedError'),
        'error'
      );
    }
  };

  const handleOpenRuleModal = () => {
    setOpenRuleModal(true);
  };

  const handleCloseRuleModal = () => {
    setOpenRuleModal(false);
    setNewRule({
      field: '',
      operator: '',
      value: '',
    });
  };

  const handleAddRule = () => {
    const isDuplicate = policy.rules.some(
      (rule) =>
        rule.field_id === newRule.field &&
        rule.operator === newRule.operator &&
        rule.value_id === newRule.value
    );

    const isRedundant = policy.rules.some((rule) => {
      if (rule.field_id === newRule.field) {
        const ruleValue = rule.value_id;
        const newRuleValue = newRule.value;

        if (!isNaN(parseFloat(ruleValue)) && !isNaN(parseFloat(newRuleValue))) {
          return (
            (rule.operator === '<=' &&
              newRule.operator === '>=' &&
              parseFloat(ruleValue) === parseFloat(newRuleValue)) ||
            (rule.operator === '>=' &&
              newRule.operator === '<=' &&
              parseFloat(ruleValue) === parseFloat(newRuleValue))
          );
        }

        if (isNaN(parseFloat(ruleValue)) && isNaN(parseFloat(newRuleValue))) {
          return (
            (rule.operator === '<=' &&
              newRule.operator === '>=' &&
              ruleValue === newRuleValue) ||
            (rule.operator === '>=' &&
              newRule.operator === '<=' &&
              ruleValue === newRuleValue)
          );
        }
      }
      return false;
    });

    const isContradictory = policy.rules.some((rule) => {
      if (rule.field_id === newRule.field) {
        const ruleValue = rule.value_id;
        const newRuleValue = newRule.value;

        if (!isNaN(parseFloat(ruleValue)) && !isNaN(parseFloat(newRuleValue))) {
          return (
            (rule.operator === '<=' &&
              newRule.operator === '>=' &&
              parseFloat(ruleValue) === parseFloat(newRuleValue)) ||
            (rule.operator === '>=' &&
              newRule.operator === '<=' &&
              parseFloat(ruleValue) === parseFloat(newRuleValue)) ||
            (rule.operator === '==' &&
              newRule.operator === '!=' &&
              parseFloat(ruleValue) === parseFloat(newRuleValue)) ||
            (rule.operator === '!=' &&
              newRule.operator === '==' &&
              parseFloat(ruleValue) === parseFloat(newRuleValue))
          );
        }

        if (isNaN(parseFloat(ruleValue)) && isNaN(parseFloat(newRuleValue))) {
          return (
            (rule.operator === '<=' &&
              newRule.operator === '>=' &&
              ruleValue === newRuleValue) ||
            (rule.operator === '>=' &&
              newRule.operator === '<=' &&
              ruleValue === newRuleValue) ||
            (rule.operator === '==' &&
              newRule.operator === '!=' &&
              ruleValue === newRuleValue) ||
            (rule.operator === '!=' &&
              newRule.operator === '==' &&
              ruleValue === newRuleValue)
          );
        }
      }
      return false;
    });

    const newErrors = {};
    if (isDuplicate) {
      newErrors.newRule = t('policyEdit.validation.duplicateRule');
    } else if (isRedundant) {
      newErrors.newRule = t('policyEdit.validation.redundantRule');
    } else if (isContradictory) {
      newErrors.newRule = t('policyEdit.validation.contradictoryRule');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      const field = fields.find((f) => f.id === newRule.field);
      const value = field
        ? field.values.find((v) => v.id === newRule.value)
        : null;

      const newRuleWithStatus = {
        field_id: newRule.field,
        field_label: field ? field.label : newRule.field,
        operator: newRule.operator,
        value_id: newRule.value,
        field_value: value ? value.value : newRule.value,
        enabled: true, // Ensure new rule has enabled field set to true
        status: 'new',
      };

      setNewRules([...newRules, newRuleWithStatus]);
      setPolicy({
        ...policy,
        rules: [...policy.rules, newRuleWithStatus],
      });
      handleCloseRuleModal();
    }
  };

  const handleToggleRule = (index) => {
    setPolicy((prevPolicy) => {
      const updatedRules = prevPolicy.rules.map((rule, i) => {
        if (i === index) {
          const modifiedRule = { ...rule, enabled: !rule.enabled };
          if (rule.status !== 'new') {
            setModifiedRules((prevModifiedRules) => [
              ...prevModifiedRules.filter((r) => r.rule_id !== rule.rule_id),
              modifiedRule,
            ]);
          }
          return modifiedRule;
        }
        return rule;
      });
      return { ...prevPolicy, rules: updatedRules };
    });
  };

  const getFieldLabel = (fieldId) => {
    const field = fields.find((f) => f.id === fieldId);
    return field ? field.label : fieldId;
  };

  const getValueLabel = (fieldId, valueId) => {
    const field = fields.find((f) => f.id === fieldId);
    const value = field ? field.values.find((v) => v.id === valueId) : null;
    return value ? value.value : valueId;
  };

  return (
    <Container className="policy-edit-container">
      <Paper elevation={3} className="paper">
        <Typography variant="h4" component="h2" gutterBottom>
          {t('policyEdit.editPolicy')}
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === 0 && (
            <div className="step-content">
              <TextField
                label={t('policyEdit.policyTitle')}
                name="title"
                value={policy.title}
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
                error={!!errors.title}
                helperText={errors.title}
              />
              <TextField
                label={t('policyEdit.policyDescription')}
                name="description"
                value={policy.description}
                onChange={handleChange}
                multiline
                rows={4}
                required
                fullWidth
                margin="normal"
                error={!!errors.description}
                helperText={errors.description}
              />
              <FormControl
                fullWidth
                margin="normal"
                error={!!errors.corporateId}
              >
                <InputLabel>{t('policyEdit.corporate')}</InputLabel>
                <Select
                  name="corporateId"
                  value={policy.corporateId}
                  onChange={handleChange}
                  required
                >
                  {corporates.map((corporate) => (
                    <MenuItem key={corporate.id} value={corporate.id}>
                      {corporate.company_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.corporateId}</FormHelperText>
              </FormControl>

              {/* New Policy Type Dropdown */}
              <FormControl
                fullWidth
                margin="normal"
                error={!!errors.policyTypeId}
              >
                <InputLabel>{t('policyEdit.policyType')}</InputLabel>
                <Select
                  name="policyTypeId"
                  value={policy.policyTypeId}
                  onChange={handleChange}
                  required
                >
                  {policyTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.policy_type}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.policyTypeId}</FormHelperText>
              </FormControl>

              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  color="primary"
                >
                  {t('policyEdit.next')}
                </Button>
              </Box>
            </div>
          )}
          {activeStep === 1 && (
            <div className="step-content">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h4">
                    {t('policyEdit.addRules')}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={handleOpenRuleModal}
                  >
                    {t('policyEdit.addRule')}
                  </Button>
                  {errors.rules && (
                    <Typography
                      variant="body2"
                      color="error"
                      className="validation-error"
                    >
                      {errors.rules}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div className="rule-list">
                    {policy.rules.map((rule, index) => (
                      <Card
                        key={index}
                        className="rule-card"
                        sx={{ marginBottom: 2 }}
                      >
                        <CardContent className="rule-content">
                          <Typography>
                            {getFieldLabel(rule.field_id)}
                          </Typography>
                          <Typography>{rule.operator}</Typography>
                          <Typography>
                            {getValueLabel(rule.field_id, rule.value_id)}
                          </Typography>
                        </CardContent>
                        <div className="rule-actions">
                          <Switch
                            checked={rule.enabled}
                            onChange={() => handleToggleRule(index)}
                            color="primary"
                          />
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleRemoveRule(index)}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      </Card>
                    ))}
                  </div>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button onClick={handleBack}>{t('policyEdit.back')}</Button>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  color="primary"
                  ml={2}
                >
                  {t('policyEdit.next')}
                </Button>
              </Box>
            </div>
          )}
          {activeStep === 2 && (
            <div className="step-content">
              <Typography variant="h6" component="h4" gutterBottom>
                {t('policyEdit.reviewPolicy')}
              </Typography>
              <Box mb={2}>
                <Card sx={{ marginBottom: 2 }}>
                  <CardContent>
                    <Typography variant="h6" component="h5">
                      {t('policyEdit.policyInfo')}
                    </Typography>
                    <Typography variant="body1">
                      <strong>{t('policyEdit.title')}:</strong> {policy.title}
                    </Typography>
                    <Typography variant="body1">
                      <strong>{t('policyEdit.description')}:</strong>{' '}
                      {policy.description}
                    </Typography>
                    <Typography variant="body1">
                      <strong>{t('policyEdit.corporateName')}:</strong>{' '}
                      {
                        corporates.find((c) => c.id === policy.corporateId)
                          ?.company_name
                      }
                    </Typography>
                    <Typography variant="body1">
                      <strong>{t('policyEdit.policyType')}:</strong>{' '}
                      {
                        policyTypes.find(
                          (type) => type.id === policy.policyTypeId
                        )?.policy_type
                      }
                    </Typography>
                    <Button
                      onClick={() => setActiveStep(0)}
                      variant="outlined"
                      color="primary"
                    >
                      {t('policyEdit.edit')}
                    </Button>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h5">
                      {t('policyEdit.rules')}
                    </Typography>
                    {policy.rules.map((rule, index) => (
                      <Typography key={index} variant="body2">
                        {getFieldLabel(rule.field_id)} {rule.operator}{' '}
                        {getValueLabel(rule.field_id, rule.value_id)}
                      </Typography>
                    ))}
                    <Button
                      onClick={() => setActiveStep(1)}
                      variant="outlined"
                      color="primary"
                    >
                      {t('policyEdit.edit')}
                    </Button>
                  </CardContent>
                </Card>
              </Box>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button onClick={handleBack}>{t('policyEdit.back')}</Button>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  ml={2}
                >
                  {t('policyEdit.update')}
                </Button>
              </Box>
            </div>
          )}
        </div>
      </Paper>

      <Modal
        open={openRuleModal}
        onClose={handleCloseRuleModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Modal background overlay
        }}
      >
        <Box
          sx={{
            backgroundColor: '#384256',
            color: 'white',
            padding: 3,
            outline: 'none',
            borderRadius: 2,
            maxWidth: 500,
            width: '100%',
            boxShadow: 3,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {t('policyEdit.addCustomRule')}
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('policyEdit.field')}</InputLabel>
            <Select
              name="field"
              value={newRule.field}
              onChange={(e) =>
                setNewRule({ ...newRule, field: e.target.value })
              }
              error={!!errors.newRule}
            >
              {fields.map((field) => (
                <MenuItem key={field.id} value={field.id}>
                  {field.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.newRule}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('policyEdit.operator')}</InputLabel>
            <Select
              name="operator"
              value={newRule.operator}
              onChange={(e) =>
                setNewRule({ ...newRule, operator: e.target.value })
              }
            >
              <MenuItem value="==">{t('policyEdit.operatorEquals')}</MenuItem>
              <MenuItem value="!=">
                {t('policyEdit.operatorNotEquals')}
              </MenuItem>
              <MenuItem value="<">{t('policyEdit.operatorLessThan')}</MenuItem>
              <MenuItem value=">">
                {t('policyEdit.operatorGreaterThan')}
              </MenuItem>
              <MenuItem value="<=">
                {t('policyEdit.operatorLessThanOrEqual')}
              </MenuItem>
              <MenuItem value=">=">
                {t('policyEdit.operatorGreaterThanOrEqual')}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('policyEdit.value')}</InputLabel>
            <Select
              name="value"
              value={newRule.value}
              onChange={(e) =>
                setNewRule({ ...newRule, value: e.target.value })
              }
              disabled={!newRule.field}
            >
              {newRule.field &&
                fields
                  .find((field) => field.id === newRule.field)
                  ?.values.map((val) => (
                    <MenuItem key={val.id} value={val.id}>
                      {val.value}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <Box
            sx={{
              marginTop: 2,
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
            }}
          >
            <Button onClick={handleCloseRuleModal} color="secondary">
              {t('policyEdit.cancel')}
            </Button>
            <Button onClick={handleAddRule} color="primary">
              {t('policyEdit.addRule')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default PolicyEdit;
