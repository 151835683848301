import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBooking } from '../../../contexts/BookingContext';
import { completeBooking } from '../../../services/flightService';
import './PassengerInfo.scss';

const PassengerInfo = () => {
  const navigate = useNavigate();
  const { bookingData, updateBookingData } = useBooking();

  const [passengerData, setPassengerData] = useState({
    passengers: [],
  });

  const [errors, setErrors] = useState({});

  // Extracting necessary details for flight summary
  const selectedFlight = bookingData?.selectedFlight || {};
  const flightSegments =
    selectedFlight?.FareItinerary?.OriginDestinationOptions?.[0]
      ?.OriginDestinationOption?.[0]?.FlightSegment || {};
  const originAirportCode =
    flightSegments?.DepartureAirportLocationCode || 'N/A';
  const destinationAirportCode =
    flightSegments?.ArrivalAirportLocationCode || 'N/A';
  const departureTime = flightSegments?.DepartureDateTime || 'N/A';
  const arrivalTime = flightSegments?.ArrivalDateTime || 'N/A';
  const airlineCode = flightSegments?.MarketingAirlineCode || 'N/A';
  const flightNumber = flightSegments?.FlightNumber || 'N/A';

  const {
    adults = 0,
    children = 0,
    infants = 0,
  } = bookingData?.searchCriteria || {};

  useEffect(() => {
    const generatePassengers = () => {
      let passengerList = [];
      let passengerCount = { Adult: 1, Child: 1, Infant: 1 };

      for (let i = 0; i < adults; i++) {
        passengerList.push({
          type: 'Adult',
          label: `Adult ${passengerCount.Adult++}`,
          title: '', // Add title field
          firstName: '',
          lastName: '',
          dob: '',
          passportNumber: '',
          passportExpiry: '',
        });
      }

      for (let i = 0; i < children; i++) {
        passengerList.push({
          type: 'Child',
          label: `Child ${passengerCount.Child++}`,
          title: '', // Add title field
          firstName: '',
          lastName: '',
          dob: '',
          passportNumber: '',
          passportExpiry: '',
        });
      }

      for (let i = 0; i < infants; i++) {
        passengerList.push({
          type: 'Infant',
          label: `Infant ${passengerCount.Infant++}`,
          title: '', // Add title field
          firstName: '',
          lastName: '',
          dob: '',
          passportNumber: '', // Added passport number for infants
          passportExpiry: '', // Added passport expiry for infants
        });
      }
      setPassengerData({ passengers: passengerList });
    };

    generatePassengers();
  }, [adults, children, infants]);

  const validateInputs = () => {
    let newErrors = {};
    passengerData.passengers.forEach((passenger, index) => {
      if (!passenger.title) newErrors[`title_${index}`] = 'Title is required'; // Title validation
      if (!passenger.firstName)
        newErrors[`firstName_${index}`] = 'First Name is required';
      if (!passenger.lastName)
        newErrors[`lastName_${index}`] = 'Last Name is required';
      if (!passenger.dob)
        newErrors[`dob_${index}`] = 'Date of Birth is required';
      if (!passenger.passportNumber) {
        newErrors[`passportNumber_${index}`] = 'Passport Number is required'; // Now mandatory for all passengers
      }
      if (!passenger.passportExpiry) {
        newErrors[`passportExpiry_${index}`] = 'Passport Expiry is required'; // Now mandatory for all passengers
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (index, field, value) => {
    const newPassengerData = [...passengerData.passengers];
    newPassengerData[index][field] = value;
    setPassengerData({ passengers: newPassengerData });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Passenger Data:', passengerData);
    if (!validateInputs()) return;

    try {
      const flightId =
        bookingData?.selectedFlight?.FareItinerary?.AirItineraryFareInfo
          ?.FareSourceCode || 'Flight ID missing';
      const sessionId = bookingData?.session_id || 'Session ID missing';
      const fareSourceCode =
        bookingData?.fare_source_code || 'Fare Source Code missing';
      const fareType =
        bookingData?.selectedFlight?.FareItinerary?.AirItineraryFareInfo
          ?.FareType || 'Fare Type missing'; // Get the fare type

      const response = await completeBooking(flightId, {
        session_id: sessionId,
        fare_source_code: fareSourceCode,
        fareType: fareType, // Include fareType in the payload
        passengerInfo: passengerData.passengers,
      });

      updateBookingData({
        passengers: passengerData.passengers,
        bookingConfirmation: response,
      });

      console.log('Booking completed:', response);
      // Handle the response depending on whether the booking was successful
      if (response.BookFlightResponse?.BookFlightResult?.Success) {
        // Success case
        navigate('/flights/ticket', {
          state: {
            status: response.BookFlightResponse.BookFlightResult.Status,
            uniqueID: response.BookFlightResponse.BookFlightResult.UniqueID,
            ticketNumber:
              response.BookFlightResponse.BookFlightResult.ticketNumber || null, // Optional
            ticketTimeLimit:
              response.BookFlightResponse.BookFlightResult.TktTimeLimit, // Optional for non-LCC
          },
        });
      } else {
        // Handle booking failure, if there's an error in the response
        console.error(
          'Booking failed:',
          response.BookFlightResponse?.BookFlightResult?.Errors
        );
        alert(
          `Booking failed: ${
            response.BookFlightResponse?.BookFlightResult?.Errors
              ?.ErrorMessage || 'Unknown error'
          }`
        );
      }
    } catch (error) {
      console.error('Error completing booking:', error);
      alert('An error occurred while completing the booking.');
    }
  };

  if (!bookingData || !bookingData.searchCriteria) {
    return <div>Error: Booking data or search criteria are missing.</div>;
  }

  return (
    <div className="passenger-info">
      <h1>Passenger Information</h1>

      <div className="flight-details">
        <h2>Flight Summary</h2>
        <p>
          <strong>Flight:</strong> {airlineCode} {flightNumber}
        </p>
        <p>
          <strong>From:</strong> {originAirportCode}
        </p>
        <p>
          <strong>To:</strong> {destinationAirportCode}
        </p>
        <p>
          <strong>Departure:</strong> {departureTime}
        </p>
        <p>
          <strong>Arrival:</strong> {arrivalTime}
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        {passengerData.passengers.map((passenger, index) => (
          <div className="passenger-row" key={index}>
            <h3>{passenger.label}</h3>
            <div className="passenger-fields">
              <div className="form-group">
                <label>Title</label>
                <select
                  value={passenger.title}
                  onChange={(e) =>
                    handleInputChange(index, 'title', e.target.value)
                  }
                >
                  <option value="">Select Title</option>
                  {passenger.type === 'Adult' ? (
                    <>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                    </>
                  ) : (
                    <>
                      <option value="Master">Master</option>
                      <option value="Miss">Miss</option>
                    </>
                  )}
                </select>
                {errors[`title_${index}`] && (
                  <span className="error-text">{errors[`title_${index}`]}</span>
                )}
              </div>

              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  value={passenger.firstName}
                  onChange={(e) =>
                    handleInputChange(index, 'firstName', e.target.value)
                  }
                />
                {errors[`firstName_${index}`] && (
                  <span className="error-text">
                    {errors[`firstName_${index}`]}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  value={passenger.lastName}
                  onChange={(e) =>
                    handleInputChange(index, 'lastName', e.target.value)
                  }
                />
                {errors[`lastName_${index}`] && (
                  <span className="error-text">
                    {errors[`lastName_${index}`]}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label>Date of Birth</label>
                <input
                  type="date"
                  value={passenger.dob}
                  onChange={(e) =>
                    handleInputChange(index, 'dob', e.target.value)
                  }
                />
                {errors[`dob_${index}`] && (
                  <span className="error-text">{errors[`dob_${index}`]}</span>
                )}
              </div>

              <div className="form-group">
                <label>Passport Number</label>
                <input
                  type="text"
                  value={passenger.passportNumber}
                  onChange={(e) =>
                    handleInputChange(index, 'passportNumber', e.target.value)
                  }
                />
                {errors[`passportNumber_${index}`] && (
                  <span className="error-text">
                    {errors[`passportNumber_${index}`]}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label>Passport Expiry Date</label>
                <input
                  type="date"
                  value={passenger.passportExpiry}
                  onChange={(e) =>
                    handleInputChange(index, 'passportExpiry', e.target.value)
                  }
                />
                {errors[`passportExpiry_${index}`] && (
                  <span className="error-text">
                    {errors[`passportExpiry_${index}`]}
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default PassengerInfo;
