import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Paper,
  Alert,
  IconButton,
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import Swal from 'sweetalert2';
import {
  fetchPolicyById,
  assignPolicy,
  fetchPolicyAssignmentByPolicyId,
} from '../../../services/policyService';
import { fetchDepartmentsByCorporateId } from '../../../services/departmentService';
import {
  fetchUsersByDepartmentId,
  fetchUsersByCorporateId,
  fetchUsersRoles,
} from '../../../services/userService';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/AuthContext';
import './PolicyAssign.scss';

const PolicyAssign = () => {
  const { t } = useTranslation(); // i18n translation hook
  const navigate = useNavigate(); // React Router navigation hook
  const { id } = useParams(); // Get policy ID from the URL parameters
  const { user } = useAuth(); // Get the currently logged-in user information from AuthContext

  // State variables for managing the policy data, departments, roles, employees, and UI state
  const [policy, setPolicy] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState('');
  const [exclusions, setExclusions] = useState([{ type: '', value: '' }]);
  const [error, setError] = useState(null);

  // Fetch policy details and related data when the component mounts or when 'id' changes
  useEffect(() => {
    const fetchPolicyData = async () => {
      try {
        const policyData = await fetchPolicyById(id); // Fetch policy by ID

        // Redirect to 404 if the corporate admin tries to access a policy not in their corporate
        if (
          user.role_id === 4 &&
          policyData.corporate_id !== user.corporate_id
        ) {
          navigate('/404');
          return;
        }
        setPolicy(policyData); // Set the fetched policy data

        // Fetch related data based on policy type
        if (policyData.policy_type_id === 1) {
          // Corporate policy type
          const departments = await fetchDepartmentsByCorporateId(
            policyData.corporate_id
          );
          setDepartments(departments);

          const users = await fetchUsersByCorporateId(policyData.corporate_id);
          setEmployees(users);

          // Fetch and filter roles to include only roles with role_type 'client'
          const allRoles = await fetchUsersRoles();
          const clientRoles = allRoles.filter(
            (role) => role.role_type === 'client'
          );
          setRoles(clientRoles);

          // Fetch existing assignment data
          const assignments = await fetchPolicyAssignmentByPolicyId(id);

          if (assignments && assignments.length > 0) {
            const assignment = assignments[0]; // Use the first assignment if multiple exist

            const initialEntity =
              assignment.department_id ||
              assignment.corporate_id ||
              assignment.user_id;

            setSelectedEntity(initialEntity); // Set the initial selected entity

            if (assignment.exclusions) {
              const initialExclusions = assignment.exclusions.map(
                (exclusion) => ({
                  type: exclusion.exclusion_type,
                  value: exclusion.exclusion_value,
                })
              );
              setExclusions(initialExclusions);

              // Apply initial exclusions to the employees list
              filterAndApplyExclusions(users, initialExclusions);
            } else {
              // If there are no exclusions, set the full list of employees
              setFilteredEmployees(users);
            }
          } else {
            // If no assignments are found, set the full list of employees
            setFilteredEmployees(users);
          }
        } else if (policyData.policy_type_id === 2) {
          // Department policy type
          const departments = await fetchDepartmentsByCorporateId(
            policyData.corporate_id
          );
          setDepartments(departments);

          // Fetch existing assignment data
          const assignments = await fetchPolicyAssignmentByPolicyId(id);

          if (assignments && assignments.length > 0) {
            const assignment = assignments[0]; // Use the first assignment if multiple exist

            const initialEntity =
              assignment.department_id ||
              assignment.corporate_id ||
              assignment.user_id;

            setSelectedEntity(initialEntity); // Set the initial selected entity

            if (initialEntity) {
              // Fetch users for the selected department
              const users = await fetchUsersByDepartmentId(initialEntity);
              setEmployees(users);

              if (assignment.exclusions) {
                const initialExclusions = assignment.exclusions.map(
                  (exclusion) => ({
                    type: exclusion.exclusion_type,
                    value: exclusion.exclusion_value,
                  })
                );
                setExclusions(initialExclusions);

                // Apply initial exclusions to the department's employees list
                filterAndApplyExclusions(users, initialExclusions);
              } else {
                // If there are no exclusions, set the full list of employees
                setFilteredEmployees(users);
              }
            }
          }

          // Fetch and filter roles to include only roles with role_type 'client'
          const allRoles = await fetchUsersRoles();
          const clientRoles = allRoles.filter(
            (role) => role.role_type === 'client'
          );
          setRoles(clientRoles);
        } else if (policyData.policy_type_id === 3) {
          // Personal policy type
          const users = await fetchUsersByCorporateId(policyData.corporate_id);
          setEmployees(users);

          // Fetch existing assignment data
          const assignments = await fetchPolicyAssignmentByPolicyId(id);

          if (assignments && assignments.length > 0) {
            const assignment = assignments[0]; // Use the first assignment if multiple exist

            const initialEntity =
              assignment.department_id ||
              assignment.corporate_id ||
              assignment.user_id;

            setSelectedEntity(initialEntity); // Set the initial selected entity

            if (assignment.exclusions) {
              const initialExclusions = assignment.exclusions.map(
                (exclusion) => ({
                  type: exclusion.exclusion_type,
                  value: exclusion.exclusion_value,
                })
              );
              setExclusions(initialExclusions);

              // Apply initial exclusions to the employees list
              filterAndApplyExclusions(users, initialExclusions);
            } else {
              // If there are no exclusions, set the full list of employees
              setFilteredEmployees(users);
            }
          } else {
            // If no assignments are found, set the full list of employees
            setFilteredEmployees(users);
          }

          // Fetch and filter roles to include only roles with role_type 'client'
          const allRoles = await fetchUsersRoles();
          const clientRoles = allRoles.filter(
            (role) => role.role_type === 'client'
          );
          setRoles(clientRoles);
        }
      } catch (error) {
        console.error('Error fetching policy or assignment data:', error);
      }
    };

    fetchPolicyData(); // Fetch the policy data when component mounts or when 'id' changes
  }, [id, navigate, user.role_id, user.corporate_id]);

  // Fetch users for a specific department and apply exclusions
  const fetchUsersForDepartment = async (departmentId) => {
    try {
      const users = await fetchUsersByDepartmentId(departmentId);
      setEmployees(users); // Set the employees for the selected department
      filterAndApplyExclusions(users, exclusions); // Apply the exclusions to the list
    } catch (error) {
      console.error('Error fetching users for department:', error);
    }
  };

  // Filter the employees list based on the exclusions set
  const filterAndApplyExclusions = (users, exclusions) => {
    console.log('Original users:', users); // Log the initial list of users

    let filteredUsers = [...users]; // Create a copy of users to apply filters on

    exclusions.forEach((exclusion, index) => {
      console.log(`Processing exclusion ${index + 1}:`, exclusion);

      if (exclusion.type === 'role') {
        console.log('Excluding users with role ID:', exclusion.value);
        filteredUsers = filteredUsers.filter(
          (employee) => employee.role_id !== exclusion.value
        );
        console.log('Users after role exclusion:', filteredUsers);
      } else if (exclusion.type === 'department') {
        console.log('Excluding users from department ID:', exclusion.value);
        filteredUsers = filteredUsers.filter(
          (employee) => employee.department_id !== exclusion.value
        );
        console.log('Users after department exclusion:', filteredUsers);
      } else if (exclusion.type === 'employee') {
        console.log('Excluding specific employee ID:', exclusion.value);
        filteredUsers = filteredUsers.filter(
          (employee) => employee.id !== exclusion.value
        );
        console.log('Users after employee exclusion:', filteredUsers);
      }
    });

    console.log('Final filtered users:', filteredUsers); // Log the final filtered list of users
    setFilteredEmployees(filteredUsers); // Update the state with the filtered list
  };

  // Handle changes to the selected entity (corporate, department, or personal)
  const handleEntityChange = (event) => {
    const newValue = event.target.value;

    // Clear exclusions when the selected entity changes
    setExclusions([{ type: '', value: '' }]);
    setSelectedEntity(newValue);

    // Fetch users for the selected department or reset employees list based on the policy type
    if (policy.policy_type_id === 2) {
      fetchUsersForDepartment(newValue);
    } else if (policy.policy_type_id === 1 || policy.policy_type_id === 3) {
      // For corporate or personal policies, re-fetch all users
      fetchUsersByCorporateId(policy.corporate_id).then((users) => {
        setEmployees(users);
        filterAndApplyExclusions(users, exclusions);
      });
    }
  };

  // Handle changes to the exclusions set for the policy assignment
  const handleExclusionChange = (index, key, value) => {
    const newExclusions = exclusions.slice();

    if (key === 'type' && value !== 'employee') {
      // Clear the value if the type is changed to something other than employee
      newExclusions[index] = { type: value, value: '' };
    } else {
      newExclusions[index][key] = value;
    }

    // Clear employee selection if the role or department changes
    if (
      key === 'value' &&
      (newExclusions[index].type === 'role' ||
        newExclusions[index].type === 'department')
    ) {
      // Loop through all exclusions and clear employee exclusions
      newExclusions.forEach((exclusion, i) => {
        if (exclusion.type === 'employee') {
          newExclusions[i].value = '';
        }
      });
    }

    setExclusions(newExclusions); // Update the state with the new exclusions

    // Reapply filters when the exclusion is updated
    filterAndApplyExclusions(employees, newExclusions);
  };

  // Add a new exclusion rule to the list
  const addExclusion = () => {
    setExclusions([...exclusions, { type: '', value: '' }]);
  };

  // Remove an exclusion rule from the list
  const removeExclusion = (index) => {
    console.log(1111);
    const newExclusions = exclusions.slice();
    newExclusions.splice(index, 1); // Remove the exclusion at the given index
    setExclusions(newExclusions);
    console.log('new Exclusions:', newExclusions);
    // Reapply filters after an exclusion is removed
    filterAndApplyExclusions(employees, newExclusions);
  };

  // Render the exclusion fields in the UI
  const renderExclusionFields = () => {
    return exclusions.map((exclusion, index) => {
      const selectedEmployee = employees.find(
        (employee) => employee.id === exclusion.value
      );
      return (
        <Grid container spacing={2} key={index}>
          <Grid item xs={5} sm={3}>
            <FormControl fullWidth>
              <InputLabel>{t('policyAssign.excludeType')}</InputLabel>
              <Select
                value={exclusion.type}
                onChange={(e) =>
                  handleExclusionChange(index, 'type', e.target.value)
                }
              >
                {policy.policy_type_id === 1 && (
                  <MenuItem value="department">
                    {t('policyAssign.department')}
                  </MenuItem>
                )}
                <MenuItem value="role">{t('policyAssign.role')}</MenuItem>
                <MenuItem value="employee">
                  {t('policyAssign.employee')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5} sm={7}>
            {exclusion.type === 'department' && (
              <FormControl fullWidth>
                <InputLabel>{t('policyAssign.selectDepartment')}</InputLabel>
                <Select
                  value={exclusion.value}
                  onChange={(e) =>
                    handleExclusionChange(index, 'value', e.target.value)
                  }
                >
                  {departments.map((department) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.department_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {exclusion.type === 'role' && (
              <FormControl fullWidth>
                <InputLabel>{t('policyAssign.selectRole')}</InputLabel>
                <Select
                  value={exclusion.value}
                  onChange={(e) =>
                    handleExclusionChange(index, 'value', e.target.value)
                  }
                >
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.role_name_text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {exclusion.type === 'employee' && (
              <FormControl fullWidth>
                <InputLabel>{t('policyAssign.selectEmployee')}</InputLabel>
                <Select
                  value={exclusion.value}
                  onChange={(e) =>
                    handleExclusionChange(index, 'value', e.target.value)
                  }
                >
                  {/* Show the selected employee even if it is filtered out */}
                  {selectedEmployee && (
                    <MenuItem
                      key={selectedEmployee.id}
                      value={selectedEmployee.id}
                    >
                      {selectedEmployee.full_name}
                    </MenuItem>
                  )}
                  {filteredEmployees.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      {employee.full_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton
              color="secondary"
              onClick={() => removeExclusion(index)}
              aria-label="remove exclusion"
            >
              <RemoveCircleOutline />
            </IconButton>
          </Grid>
        </Grid>
      );
    });
  };

  // Handle the assignment of the policy with the selected entity and exclusions
  const handleAssign = () => {
    setError(null); // Reset the error message

    // Prepare the payload to be submitted
    const payload = {
      policy_id: policy.id,
      policy_type_id: policy.policy_type_id,
      exclusions: exclusions.filter(
        (exclusion) => exclusion.type && exclusion.value
      ),
    };

    // Determine the entity being assigned based on the policy type
    if (policy.policy_type_id === 1) {
      payload.corporate_id = selectedEntity;
    } else if (policy.policy_type_id === 2) {
      payload.department_id = selectedEntity;
    } else if (policy.policy_type_id === 3) {
      payload.user_id = selectedEntity;
    }

    // Log the payload to the console for debugging purposes
    console.log('Submitting the following data:', payload);

    // Call the assignPolicy API with the constructed payload
    assignPolicy(id, payload)
      .then(() => {
        // Show success message and navigate back to policies list
        Swal.fire({
          icon: 'success',
          title: t('policyAssign.successTitle'),
          text: t('policyAssign.successMessage'),
        }).then(() => {
          navigate('/policies');
        });
      })
      .catch((error) => {
        console.error(error);
        setError(error.response?.data?.error || t('policyAssign.errorMessage'));
      });
  };

  return (
    <div className="policy-assign-container">
      <Paper elevation={3} className="paper">
        {policy && (
          <>
            <div className="section">
              <Typography variant="h6" className="section-title">
                {t('policyAssign.policyInfo')}
              </Typography>
              <Typography variant="body1">
                <strong>{t('policyAssign.title')}:</strong> {policy.title}
              </Typography>
              <Typography variant="body1">
                <strong>{t('policyAssign.description')}:</strong>{' '}
                {policy.description}
              </Typography>
            </div>

            <div className="section">
              <Typography variant="h6" className="section-title">
                {t('policyAssign.policyRules')}
              </Typography>
              <List className="policy-rules-list">
                {policy.rules.map((rule) => (
                  <React.Fragment key={rule.rule_id}>
                    <ListItem>
                      <ListItemText
                        primary={`${rule.field_label} ${rule.operator} ${rule.field_value}`}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </div>

            <div className="section">
              <Typography variant="h6" className="section-title">
                {t('policyAssign.assignPolicyTo')}
              </Typography>
              <Grid container spacing={2} className="dropdown-container">
                {policy.policy_type_id === 1 && (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>
                        {t('policyAssign.selectCorporate')}
                      </InputLabel>
                      <Select
                        value={selectedEntity}
                        onChange={handleEntityChange}
                      >
                        <MenuItem value={policy.corporate_id}>
                          {policy.company_name}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {policy.policy_type_id === 2 && (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>
                        {t('policyAssign.selectDepartment')}
                      </InputLabel>
                      <Select
                        value={selectedEntity}
                        onChange={handleEntityChange}
                      >
                        {departments.map((department) => (
                          <MenuItem key={department.id} value={department.id}>
                            {department.department_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {policy.policy_type_id === 3 && (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>
                        {t('policyAssign.selectEmployee')}
                      </InputLabel>
                      <Select
                        value={selectedEntity}
                        onChange={handleEntityChange}
                      >
                        {employees.map((employee) => (
                          <MenuItem key={employee.id} value={employee.id}>
                            {employee.full_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </div>

            {policy.policy_type_id !== 3 && selectedEntity && (
              <div className="section">
                <Typography variant="h6" className="section-title">
                  {t('policyAssign.excludeSection')}
                </Typography>
                {renderExclusionFields()}
                <Box mt={2}>
                  <IconButton
                    color="primary"
                    onClick={addExclusion}
                    aria-label="add exclusion"
                  >
                    <AddCircleOutline />
                  </IconButton>
                </Box>
              </div>
            )}

            {error && (
              <Box mt={2}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}

            <Box
              mt={2}
              display="flex"
              justifyContent="center"
              className="section"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAssign}
                disabled={!selectedEntity}
              >
                {t('policyAssign.assignButton')}
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </div>
  );
};

export default PolicyAssign;
