import React from 'react';
import PassengerCounter from '../passengerCounter/PassengerCounter';
import './PassengerSelector.scss';
import { FaUser, FaChild } from 'react-icons/fa';
import { GiBabyBottle } from 'react-icons/gi';

const PassengerSelector = ({ passengers, onPassengersChange }) => {
  const { adults, children, infants } = passengers;

  const totalPassengers = adults + children + infants;

  const handleIncrement = (type) => {
    if (totalPassengers < 9) {
      if (type === 'infants' && infants < adults) {
        onPassengersChange({ ...passengers, [type]: passengers[type] + 1 });
      } else if (type !== 'infants') {
        onPassengersChange({ ...passengers, [type]: passengers[type] + 1 });
      }
    }
  };

  const handleDecrement = (type) => {
    if (type === 'adults' && adults > 1) {
      // Ensure at least 1 adult remains
      const newAdultsCount = adults - 1;
      let newInfantsCount = infants;
      if (newInfantsCount > newAdultsCount) {
        newInfantsCount = newAdultsCount; // Reduce infants to match adults
      }
      onPassengersChange({
        ...passengers,
        adults: newAdultsCount,
        infants: newInfantsCount,
      });
    } else if (type !== 'adults' && passengers[type] > 0) {
      onPassengersChange({ ...passengers, [type]: passengers[type] - 1 });
    }
  };

  return (
    <div className="passenger-selector">
      <PassengerCounter
        type="Adults"
        ageRange="Over 11"
        count={adults}
        onDecrement={() => handleDecrement('adults')}
        onIncrement={() => handleIncrement('adults')}
        icon={<FaUser />}
      />
      <PassengerCounter
        type="Children"
        ageRange="2 – 11"
        count={children}
        onDecrement={() => handleDecrement('children')}
        onIncrement={() => handleIncrement('children')}
        icon={<FaChild />}
      />
      <PassengerCounter
        type="Infants"
        ageRange="Under 2"
        count={infants}
        onDecrement={() => handleDecrement('infants')}
        onIncrement={() => handleIncrement('infants')}
        icon={<GiBabyBottle />}
      />
    </div>
  );
};

export default PassengerSelector;
