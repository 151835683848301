// src/utils/Icons.js
import {
  Home,
  Search,
  Notifications,
  Settings,
  Flight,
  FormatListBulleted,
  ListAlt,
  ExitToApp,
  Person,
  PersonAdd,
  People,
  AttachMoney,
  AssignmentTurnedIn,
  Create,
  AssignmentInd,
  BarChart,
  Description,
  TrendingUp,
  Edit,
  Delete,
  CheckCircle,
  Cancel,
} from '@mui/icons-material';

const Icons = {
  HomeIcon: Home,
  SearchIcon: Search,
  NotificationsIcon: Notifications,
  SettingsIcon: Settings,
  FlightIcon: Flight,
  FormatListBulletedIcon: FormatListBulleted,
  ListAltIcon: ListAlt,
  ExitToAppIcon: ExitToApp,
  PersonIcon: Person,
  PersonAddIcon: PersonAdd,
  PeopleIcon: People,
  AttachMoneyIcon: AttachMoney,
  AssignmentTurnedInIcon: AssignmentTurnedIn,
  CreateIcon: Create,
  AssignmentIndIcon: AssignmentInd,
  BarChartIcon: BarChart,
  DescriptionIcon: Description,
  TrendingUpIcon: TrendingUp,
  EditIcon: Edit,
  DeleteIcon: Delete,
  ActivateIcon: CheckCircle,
  DeactivateIcon: Cancel,
};

export default Icons;
