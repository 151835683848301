import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createBudget } from '../../../services/budgetService';
import {
  fetchCorporates,
  fetchCorporate,
} from '../../../services/corporateService';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/AuthContext';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import './BudgetCreate.scss';

const BudgetCreate = () => {
  const { t } = useTranslation();
  const { user } = useAuth(); // Get the logged-in user info
  const navigate = useNavigate();
  const [corporates, setCorporates] = useState([]);
  const [budgetData, setBudgetData] = useState({
    title: '',
    description: '',
    amount: '',
    startDate: '',
    endDate: '',
    corporateId: user.role_id === 4 ? user.corporate_id : '', // Automatically select corporate for corporate_admin
  });
  const [errors, setErrors] = useState({});
  const [corporateName, setCorporateName] = useState('');

  useEffect(() => {
    if (user.role_id === 4) {
      fetchCorporate(user.corporate_id)
        .then((response) => {
          console.log('Corporate response:', response); // Debug log
          if (response && response.company_name) {
            setCorporateName(response.company_name);
          } else {
            console.error('Error: Invalid response format:', response);
          }
        })
        .catch((error) => console.error('Error fetching corporate:', error));
    } else {
      fetchCorporates()
        .then((response) => {
          console.log('Corporates response:', response); // Debug log
          const filteredCorporates = response.slice(1); // Ignore the first corporate
          setCorporates(filteredCorporates);
        })
        .catch((error) => console.error('Error fetching corporates:', error));
    }
  }, [user.role_id, user.corporate_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBudgetData({ ...budgetData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!budgetData.title || !/^[a-zA-Z0-9\s]{4,}$/.test(budgetData.title)) {
      newErrors.title = t(
        'Title must be at least 4 characters long and contain only letters, numbers, and spaces.'
      );
    }
    if (!budgetData.description || budgetData.description.length < 10) {
      newErrors.description = t(
        'Description must be at least 10 characters long.'
      );
    }
    if (
      !budgetData.amount ||
      isNaN(budgetData.amount) ||
      budgetData.amount <= 0
    ) {
      newErrors.amount = t('Amount must be a positive number.');
    }
    if (!budgetData.startDate) {
      newErrors.startDate = t('Start Date is required.');
    }
    if (!budgetData.endDate) {
      newErrors.endDate = t('End Date is required.');
    }
    if (
      budgetData.startDate &&
      budgetData.endDate &&
      budgetData.startDate >= budgetData.endDate
    ) {
      newErrors.endDate = t('End Date must be greater than Start Date.');
    }
    if (!budgetData.corporateId) {
      newErrors.corporateId = t('Corporate is required.');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Clear previous errors
    setErrors({});

    if (validate()) {
      createBudget(budgetData)
        .then(() => {
          navigate('/budgets');
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            const newErrors = {};
            error.response.data.errors.forEach((err) => {
              switch (err.code) {
                case 'title_invalid':
                  newErrors.title = t('validation.budgetCreate.title_invalid');
                  break;
                case 'description_required':
                  newErrors.description = t(
                    'validation.budgetCreate.description_required'
                  );
                  break;
                case 'amount_invalid':
                  newErrors.amount = t(
                    'validation.budgetCreate.amount_invalid'
                  );
                  break;
                case 'start_date_required':
                  newErrors.startDate = t(
                    'validation.budgetCreate.start_date_required'
                  );
                  break;
                case 'start_date_invalid':
                  newErrors.startDate = t(
                    'validation.budgetCreate.start_date_invalid'
                  );
                  break;
                case 'end_date_required':
                  newErrors.endDate = t(
                    'validation.budgetCreate.end_date_required'
                  );
                  break;
                case 'end_date_invalid':
                  newErrors.endDate = t(
                    'validation.budgetCreate.end_date_invalid'
                  );
                  break;
                case 'end_date_before_start_date':
                  newErrors.endDate = t(
                    'validation.budgetCreate.end_date_before_start_date'
                  );
                  break;
                case 'corporate_required':
                  newErrors.corporateId = t(
                    'validation.budgetCreate.corporate_required'
                  );
                  break;
                case 'overlap':
                  newErrors.general = t(
                    'validation.budgetCreate.overlapping_dates_error'
                  );
                  break;
                default:
                  newErrors.general = t(
                    'validation.budgetCreate.unknown_error'
                  );
              }
            });
            console.log('Errors:', newErrors);
            setErrors(newErrors);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setErrors({ general: error.response.data.message });
          } else {
            console.error('Error creating budget:', error);
          }
        });
    }
  };

  const handleEndDateChange = (e) => {
    const { name, value } = e.target;
    // Ensure the end date is not before the start date
    if (budgetData.startDate && value < budgetData.startDate) {
      setErrors({
        ...errors,
        endDate: t('End Date must be greater than Start Date.'),
      });
    } else {
      setErrors({
        ...errors,
        endDate: '',
      });
      setBudgetData({ ...budgetData, [name]: value });
    }
  };

  const handleDateInput = (e) => {
    const { value } = e.target;
    // Limit the year input to 4 digits
    if (/^\d{0,4}-\d{0,2}-\d{0,2}$/.test(value)) {
      e.target.value = value;
    } else {
      e.target.value = value.slice(0, 10); // Limit the input to 'YYYY-MM-DD'
    }
  };

  return (
    <Container className="create-budget-container" maxWidth="sm">
      <Paper elevation={3} className="paper">
        <Typography variant="h4" component="h2" gutterBottom>
          {t('Create Budget')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label={t('Title')}
            name="title"
            value={budgetData.title || ''}
            onChange={handleChange}
            error={!!errors.title}
            helperText={errors.title}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('Description')}
            name="description"
            value={budgetData.description || ''}
            onChange={handleChange}
            multiline
            rows={4}
            error={!!errors.description}
            helperText={errors.description}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('Amount')}
            name="amount"
            type="number"
            value={budgetData.amount || ''}
            onChange={handleChange}
            error={!!errors.amount}
            helperText={errors.amount}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('Start Date')}
            name="startDate"
            type="date"
            value={budgetData.startDate || ''}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            onInput={handleDateInput} // Limit the year input to 4 digits
            error={!!errors.startDate}
            helperText={errors.startDate}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('End Date')}
            name="endDate"
            type="date"
            value={budgetData.endDate || ''}
            onChange={handleEndDateChange}
            InputLabelProps={{ shrink: true }}
            onInput={handleDateInput} // Limit the year input to 4 digits
            error={!!errors.endDate}
            helperText={errors.endDate}
            required
            fullWidth
            margin="normal"
            inputProps={{
              min: budgetData.startDate, // Block dates until the start date
            }}
          />
          {user.role_id === 4 ? (
            <TextField
              label={t('Corporate')}
              value={corporateName || ''}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              margin="normal"
            />
          ) : (
            <FormControl fullWidth margin="normal" error={!!errors.corporateId}>
              <InputLabel>{t('Corporate')}</InputLabel>
              <Select
                name="corporateId"
                value={budgetData.corporateId || ''}
                onChange={handleChange}
                required
              >
                {corporates.map((corporate) => (
                  <MenuItem key={corporate.id} value={corporate.id}>
                    {corporate.company_name}
                  </MenuItem>
                ))}
              </Select>
              {errors.corporateId && (
                <Typography variant="body2" className="errorText">
                  {errors.corporateId}
                </Typography>
              )}
            </FormControl>
          )}
          {errors.general && (
            <Typography variant="body2" className="errorText">
              {errors.general}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className="submit-button"
          >
            {t('Create')}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default BudgetCreate;
