import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { fetchDepartments } from '../../../services/departmentService';
import Icons from '../../../utils/icons/Icons';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './DepartmentList.scss';

const { CreateIcon, EditIcon } = Icons;

const DepartmentList = () => {
  const { t } = useTranslation();
  const [departments, setDepartments] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDepartments()
      .then((response) => {
        setDepartments(response);
        setError(null); // Clear any previous error
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
        setError(t('departmentList.errorFetchingDepartments'));
      });
  }, [t]);

  const columns = [
    {
      field: 'department_name',
      headerName: t('departmentList.departmentName'),
      flex: 1,
    },
    {
      field: 'manager_name',
      headerName: t('departmentList.managerName'),
      flex: 1,
    },
    {
      field: 'company_name',
      headerName: t('departmentList.companyName'),
      flex: 1,
    },
    {
      field: 'edit',
      headerName: t('departmentList.edit'),
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={t('departmentList.edit')}>
          <IconButton
            component="a"
            href={`/departments/${params.row.id}/edit`}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="department-list-container">
      <h2>{t('departmentList.title')}</h2>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/departments/create')}
        className="create-button"
        startIcon={<CreateIcon />}
      >
        {t('departmentList.createNewDepartment')}
      </Button>
      {error && (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      )}
      <div className="department-list">
        <DataGrid
          rows={departments}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageSize,
              },
            },
          }}
          pagination
          autoHeight
        />
      </div>
    </div>
  );
};

export default DepartmentList;
