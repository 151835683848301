import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import {
  createExpense,
  fetchExpenseCategories,
} from '../../../services/expenseService';
import {
  fetchCorporates,
  fetchCorporate,
} from '../../../services/corporateService';
import { fetchUsersByCorporateId } from '../../../services/userService';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/AuthContext';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './ExpenseCreate.scss';

const ExpenseCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [categories, setCategories] = useState([]);
  const [corporates, setCorporates] = useState([]);
  const [users, setUsers] = useState([]);
  const [receipt, setReceipt] = useState(null);

  const [formData, setFormData] = useState({
    amount: '',
    expense_date: '',
    category_id: '',
    description: '',
    corporate_id: user.role_id !== 1 ? user.corporate_id : '',
    user_id: user.role_id === 6 ? user.id : '',
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setLoading(true);
    fetchExpenseCategories()
      .then((data) => {
        setCategories(data);
        setLoading(false);
      })
      .catch(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: t('Failed to load expense categories'),
        }));
        setLoading(false);
      });

    if (user.role_id === 1) {
      fetchCorporates().then((data) => setCorporates(data));
    } else {
      fetchCorporate(user.corporate_id)
        .then((data) => {
          setCorporates([data]);
          setFormData((prevFormData) => ({
            ...prevFormData,
            corporate_id: data.id,
          }));
        })
        .catch((error) => {
          console.error('Error fetching corporate in useEffect:', error);
          setErrors((prevErrors) => ({
            ...prevErrors,
            general: t('Failed to load corporate data'),
          }));
          setLoading(false);
        });
    }
  }, [t, user.role_id, user.corporate_id]);

  useEffect(() => {
    if (formData.corporate_id) {
      fetchUsersByCorporateId(formData.corporate_id)
        .then((data) => {
          setUsers(data);
        })
        .catch((error) => {
          console.error('Error fetching users:', error);
          setErrors((prevErrors) => ({
            ...prevErrors,
            general: t('Failed to load users'),
          }));
        });
    }
  }, [formData.corporate_id, t]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Clear specific error on change
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.amount || isNaN(formData.amount) || formData.amount <= 0) {
      newErrors.amount = t('Amount must be a positive number.');
    }
    if (!formData.expense_date) {
      newErrors.expense_date = t('Date is required.');
    }
    if (!formData.category_id) {
      newErrors.category_id = t('Category is required.');
    }
    if (!formData.description || formData.description.length < 10) {
      newErrors.description = t(
        'Description must be at least 10 characters long.'
      );
    }
    if (user.role_id === 1 && !formData.corporate_id) {
      newErrors.corporate_id = t('Corporate is required.');
    }
    if (!formData.user_id) {
      newErrors.user_id = t('User is required.');
    }
    if (!receipt) {
      newErrors.receipt = t('Receipt is required.');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});

    if (validate()) {
      setLoading(true);

      const expenseData = new FormData();
      expenseData.append('amount', formData.amount);
      expenseData.append('expense_date', formData.expense_date);
      expenseData.append('category_id', formData.category_id);
      expenseData.append('description', formData.description);
      expenseData.append('corporate_id', formData.corporate_id);
      expenseData.append('user_id', formData.user_id);
      expenseData.append('receipt', receipt); // Append the receipt file

      createExpense(expenseData)
        .then(() => {
          setFormData({
            amount: '',
            expense_date: '',
            category_id: '',
            description: '',
            corporate_id: user.role_id !== 1 ? user.corporate_id : '',
            user_id: user.role_id === 6 ? user.id : '',
          });
          setReceipt(null); // Reset the receipt file input
          setLoading(false);
          navigate('/expenses');
        })
        .catch((error) => {
          console.error('Error creating expense:', error);
          const newErrors = {};

          // Handle validation errors from the backend
          if (error.response && error.response.status === 400) {
            const validationErrors = error.response.data.errors;
            for (const key in validationErrors) {
              newErrors[key] = validationErrors[key].message;
            }
          } else if (error.response && error.response.status === 500) {
            newErrors.general =
              error.response.data.error ||
              error.response.data.message ||
              t('An unexpected error occurred. Please try again later.');
          } else {
            newErrors.general =
              error?.response?.data?.message || t('Failed to create expense');
          }

          setErrors(newErrors);
          setLoading(false);
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setReceipt(acceptedFiles[0]);
    },
    accept: '.jpg,.jpeg,.png,.pdf',
    maxFiles: 1,
  });

  return (
    <Container className="expense-create-container" maxWidth="sm">
      <Paper elevation={3} className="paper">
        <Typography variant="h4" component="h2" gutterBottom>
          {t('Create Expense')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label={t('Amount')}
            name="amount"
            type="number"
            value={formData.amount || ''}
            onChange={handleChange}
            error={!!errors.amount}
            helperText={errors.amount}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('Date')}
            name="expense_date"
            type="date"
            value={formData.expense_date || ''}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            error={!!errors.expense_date}
            helperText={errors.expense_date}
            required
            fullWidth
            margin="normal"
          />
          {user.role_id === 1 && (
            <FormControl
              fullWidth
              margin="normal"
              error={!!errors.corporate_id}
            >
              <InputLabel>{t('Corporate')}</InputLabel>
              <Select
                name="corporate_id"
                value={formData.corporate_id || ''}
                onChange={handleChange}
                required
              >
                {corporates.map((corporate) => (
                  <MenuItem key={corporate.id} value={corporate.id}>
                    {corporate.company_name}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2" color="error">
                {errors.corporate_id}
              </Typography>
            </FormControl>
          )}
          <FormControl fullWidth margin="normal" error={!!errors.user_id}>
            <InputLabel>{t('User')}</InputLabel>
            <Select
              name="user_id"
              value={formData.user_id || ''}
              onChange={handleChange}
              required
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.full_name}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body2" color="error">
              {errors.user_id}
            </Typography>
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!errors.category_id}>
            <InputLabel>{t('Category')}</InputLabel>
            <Select
              name="category_id"
              value={formData.category_id || ''}
              onChange={handleChange}
              required
            >
              <MenuItem value="" disabled>
                {t('Select a category')}
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.category_name}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body2" color="error">
              {errors.category_id}
            </Typography>
          </FormControl>
          <TextField
            label={t('Description')}
            name="description"
            value={formData.description || ''}
            onChange={handleChange}
            multiline
            rows={4}
            error={!!errors.description}
            helperText={errors.description}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal" error={!!errors.receipt}>
            <div
              {...getRootProps()}
              style={{
                border: '2px dashed #ccc',
                borderRadius: '4px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              <input {...getInputProps()} />
              <CloudUploadIcon style={{ fontSize: '48px', color: '#999' }} />
              <Typography variant="body2">
                {receipt
                  ? receipt.name
                  : t('Drag & drop your recipt here, or click to select one')}
              </Typography>
            </div>
            {errors.receipt && (
              <Typography variant="body2" color="error">
                {errors.receipt}
              </Typography>
            )}
          </FormControl>
          {errors.general && (
            <Typography variant="body2" color="error">
              {errors.general}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className="submit-button"
            disabled={loading}
          >
            {loading ? t('Creating...') : t('Create Expense')}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default ExpenseCreate;
