import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { fetchBudgets } from '../../../services/budgetService';
import Icons from '../../../utils/icons/Icons';
import { Button, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './BudgetList.scss';

const { CreateIcon, EditIcon, AssignmentTurnedInIcon } = Icons;

const BudgetList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [budgets, setBudgets] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchBudgets()
      .then((response) => setBudgets(response))
      .catch((error) => console.error('Error fetching budgets:', error));
  }, []);

  const handleCreate = () => {
    navigate('/budgets/create');
  };

  const columns = [
    { field: 'title', headerName: t('budgetList.title'), flex: 1 },
    {
      field: 'description',
      headerName: t('budgetList.description'),
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>
            {params.value.length > 50
              ? `${params.value.substring(0, 50)}...`
              : params.value}
          </span>
        </Tooltip>
      ),
    },
    { field: 'amount', headerName: t('budgetList.totalAmount'), flex: 1 },
    { field: 'start_date', headerName: t('budgetList.startDate'), flex: 1 },
    { field: 'end_date', headerName: t('budgetList.endDate'), flex: 1 },
    {
      field: 'company_name',
      headerName: t('budgetList.companyName'),
      flex: 1.5,
    },
    {
      field: 'edit',
      headerName: t('budgetList.edit'),
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={t('budgetList.edit')}>
          <IconButton
            onClick={() => navigate(`/budgets/${params.row.id}/edit`)}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'allocate',
      headerName: t('budgetList.allocate'),
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={t('budgetList.allocate')}>
          <IconButton
            onClick={() => navigate(`/budgets/${params.row.id}/allocate`)}
            color="secondary"
          >
            <AssignmentTurnedInIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="budget-list-container">
      <h2>{t('budgetList.budgetListTitle')}</h2>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreate}
        className="create-button"
        startIcon={<CreateIcon />}
      >
        {t('budgetList.createBudget')}
      </Button>
      <div className="budget-list">
        <DataGrid
          rows={budgets}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageSize,
              },
            },
          }}
          pagination
          autoHeight
          disableColumnMenu
          disableSelectionOnClick
          componentsProps={{
            pagination: {
              rowsPerPageOptions: [5, 10, 20],
              labelRowsPerPage: t('budgetList.rowsPerPage'),
            },
          }}
        />
      </div>
    </div>
  );
};

export default BudgetList;
