// src/services/flightService.js
import axiosInstance from './axiosConfig';

/**
 * Fetches flight search results based on the search criteria.
 *
 * @param {Object} searchCriteria - The criteria used to search for flights (e.g., origin, destination, dates).
 * @returns {Promise<Object>} - A promise that resolves to the search results.
 * @throws {Error} - Throws an error if the request fails.
 */
export const searchFlights = (searchCriteria) => {
  return axiosInstance
    .post('/v1/flights/search', searchCriteria)
    .then((response) => {
      console.log(response.data); // Log response data for debugging
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Validates the price of the selected flight.
 *
 * @param {string} flightId - The ID of the selected flight.
 * @returns {Promise<Object>} - A promise that resolves to the validation result.
 * @throws {Error} - Throws an error if the request fails.
 */
/*export const validateFlightPrice = (flightId) => {
  return axiosInstance
    .post(`/v1/flights/${flightId}/validate-price`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
*/
/**
 * Submits passenger information for the selected flight.
 *
 * @param {string} flightId - The ID of the selected flight.
 * @param {Object} passengerInfo - The passenger details to submit.
 * @returns {Promise<Object>} - A promise that resolves to the submission result.
 * @throws {Error} - Throws an error if the request fails.
 */
/*export const submitPassengerInfo = (flightId, passengerInfo) => {
  return axiosInstance
    .post(`/v1/flights/${flightId}/passenger-info`, passengerInfo)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
*/
/**
 * Fetches the list of airports for the autocomplete feature via the backend.
 *
 * @returns {Promise<Array>} - A promise that resolves to an array of airport objects.
 * @throws {Error} - Throws an error if the request fails.
 */
export const fetchAirportsList = () => {
  return axiosInstance
    .get('/v1/flights/airports')
    .then((response) => {
      console.log(response.data); // Log response data for debugging
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
/**
 * Consolidated API call for validating price, submitting passenger info, and generating PNR
 *
 * @param {string} flightId - The ID of the selected flight.
 * @param {Object} bookingData - The booking details including sessionId, fareSourceCode, and passenger info.
 * @returns {Promise<Object>} - A promise that resolves to the booking confirmation with PNR and ticket number.
 * @throws {Error} - Throws an error if the request fails.
 */
export const completeBooking = (flightId, bookingData) => {
  return axiosInstance
    .post(`/v1/flights/${flightId}/book`, bookingData)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
/**
 * Fetches trip details based on the booking reference uniqueID.
 *
 * @param {string} uniqueID - The unique booking reference ID.
 * @returns {Promise<Object>} - A promise that resolves to the trip details.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getTripDetails = (uniqueID) => {
  return axiosInstance
    .post(`/v1/flights/${uniqueID}/trip-details`, { uniqueID })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
// Add more methods as needed for booking, ticketing, etc.
