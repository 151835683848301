import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  fetchBudgetById,
  fetchDetailedBudgetById,
  allocateBudget,
} from '../../../services/budgetService';
import { fetchDepartmentsByCorporateId } from '../../../services/departmentService';
import { fetchUsersByDepartmentId } from '../../../services/userService';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/AuthContext';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import './BudgetAllocate.scss';

const BudgetAllocate = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { user: loggedInUser } = useAuth();

  const [budget, setBudget] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState({});
  const [allocations, setAllocations] = useState({
    departments: {},
    users: {},
    corporate: {},
  });
  const [detailedData, setDetailedData] = useState({
    departments: {},
  });
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState({
    departments: {},
    users: {},
  });

  // Fetch budget, detailed data, and departments on component mount
  useEffect(() => {
    fetchBudgetById(id)
      .then((response) => {
        if (
          loggedInUser.role_id === 4 &&
          response.corporate_id !== loggedInUser.corporate_id
        ) {
          navigate('/unauthorized');
          return;
        }
        setBudget(response);
        setAllocations((prevAllocations) => ({
          ...prevAllocations,
          corporate: response,
        }));
        return fetchDepartmentsByCorporateId(response.corporate_id);
      })
      .then((response) => setDepartments(response))
      .catch((error) => {
        console.error('Error fetching data:', error);
        navigate('/404');
      });

    fetchDetailedBudgetById(id)
      .then((response) => {
        setDetailedData(response);
        console.log('Response: ', response);
        // Update allocations with detailed data
        const departmentAllocations = {};
        const userAllocations = {};

        Object.values(response.departments).forEach((dept) => {
          departmentAllocations[dept.id] = dept.amount;
          dept.users.forEach((user) => {
            if (!userAllocations[dept.id]) {
              userAllocations[dept.id] = {};
            }
            userAllocations[dept.id][user.id] = user.amount;
          });
        });

        setAllocations((prevAllocations) => ({
          ...prevAllocations,
          departments: departmentAllocations,
          users: userAllocations,
        }));

        // Debugging: Log allocations
        console.log('Allocations:', departmentAllocations, userAllocations);
      })
      .catch((error) => console.error('Error fetching detailed data:', error));
  }, [id, loggedInUser, navigate]);

  // Fetch users for the current department when the active step changes
  useEffect(() => {
    if (activeStep > 0 && activeStep <= departments.length) {
      const departmentId = departments[activeStep - 1].id;
      fetchUsersByDepartmentId(departmentId)
        .then((response) => {
          setUsers((prevUsers) => ({
            ...prevUsers,
            [departmentId]: response,
          }));
        })
        .catch((error) => console.error('Error fetching users:', error));
    }
  }, [activeStep, departments]);

  // Handle next button click
  const handleNext = () => {
    let hasError = false;
    const newErrors = { ...errors };

    if (activeStep === 0) {
      const totalDepartmentAllocation = Object.values(
        allocations.departments
      ).reduce((sum, value) => sum + Number(value || 0), 0);

      // Check if total department allocation exceeds budget
      if (totalDepartmentAllocation > budget.amount) {
        Swal.fire({
          icon: 'error',
          title: t('error'),
          text: t('budgetAllocate.errors.totalAllocationsExceedBudget'),
        });
        return;
      }

      // Check if at least one department has an allocated budget
      const hasAtLeastOneAllocation = Object.values(
        allocations.departments
      ).some((value) => Number(value) > 0);

      if (!hasAtLeastOneAllocation) {
        Swal.fire({
          icon: 'error',
          title: t('error'),
          text: t('budgetAllocate.errors.allocateToAtLeastOneDepartment'),
        });
        return;
      }
    } else if (activeStep <= departments.length) {
      const departmentId = departments[activeStep - 1].id;
      const totalUserAllocation = Object.values(
        allocations.users[departmentId] || {}
      ).reduce((sum, value) => sum + Number(value || 0), 0);

      // Check if total user allocation exceeds department budget
      if (totalUserAllocation > allocations.departments[departmentId]) {
        Swal.fire({
          icon: 'error',
          title: t('error'),
          text: t(
            'budgetAllocate.errors.totalUserAllocationsExceedDepartmentBudget'
          ),
        });
        return;
      }
    }

    setErrors(newErrors);

    // Proceed to the next step if there are no errors
    if (!hasError) {
      let nextStep = activeStep + 1;

      // Skip user allocation steps for departments with no allocated budget
      while (
        nextStep < steps.length - 1 &&
        !allocations.departments[departments[nextStep - 1]?.id]
      ) {
        nextStep++;
      }

      setActiveStep(nextStep);
    }
  };

  // Handle back button click
  const handleBack = () => {
    let prevStep = activeStep - 1;

    // Skip user allocation steps for departments with no allocated budget
    while (
      prevStep > 0 &&
      !allocations.departments[departments[prevStep - 1]?.id]
    ) {
      prevStep--;
    }

    setActiveStep(prevStep);
  };

  // Handle allocation change for departments and users
  const handleAllocationChange = (type, id, value) => {
    const sanitizedValue = value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except decimal points
    setAllocations((prevAllocations) => {
      const newAllocations = { ...prevAllocations };
      if (type === 'department') {
        newAllocations.departments[id] = sanitizedValue;
      } else if (type === 'user') {
        const [departmentId, userId] = id.split('-');
        if (!newAllocations.users[departmentId]) {
          newAllocations.users[departmentId] = {};
        }
        newAllocations.users[departmentId][userId] = sanitizedValue;
      }
      return newAllocations;
    });

    // Clear error messages when the user starts typing
    if (type === 'department') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        departments: {
          ...prevErrors.departments,
          [id]: '',
        },
      }));
    } else if (type === 'user') {
      const [departmentId, userId] = id.split('-');
      setErrors((prevErrors) => ({
        ...prevErrors,
        users: {
          ...prevErrors.users[departmentId],
          [userId]: '',
        },
      }));
    }
  };

  // Handle editing a specific step
  const handleEditStep = (step) => {
    setActiveStep(step);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Filter out unnecessary allocations
    const filteredDepartments = Object.keys(allocations.departments)
      .filter(
        (departmentId) => Number(allocations.departments[departmentId]) > 0
      )
      .reduce((result, departmentId) => {
        result[departmentId] = allocations.departments[departmentId];
        return result;
      }, {});

    const filteredUsers = Object.keys(allocations.users)
      .filter((departmentId) => filteredDepartments[departmentId]) // Only include users for departments with a valid budget
      .reduce((result, departmentId) => {
        const users = Object.keys(allocations.users[departmentId])
          .filter(
            (userId) => Number(allocations.users[departmentId][userId]) > 0
          )
          .reduce((userResult, userId) => {
            userResult[userId] = allocations.users[departmentId][userId];
            return userResult;
          }, {});
        if (Object.keys(users).length > 0) {
          result[departmentId] = users;
        }
        return result;
      }, {});

    const finalAllocations = {
      departments: filteredDepartments,
      users: filteredUsers,
      corporate: allocations.corporate,
    };

    allocateBudget(id, finalAllocations)
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: t('success'),
          text: t('budgetAllocate.success.allocationSuccess'),
        });
        navigate(`/budgets`);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: t('error'),
          text: t('budgetAllocate.errors.errorAllocatingBudget'),
        });
        console.error('Error allocating budget:', error);
      });
  };

  // Define steps for the stepper
  const steps = [
    t('budgetAllocate.allocateToDepartments'),
    ...departments.map(
      (dept) => `${t('budgetAllocate.allocateTo')} ${dept.department_name}`
    ),
    t('budgetAllocate.reviewAndSubmit'),
  ];

  return (
    <div className="allocate-budget-container">
      <h2>{t('budgetAllocate.allocateBudget')}</h2>
      {budget && (
        <Paper elevation={3} className="budget-info">
          <Typography variant="h5" align="center">
            {budget.title}
          </Typography>
          <Typography variant="body1" align="left">
            {budget.description}
          </Typography>
          <Typography variant="body1" align="left">
            {t('budgetAllocate.totalAmount')}: {budget.amount} |{' '}
            {t('budgetAllocate.unallocatedAmount')}: {budget.unallocated_amount}{' '}
            | {t('budgetAllocate.startDate')}: {budget.start_date} |{' '}
            {t('budgetAllocate.endDate')}: {budget.end_date}
          </Typography>
          {Object.values(detailedData.departments).map((dept) => (
            <Accordion key={dept.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${dept.id}-content`}
                id={`panel-${dept.id}-header`}
              >
                <Typography>
                  {dept.department_name} Budget: {dept.amount} (
                  {t('budgetAllocate.unallocatedAmount')}:{' '}
                  {dept.unallocated_budget})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {dept.users.map((user) => (
                  <Typography key={user.id}>
                    {user.full_name}: {user.amount}
                  </Typography>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
      )}
      <Stepper activeStep={activeStep} className="stepper">
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              error={
                index > 0 &&
                index < steps.length - 1 &&
                !allocations.departments[departments[index - 1]?.id]
              }
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <form onSubmit={handleSubmit} className="form">
        {activeStep === 0 && (
          <div className="form-step">
            {departments.map((department) => (
              <div key={department.id} className="form-field">
                {console.log(
                  `Department ${department.id} Budget:`,
                  allocations.departments[department.id]
                )}
                <TextField
                  label={`${department.department_name} Budget`}
                  type="text"
                  value={allocations.departments[department.id] || ''}
                  onChange={(e) =>
                    handleAllocationChange(
                      'department',
                      department.id,
                      e.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                  required
                  variant="outlined"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  error={!!errors.departments[department.id]}
                  helperText={errors.departments[department.id]}
                />
              </div>
            ))}
            <div className="actions">
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="contained"
                color="primary"
              >
                {t('budgetAllocate.back')}
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {t('budgetAllocate.next')}
              </Button>
            </div>
          </div>
        )}
        {activeStep > 0 && activeStep <= departments.length && (
          <div className="form-step">
            {users[departments[activeStep - 1].id]?.map((user) => (
              <div key={user.id} className="form-field">
                {console.log(
                  `User ${user.id} Budget in Department ${
                    departments[activeStep - 1].id
                  }:`,
                  allocations.users[departments[activeStep - 1].id]?.[user.id]
                )}
                <TextField
                  label={`${user.full_name} Budget`}
                  type="text"
                  value={
                    allocations.users[departments[activeStep - 1].id]?.[
                      user.id
                    ] || ''
                  }
                  onChange={(e) =>
                    handleAllocationChange(
                      'user',
                      `${departments[activeStep - 1].id}-${user.id}`,
                      e.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                  required
                  variant="outlined"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  error={
                    !!errors.users[departments[activeStep - 1].id]?.[user.id]
                  }
                  helperText={
                    errors.users[departments[activeStep - 1].id]?.[user.id]
                  }
                />
              </div>
            ))}
            <div className="actions">
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="contained"
                color="primary"
              >
                {t('budgetAllocate.back')}
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {t('budgetAllocate.next')}
              </Button>
            </div>
          </div>
        )}
        {activeStep === steps.length - 1 && (
          <div>
            <Typography variant="h6">
              {t('budgetAllocate.reviewAndSubmit')}
            </Typography>
            <div className="review-step">
              {departments.map((department, index) => (
                <div key={department.id} className="review-department">
                  <Typography variant="h6">
                    {department.department_name} Budget: $
                    {allocations.departments[department.id] || 0} (
                    {t('budgetAllocate.unallocatedBudget')}:{' '}
                    {detailedData.departments[department.id]
                      ?.unallocated_budget || 0}
                    )
                    <IconButton size="small" onClick={() => handleEditStep(0)}>
                      <EditIcon />
                    </IconButton>
                  </Typography>
                  {users[department.id]?.map((user) => (
                    <Typography
                      key={user.id}
                      variant="body1"
                      className="review-user"
                    >
                      {user.full_name}: $
                      {allocations.users[department.id]?.[user.id] || 0}
                      <IconButton
                        size="small"
                        onClick={() => handleEditStep(index + 1)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Typography>
                  ))}
                </div>
              ))}
            </div>
            <div className="actions">
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="contained"
                color="primary"
              >
                {t('budgetAllocate.back')}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {t('budgetAllocate.submit')}
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default BudgetAllocate;
