import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  Paper,
} from '@mui/material';
import { fetchManagers } from '../../../services/userService';
import { fetchCorporates } from '../../../services/corporateService';
import { createDepartment } from '../../../services/departmentService';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/AuthContext';
import './DepartmentCreate.scss';

const DepartmentCreate = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    departmentName: '',
    corporateId: user.role_id === 4 ? user.corporate_id : '',
    managerId: '',
  });
  const [corporates, setCorporates] = useState([]);
  const [managers, setManagers] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (user.role_id === 1) {
      fetchCorporates()
        .then((data) => {
          if (Array.isArray(data)) {
            setCorporates(data);
          } else {
            console.error('Fetched corporates is not an array:', data);
          }
        })
        .catch((error) => console.error('Error fetching corporates:', error));
    }

    if (user.role_id === 4) {
      fetchManagers(user.corporate_id)
        .then((data) => {
          if (Array.isArray(data)) {
            setManagers(data);
          } else {
            console.error('Fetched managers is not an array:', data);
          }
        })
        .catch((error) => console.error('Error fetching managers:', error));
    }
  }, [user.role_id, user.corporate_id]);

  const validateName = (name) => {
    const re = /^[A-Za-z\s]{3,}$/;
    return re.test(name);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let newErrors = { ...errors };

    if (name === 'departmentName' && !validateName(value)) {
      newErrors.departmentName = t('departmentCreate.validations.invalidName');
    } else {
      delete newErrors.departmentName;
    }

    if (name === 'corporateId') {
      fetchManagers(value)
        .then((data) => {
          if (Array.isArray(data)) {
            setManagers(data);
          } else {
            console.error('Fetched managers is not an array:', data);
          }
        })
        .catch((error) => console.error('Error fetching managers:', error));
    }

    setErrors(newErrors);
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.departmentName || !validateName(formData.departmentName)) {
      newErrors.departmentName = t('departmentCreate.validations.invalidName');
    }
    if (!formData.corporateId) {
      newErrors.corporateId = t(
        'departmentCreate.validations.corporateRequired'
      );
    }
    if (!formData.managerId) {
      newErrors.managerId = t('departmentCreate.validations.managerRequired');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});

    if (validate()) {
      createDepartment(formData)
        .then(() => {
          navigate('/departments');
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            const newErrors = {};
            error.response.data.errors.forEach((err) => {
              switch (err.code) {
                case 'departmentName_invalid':
                  newErrors.departmentName = t(
                    'departmentCreate.validations.invalidName'
                  );
                  break;
                case 'corporate_required':
                  newErrors.corporateId = t(
                    'departmentCreate.validations.corporateRequired'
                  );
                  break;
                case 'manager_required':
                  newErrors.managerId = t(
                    'departmentCreate.validations.managerRequired'
                  );
                  break;
                default:
                  newErrors.general = t(
                    'departmentCreate.validations.unknown_error'
                  );
              }
            });
            setErrors(newErrors);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setErrors({ general: error.response.data.message });
          } else {
            console.error('Error creating department:', error);
          }
        });
    }
  };

  return (
    <Paper className="department-create-root">
      <Typography variant="h4" className="form-title">
        {t('departmentCreate.addNewDepartment')}
      </Typography>
      <form onSubmit={handleSubmit} className="department-create-form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('departmentCreate.departmentName')}
              name="departmentName"
              value={formData.departmentName}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.departmentName}
              helperText={errors.departmentName}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          {user.role_id === 1 && (
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                required
                error={!!errors.corporateId}
                className="form-control"
              >
                <InputLabel
                  className="textField-label select-label"
                  sx={{ backgroundColor: 'white', px: 1 }}
                >
                  {t('departmentCreate.corporate')}
                </InputLabel>
                <Select
                  name="corporateId"
                  value={formData.corporateId}
                  onChange={handleChange}
                >
                  {corporates.map((corporate) => (
                    <MenuItem key={corporate.id} value={corporate.id}>
                      {corporate.company_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.corporateId}</FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!!errors.managerId}
              className="form-control"
            >
              <InputLabel
                className="textField-label select-label"
                sx={{ backgroundColor: 'white', px: 1 }}
              >
                {t('departmentCreate.manager')}
              </InputLabel>
              <Select
                name="managerId"
                value={formData.managerId}
                onChange={handleChange}
              >
                {managers.map((manager) => (
                  <MenuItem key={manager.id} value={manager.id}>
                    {`${manager.first_name} ${manager.last_name}`}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.managerId}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {errors.general && (
          <Typography variant="body2" className="errorText">
            {errors.general}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="submit-button"
        >
          {t('departmentCreate.addDepartment')}
        </Button>
      </form>
    </Paper>
  );
};

export default DepartmentCreate;
