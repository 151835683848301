import React, { forwardRef } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Paper,
} from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import './AutocompleteResults.scss';

const AutocompleteResults = forwardRef(
  ({ results, searchTerm, anchorEl, onClose, onSelect }, ref) => {
    const getIcon = (type) => {
      switch (type) {
        case 'airport':
          return <FlightIcon className="autocomplete-icon" />;
        case 'city':
          return <LocationCityIcon className="autocomplete-icon" />;
        default:
          return null;
      }
    };

    const highlightMatch = (text, searchTerm) => {
      const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
      return (
        <>
          {parts.map((part, index) =>
            part.toLowerCase() === searchTerm.toLowerCase() ? (
              <span key={index} className="highlighted-text">
                {part}
              </span>
            ) : (
              part
            )
          )}
        </>
      );
    };

    if (!anchorEl) return null;

    const rect = anchorEl.getBoundingClientRect();

    return (
      <Paper
        ref={ref} // Attach the ref here
        style={{
          position: 'absolute',
          top: `${rect.bottom + 10}px`,
          left: `${rect.left}px`,
          width: `${rect.width}px`,
          zIndex: 1300,
        }}
      >
        <List className="autocomplete-root">
          {results.map((result, index) => (
            <ListItem
              key={index}
              className="autocomplete-listItem"
              onClick={() => {
                onSelect(result);
                onClose(); // Close the popover after selecting an item
              }}
            >
              <ListItemIcon>{getIcon(result.type)}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    className="autocomplete-primaryText"
                  >
                    {highlightMatch(
                      `${result.name} (${result.code})`,
                      searchTerm
                    )}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="body2"
                    className="autocomplete-secondaryText"
                  >
                    {highlightMatch(result.cityCountry, searchTerm)}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    );
  }
);

export default AutocompleteResults;
