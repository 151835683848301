// src/components/routing/RoutesWithConfig.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

const RoutesWithConfig = ({ routes }) => {
  return (
    <Routes>
      {routes.map(({ path, component: Component, requiredRoles }) => (
        <Route
          key={path}
          path={path}
          element={
            <ProtectedRoute requiredRoles={requiredRoles}>
              <Component />
            </ProtectedRoute>
          }
        />
      ))}
    </Routes>
  );
};

export default RoutesWithConfig;
