import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {
  fetchUsers,
  activateUser,
  deactivateUser,
} from '../../../services/userService';
import Icons from '../../../utils/icons/Icons';
import {
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/AuthContext'; // Import AuthContext
import './UserList.scss';

const { CreateIcon, EditIcon, ActivateIcon, DeactivateIcon } = Icons;

const UserList = () => {
  const { t } = useTranslation();
  const { user } = useAuth(); // Get the current user from AuthContext
  const [users, setUsers] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isActivating, setIsActivating] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers()
      .then((response) => setUsers(response))
      .catch((error) => console.error('Error fetching users:', error));
  }, []);

  const handleActivateDeactivate = (id, isActive) => {
    setSelectedUserId(id);
    setIsActivating(!isActive);
    setConfirmDialogOpen(true);
  };

  const confirmActivateDeactivate = () => {
    const action = isActivating ? activateUser : deactivateUser;
    action(selectedUserId)
      .then(() => {
        setUsers(
          users.map((user) =>
            user.id === selectedUserId
              ? { ...user, is_active: isActivating }
              : user
          )
        );
        setConfirmDialogOpen(false);
      })
      .catch((error) =>
        console.error(
          `Error ${isActivating ? 'activating' : 'deactivating'} user:`,
          error
        )
      );
  };

  // Define the basic columns
  const columns = [
    { field: 'full_name', headerName: t('userList.fullName'), flex: 1 },
    { field: 'email', headerName: t('userList.email'), flex: 1.5 },
    { field: 'role', headerName: t('userList.userRole'), flex: 1 },
    { field: 'corporate', headerName: t('userList.corporateName'), flex: 1 },
    { field: 'manager', headerName: t('userList.reportingManager'), flex: 1 },
    { field: 'level_name', headerName: t('userList.level'), flex: 1 },
  ];

  // Conditionally add the edit and activate/deactivate columns
  if (user.role_id === 1 || user.role_id === 4) {
    columns.push(
      {
        field: 'edit',
        headerName: t('userList.edit'),
        flex: 0.5,
        renderCell: (params) => (
          <Tooltip title={t('userList.edit')}>
            <IconButton
              component="a"
              href={`/users/${params.row.id}/edit`}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        ),
      },
      {
        field: 'activateDeactivate',
        headerName: t('userList.status'),
        flex: 0.5,
        renderCell: (params) => (
          <Tooltip
            title={
              params.row.is_active
                ? t('userList.clickToDeactivate')
                : t('userList.clickToActivate')
            }
          >
            <IconButton
              onClick={() =>
                handleActivateDeactivate(params.row.id, params.row.is_active)
              }
              sx={{ color: params.row.is_active ? 'green' : 'red' }} // Green for active, red for deactivate
            >
              {params.row.is_active ? <ActivateIcon /> : <DeactivateIcon />}
            </IconButton>
          </Tooltip>
        ),
      }
    );
  }

  return (
    <div className="user-list-container">
      <h2>{t('userList.title')}</h2>
      {(user.role_id === 1 || user.role_id === 4) && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/users/create')}
          className="create-button"
          startIcon={<CreateIcon />}
        >
          {t('userList.createNewUser')}
        </Button>
      )}
      <div className="user-list">
        <DataGrid
          rows={users}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageSize,
              },
            },
          }}
          pagination
          autoHeight
        />
      </div>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>
          {isActivating
            ? t('userList.activateUser')
            : t('userList.deactivateUser')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('userList.confirmMessage', {
              action: isActivating
                ? t('userList.activate')
                : t('userList.deactivate'),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            {t('userList.cancel')}
          </Button>
          <Button onClick={confirmActivateDeactivate} color="primary">
            {t('userList.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserList;
