import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { fetchCorporates } from '../../../services/corporateService';
import Icons from '../../../utils/icons/Icons';
import {
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import './CorporateList.scss';

const { CreateIcon, EditIcon } = Icons;

const CorporateList = () => {
  const { t } = useTranslation();
  const [corporates, setCorporates] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCorporates()
      .then((response) => setCorporates(response))
      .catch((error) => console.error('Error fetching corporates:', error));
  }, []);

  const columns = [
    {
      field: 'company_name',
      headerName: t('corporateList.corporateName'),
      flex: 1,
    },
    {
      field: 'contact_person',
      headerName: t('corporateList.contactName'),
      flex: 1,
    },
    {
      field: 'contact_email',
      headerName: t('corporateList.contactEmail'),
      flex: 1.5,
    },
    {
      field: 'contact_number',
      headerName: t('corporateList.contactNumber'),
      flex: 1,
    },
    {
      field: 'edit',
      headerName: t('corporateList.edit'),
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={t('corporateList.edit')}>
          <IconButton
            component="a"
            href={`/corporates/${params.row.id}/edit`}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="corporate-list-container">
      <h2>{t('corporateList.title')}</h2>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/corporates/create')}
        className="create-button"
        startIcon={<CreateIcon />}
      >
        {t('corporateList.createNewCorporate')}
      </Button>
      <div className="corporate-list">
        <DataGrid
          rows={corporates}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageSize,
              },
            },
          }}
          pagination
          autoHeight
        />
      </div>
    </div>
  );
};

export default CorporateList;
