import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './FlightClassDropdown.scss'; // Import the SCSS file

const FlightClassDropdown = ({ selectedClass, onClassChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const flightClasses = ['Economy', 'Premium Economy', 'Business', 'First'];

  const handleClassChange = (event) => {
    const selectedValue = event.target.value;
    onClassChange(selectedValue);
    setIsOpen(false);
  };

  return (
    <div className="dropdown">
      <button
        type="button"
        className={`dropdown-toggle ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedClass} <ExpandMoreIcon className="expand-icon" />
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          {flightClasses.map((flightClass) => (
            <label key={flightClass} className="dropdown-item">
              <input
                type="radio"
                value={flightClass}
                checked={selectedClass === flightClass}
                onChange={handleClassChange}
              />
              {flightClass}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default FlightClassDropdown;
