// src/routesConfig.js
import Home from './pages/home/Home';
import Unauthorized from './pages/unauthorized/Unauthorized';
import UserList from './pages/users/userList/UserList';
import UserCreate from './pages/users/userCreate/UserCreate';
import UserEdit from './pages/users/userEdit/UserEdit';
import BudgetCreate from './pages/budget/budgetCreate/BudgetCreate';
import BudgetEdit from './pages/budget/budgetEdit/BudgetEdit';
import BudgetList from './pages/budget/budgetList/BudgetList';
import BudgetAllocate from './pages/budget/budgetAllocate/BudgetAllocate';
import CorporateCreate from './pages/corporates/corporateCreate/CorporateCreate';
import CorporateList from './pages/corporates/corporateList/CorporateList';
import CorporateEdit from './pages/corporates/corporateEdit/CorporateEdit';
import DepartmentCreate from './pages/departments/departmentCreate/DepartmentCreate';
import DepartmentList from './pages/departments/departmentList/DepartmentList';
import DepartmentEdit from './pages/departments/departmentEdit/DepartmentEdit';
import PolicyList from './pages/policies/policyList/PolicyList';
import PolicyCreate from './pages/policies/policyCreate/PolicyCreate';
import PolicyEdit from './pages/policies/policyEdit/PolicyEdit';
import PolicyAssign from './pages/policies/policyAssign/PolicyAssign';
import ExpenseList from './pages/expenses/expenseList/ExpenseList'; // New Expense List component
import ExpenseCreate from './pages/expenses/expenseCreate/ExpenseCreate'; // New Expense Create component
import ExpenseEdit from './pages/expenses/expenseEdit/ExpenseEdit'; // New Expense Edit component
//import FlightSearch from './pages/flights/flightSearch/FlightSearch';
//import SelectFlight from './pages/flights/selectFlight/SelectFlight';
//import PassengerInfo from './pages/flights/passengerInfo/PassengerInfo';
//import ReviewBooking from './pages/flights/reviewBooking/ReviewBooking';
//import Payment from './pages/flights/payment/Payment';
//import Confirmation from './pages/flights/confirmation/Confirmation';
//import withBookingProvider from './hoc/withBookingProvider'; // Import the HOC
import NotFound from './pages/notFound/NotFound';
const routesConfig = [
  {
    path: '/',
    component: Home,
    requiredRoles: [], // Accessible to all authenticated users
  },
  {
    path: '/users',
    component: UserList,
    requiredRoles: [
      'system_admin',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
  },
  {
    path: '/users/create',
    component: UserCreate,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/users/:id/edit',
    component: UserEdit,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/corporates',
    component: CorporateList,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/corporates/create',
    component: CorporateCreate,
    requiredRoles: ['system_admin'],
  },
  {
    path: '/corporates/:id/edit',
    component: CorporateEdit,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/departments',
    component: DepartmentList,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/departments/create',
    component: DepartmentCreate,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/departments/:id/edit',
    component: DepartmentEdit,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/budgets',
    component: BudgetList,
    requiredRoles: [
      'system_admin',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
  },
  {
    path: '/budgets/create',
    component: BudgetCreate,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/budgets/:id/edit',
    component: BudgetEdit,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/budgets/:id/allocate',
    component: BudgetAllocate,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/policies',
    component: PolicyList,
    requiredRoles: [
      'system_admin',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
  },
  {
    path: '/policies/create',
    component: PolicyCreate,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/policies/:id/edit',
    component: PolicyEdit,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/policies/:id/assign',
    component: PolicyAssign,
    requiredRoles: ['system_admin', 'corporate_admin'],
  },
  {
    path: '/expenses',
    component: ExpenseList,
    requiredRoles: [
      'system_admin',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
  },
  {
    path: '/expenses/create',
    component: ExpenseCreate,
    requiredRoles: [
      'system_admin',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
  },
  {
    path: '/expenses/:id/edit',
    component: ExpenseEdit,
    requiredRoles: [
      'system_admin',
      'corporate_admin',
      'corporate_manager',
      'corporate_employee',
    ],
  },
  {
    path: '*',
    component: NotFound,
    requiredRoles: [], // Accessible to all users
  },
  {
    path: '/unauthorized',
    component: Unauthorized,
    requiredRoles: [], // Accessible to all authenticated users
  },
  // Add more routes here
];

export default routesConfig;
