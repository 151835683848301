import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import useLogProvider from '../hooks/UseLogProvider';

// Create the BookingContext with an initial value of null
export const BookingContext = createContext(null);

// BookingProvider component: This is the context provider that holds the booking data
export const BookingProvider = ({ children }) => {
  // Initialize state with data from localStorage if available, otherwise set defaults
  const [bookingData, setBookingData] = useState(() => {
    const storedData = localStorage.getItem('bookingData');
    return storedData
      ? JSON.parse(storedData)
      : {
          flightResults: null, // Initially, there are no flight results
          searchCriteria: null, // Search criteria persists between navigation steps
          selectedFlight: null, // Persist the selected flight across different pages
          session_id: null, // session_id for API calls
          fare_source_code: null, // Fare source code for the selected flight
          passengers: [], // Array of passenger details, initially empty
          paymentInfo: null, // Payment information, which remains unchanged unless updated
        };
  });

  /**
   * Function to update the booking data and save it to localStorage
   * This function ensures that any updates to booking data are merged with previous state
   */
  const updateBookingData = useCallback(
    async (data) => {
      // Log the current booking data before the update
      console.log(
        '%c[BookingContext] Previous Booking Data:',
        'color: blue;',
        bookingData
      );

      // Log the new data to be updated
      console.log(
        '%c[BookingContext] New Data to Update:',
        'color: green;',
        data
      );

      if (!data.selectedFlight && !data.session_id) {
        console.warn('Selected flight or session_id not provided', data);
      }

      // Await resolution of the new data (in case it's a promise or asynchronous process)
      const resolvedData = await Promise.resolve(data);

      // Update the state with new booking data
      setBookingData((prevData) => {
        const updatedData = { ...prevData, ...resolvedData };

        // Log the updated booking data after the change
        console.log(
          '%c[BookingContext] Updated Booking Data:',
          'color: purple;',
          updatedData
        );

        // Store the updated data in localStorage, but exclude flightResults to always trigger a fresh API search
        localStorage.setItem(
          'bookingData',
          JSON.stringify({
            ...updatedData,
            flightResults: null, // Ensure flightResults are always re-fetched to reflect new search data
          })
        );

        return updatedData; // Return the updated state
      });
    },
    [bookingData]
  );

  /**
   * Function to reset flight results and optionally session details
   */
  const resetFlightResults = useCallback(() => {
    setBookingData((prevData) => {
      const updatedData = {
        ...prevData,
        flightResults: null, // Clear the flight results
        session_id: null, // Optionally reset session ID
        fare_source_code: null, // Optionally reset fare source code
      };

      // Log the reset action
      console.log(
        '%c[BookingContext] Reset flightResults and session details:',
        'color: red;',
        updatedData
      );

      return updatedData; // Return the updated data with cleared flight results
    });
  }, []);

  // Log the current booking data every time it changes
  useEffect(() => {
    console.log(
      '%c[BookingContext] Current Booking Data on Render:',
      'color: orange;',
      bookingData
    );
  }, [bookingData]); // Dependency: This runs every time bookingData changes

  // Log the provider usage using a custom hook
  useLogProvider('BookingProvider');

  /**
   * Render the BookingContext.Provider and pass the bookingData, updateBookingData, and resetFlightResults functions as context
   */
  return (
    <BookingContext.Provider
      value={{ bookingData, updateBookingData, resetFlightResults }}
    >
      {children} {/* Render child components that consume the BookingContext */}
    </BookingContext.Provider>
  );
};

// Custom hook to access the BookingContext
export const useBooking = () => {
  const context = useContext(BookingContext); // Use useContext to get the current booking context
  if (!context) {
    throw new Error('useBooking must be used within a BookingProvider'); // Ensure this hook is used within a provider
  }

  // Log the accessed booking data whenever this hook is used
  console.log(
    '%c[useBooking] Accessed Booking Data:',
    'color: teal;',
    context.bookingData
  );

  return context; // Return the booking context, which includes bookingData and methods
};
