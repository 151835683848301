import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  Paper,
} from '@mui/material';
import {
  fetchDepartment,
  updateDepartment,
} from '../../../services/departmentService';
import { fetchCorporates } from '../../../services/corporateService';
import { fetchManagers } from '../../../services/userService';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/AuthContext';
import './DepartmentEdit.scss';

const DepartmentEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    departmentName: '',
    corporateId: '',
    managerId: '',
  });
  const [corporates, setCorporates] = useState([]);
  const [managers, setManagers] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchDepartment(id)
      .then((department) => {
        if (
          !department ||
          Object.keys(department).length === 0 ||
          (user.role_id === 4 && department.corporate_id !== user.corporate_id)
        ) {
          // Redirect to unauthorized if department data is empty or if user with role_id 4 tries to access a department that does not belong to their corporate
          navigate('/unauthorized');
        } else {
          setFormData({
            departmentName: department.department_name,
            corporateId: department.corporate_id,
            managerId: department.department_manager_id,
          });
          console.log('Fetched department data:', department);
          fetchManagers(department.corporate_id)
            .then((data) => {
              setManagers(Array.isArray(data) ? data : []);
              console.log('Fetched managers:', data);
            })
            .catch((error) => console.error('Error fetching managers:', error));
        }
      })
      .catch((error) => {
        console.error('Error fetching department:', error);
        // Redirect to unauthorized if there is an error
        navigate('/unauthorized');
      });

    if (user.role_id === 1) {
      fetchCorporates()
        .then((data) => {
          setCorporates(Array.isArray(data) ? data : []);
          console.log('Fetched corporates:', data);
        })
        .catch((error) => console.error('Error fetching corporates:', error));
    }
  }, [id, navigate, user.role_id, user.corporate_id]);

  const validateName = (name) => {
    const re = /^[A-Za-z\s]{3,}$/;
    return re.test(name);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let newErrors = { ...errors };

    if (name === 'departmentName' && !validateName(value)) {
      newErrors.departmentName = t('departmentEdit.validations.invalidName');
    } else {
      delete newErrors.departmentName;
    }

    if (name === 'corporateId') {
      fetchManagers(value)
        .then((data) => {
          setManagers(Array.isArray(data) ? data : []);
          console.log('Fetched managers:', data);
        })
        .catch((error) => console.error('Error fetching managers:', error));
    }

    setErrors(newErrors);
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.departmentName || !validateName(formData.departmentName)) {
      newErrors.departmentName = t('departmentEdit.validations.invalidName');
    }
    if (!formData.corporateId) {
      newErrors.corporateId = t('departmentEdit.validations.corporateRequired');
    }
    if (!formData.managerId) {
      newErrors.managerId = t('departmentEdit.validations.managerRequired');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    if (validate()) {
      try {
        await updateDepartment(id, formData);
        navigate('/departments');
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          const newErrors = {};
          error.response.data.errors.forEach((err) => {
            switch (err.code) {
              case 'departmentName_invalid':
                newErrors.departmentName = t(
                  'departmentEdit.validations.invalidName'
                );
                break;
              case 'corporate_required':
                newErrors.corporateId = t(
                  'departmentEdit.validations.corporateRequired'
                );
                break;
              case 'manager_required':
                newErrors.managerId = t(
                  'departmentEdit.validations.managerRequired'
                );
                break;
              default:
                newErrors.general = t(
                  'departmentEdit.validations.unknown_error'
                );
            }
          });
          console.log('Errors:', newErrors);
          setErrors(newErrors);
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrors({ general: error.response.data.message });
        } else {
          console.error('Error updating department:', error);
        }
      }
    }
  };

  return (
    <Paper className="department-edit-root">
      <Typography variant="h4" className="form-title">
        {t('departmentEdit.editDepartment')}
      </Typography>
      <form onSubmit={handleSubmit} className="department-edit-form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('departmentEdit.departmentName')}
              name="departmentName"
              value={formData.departmentName}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.departmentName}
              helperText={errors.departmentName}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          {user.role_id === 1 && (
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                required
                error={!!errors.corporateId}
                className="form-control"
              >
                <InputLabel
                  className="textField-label select-label"
                  sx={{ backgroundColor: 'white', px: 1 }}
                >
                  {t('departmentEdit.corporate')}
                </InputLabel>
                <Select
                  name="corporateId"
                  value={formData.corporateId}
                  onChange={handleChange}
                >
                  {corporates.map((corporate) => (
                    <MenuItem key={corporate.id} value={corporate.id}>
                      {corporate.company_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.corporateId}</FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!!errors.managerId}
              className="form-control"
            >
              <InputLabel
                className="textField-label select-label"
                sx={{ backgroundColor: 'white', px: 1 }}
              >
                {t('departmentEdit.manager')}
              </InputLabel>
              <Select
                name="managerId"
                value={formData.managerId}
                onChange={handleChange}
              >
                {managers.map((manager) => (
                  <MenuItem key={manager.id} value={manager.id}>
                    {`${manager.first_name} ${manager.last_name}`}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.managerId}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {errors.general && (
          <Typography color="error" variant="body2">
            {errors.general}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="submit-button"
        >
          {t('departmentEdit.updateDepartment')}
        </Button>
      </form>
    </Paper>
  );
};

export default DepartmentEdit;
