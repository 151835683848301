import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Grid, Typography, Paper } from '@mui/material';
import {
  fetchCorporate,
  updateCorporate,
} from '../../../services/corporateService';
import { useTranslation } from 'react-i18next';
import './CorporateEdit.scss';

const CorporateEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    corporateName: '',
    contactName: '',
    contactEmail: '',
    contactNumber: '',
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchCorporate(id)
      .then((corporate) => {
        if (!corporate || Object.keys(corporate).length === 0) {
          // Redirect to unauthorized if corporate data is empty
          navigate('/unauthorized');
        } else {
          setFormData({
            corporateName: corporate.company_name,
            contactName: corporate.contact_person,
            contactEmail: corporate.contact_email,
            contactNumber: corporate.contact_number,
          });
          console.log('Fetched corporate data:', corporate);
        }
      })
      .catch((error) => {
        console.error('Error fetching corporate:', error);
        // Redirect to unauthorized if there is an error
        navigate('/unauthorized');
      });
  }, [id, navigate]);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateName = (name) => {
    const re = /^[A-Za-z\s]{3,}$/;
    return re.test(name);
  };

  const validatePhoneNumber = (number) => {
    const re = /^[0-9]{10,15}$/;
    return re.test(number);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let newErrors = { ...errors };

    if (name === 'contactEmail' && !validateEmail(value)) {
      newErrors.contactEmail = t('corporateEdit.validations.invalidEmail');
    } else {
      delete newErrors.contactEmail;
    }

    if (name === 'corporateName' && !validateName(value)) {
      newErrors.corporateName = t('corporateEdit.validations.invalidName');
    } else {
      delete newErrors.corporateName;
    }

    if (name === 'contactName' && !validateName(value)) {
      newErrors.contactName = t('corporateEdit.validations.invalidName');
    } else {
      delete newErrors.contactName;
    }

    if (name === 'contactNumber' && !validatePhoneNumber(value)) {
      newErrors.contactNumber = t(
        'corporateEdit.validations.invalidPhoneNumber'
      );
    } else {
      delete newErrors.contactNumber;
    }

    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { corporateName, contactName, contactEmail, contactNumber } =
      formData;
    let newErrors = {};

    if (!validateEmail(contactEmail)) {
      newErrors.contactEmail = t('corporateEdit.validations.invalidEmail');
    }
    if (!validateName(corporateName)) {
      newErrors.corporateName = t('corporateEdit.validations.invalidName');
    }
    if (!validateName(contactName)) {
      newErrors.contactName = t('corporateEdit.validations.invalidName');
    }
    if (!validatePhoneNumber(contactNumber)) {
      newErrors.contactNumber = t(
        'corporateEdit.validations.invalidPhoneNumber'
      );
    }

    if (Object.keys(newErrors).length === 0) {
      try {
        await updateCorporate(id, formData);
        navigate('/corporates');
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          const newErrors = {};
          error.response.data.errors.forEach((err) => {
            switch (err.code) {
              case 'corporateName_invalid':
                newErrors.corporateName = t(
                  'corporateEdit.validations.invalidName'
                );
                break;
              case 'contactName_invalid':
                newErrors.contactName = t(
                  'corporateEdit.validations.invalidName'
                );
                break;
              case 'contactEmail_invalid':
                newErrors.contactEmail = t(
                  'corporateEdit.validations.invalidEmail'
                );
                break;
              case 'contactNumber_invalid':
                newErrors.contactNumber = t(
                  'corporateEdit.validations.invalidPhoneNumber'
                );
                break;
              default:
                newErrors.general = t(
                  'corporateEdit.validations.unknown_error'
                );
            }
          });
          console.log('Errors:', newErrors);
          setErrors(newErrors);
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrors({ general: error.response.data.message });
        } else {
          console.error('Error updating corporate:', error);
        }
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Paper className="corporate-edit-root">
      <Typography variant="h4" className="form-title">
        {t('corporateEdit.editCorporate')}
      </Typography>
      <form onSubmit={handleSubmit} className="corporate-edit-form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('corporateEdit.corporateName')}
              name="corporateName"
              value={formData.corporateName}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.corporateName}
              helperText={errors.corporateName}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('corporateEdit.contactName')}
              name="contactName"
              value={formData.contactName}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.contactName}
              helperText={errors.contactName}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('corporateEdit.contactEmail')}
              name="contactEmail"
              value={formData.contactEmail}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.contactEmail}
              helperText={errors.contactEmail}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('corporateEdit.contactNumber')}
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.contactNumber}
              helperText={errors.contactNumber}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
        </Grid>
        {errors.general && (
          <Typography color="error" variant="body2">
            {errors.general}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="submit-button"
        >
          {t('corporateEdit.updateCorporate')}
        </Button>
      </form>
    </Paper>
  );
};

export default CorporateEdit;
