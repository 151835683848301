import React from 'react';
import './Payment.scss';

const Payment = () => {
  return (
    <div className="payment">
      <h1>Payment</h1>
      {/* Payment form and details will be implemented here */}
    </div>
  );
};

export default Payment;
