// src/services/userService.js
import axiosInstance from './axiosConfig';

export const fetchUsers = () => {
  return axiosInstance
    .get('/v1/users')
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchUser = (id) => {
  return axiosInstance
    .get(`/v1/users/${id}`)
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchUsersRoles = () => {
  return axiosInstance
    .get('/v1/utils/roles')
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

/*
export const fetchCorporates = () => {
  return axiosInstance
    .get('/v1/utils/corporates')
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const fetchSingleCorporate = (corporateId) => {
  return axiosInstance
    .get(`/v1/utils/corporates/${corporateId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
*/
export const fetchManagers = (corporateId) => {
  return axiosInstance
    .get(`/v1/utils/managers/${corporateId}`)
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchReportingManagers = (userRoleId, corporateId) => {
  return axiosInstance
    .get(`/v1/utils/managers/${corporateId}/${userRoleId}`)
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const checkEmailExists = (email) => {
  return axiosInstance
    .get(`/v1/utils/email-exist/${email}`)
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data.exists;
    })
    .catch((error) => {
      throw error;
    });
};

export const createUser = (user) => {
  return axiosInstance
    .post('/v1/users', user)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateUser = (id, user) => {
  return axiosInstance
    .put(`/v1/users/${id}`, user)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const activateUser = (id) => {
  return axiosInstance
    .post(`/v1/users/${id}/activate`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deactivateUser = (id) => {
  return axiosInstance
    .post(`/v1/users/${id}/deactivate`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Fetches users by department ID.
 *
 * This function sends a GET request to the API endpoint to fetch users
 * belonging to a specific department identified by its ID. It utilizes
 * axios for making HTTP requests.
 *
 * @param {number|string} departmentId - The ID of the department for which to fetch users.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of user objects.
 * @throws {Error} - Throws an error if the request fails.
 */
export const fetchUsersByDepartmentId = (departmentId) => {
  return axiosInstance
    .get(`/v1/departments/${departmentId}/users`) // Make a GET request to the endpoint with the department ID.
    .then((response) => response.data) // Extract and return the data from the response.
    .catch((error) => {
      // Handle any errors that occur during the request.
      throw error; // Propagate the error to be handled by the caller.
    });
};

export const fetchUserLevels = () => {
  return axiosInstance
    .get('/v1/utils/levels')
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
/**
 * Fetches users by level ID and corporate ID.
 *
 * This function sends a GET request to the API endpoint to fetch users
 * belonging to a specific level and corporate. It utilizes axios for making HTTP requests.
 *
 * @param {number|string} levelId - The ID of the level for which to fetch users.
 * @param {number|string} corporateId - The ID of the corporate for which to fetch users.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of user objects.
 * @throws {Error} - Throws an error if the request fails.
 */
export const fetchUsersByLevelAndCorporate = (levelId, corporateId) => {
  return axiosInstance
    .get(`/v1/users/level/${levelId}/corporate/${corporateId}`) // Make a GET request to the endpoint with the level ID and corporate ID.
    .then((response) => response.data) // Extract and return the data from the response.
    .catch((error) => {
      // Handle any errors that occur during the request.
      throw error; // Propagate the error to be handled by the caller.
    });
};
/**
 * Fetches available user levels by corporate ID.
 *
 * This function sends a GET request to the API endpoint to fetch available user levels
 * for a specific corporate identified by its ID. It utilizes axios for making HTTP requests.
 *
 * @param {number|string} corporateId - The ID of the corporate for which to fetch available user levels.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of available user levels.
 * @throws {Error} - Throws an error if the request fails.
 */
export const fetchAvailableUserLevels = (corporateId) => {
  return axiosInstance
    .get(`/v1/utils/available-levels/${corporateId}`) // Make a GET request to the endpoint with the corporate ID.
    .then((response) => {
      console.log(response.data); // Extract and return the data from the response.
      return response.data;
    })
    .catch((error) => {
      // Handle any errors that occur during the request.
      throw error; // Propagate the error to be handled by the caller.
    });
};
/**
 * Fetches users by corporate ID.
 *
 * This function sends a GET request to the API endpoint to fetch users
 * belonging to a specific corporate identified by its ID. It utilizes axios for making HTTP requests.
 *
 * @param {number|string} corporateId - The ID of the corporate for which to fetch users.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of user objects.
 * @throws {Error} - Throws an error if the request fails.
 */
export const fetchUsersByCorporateId = (corporateId) => {
  return axiosInstance
    .get(`/v1/corporates/${corporateId}/users`) // Make a GET request to the endpoint with the corporate ID.
    .then((response) => {
      console.log('USERS:', response.data); // Log the response data for debugging purposes.
      return response.data; // Extract and return the data from the response.
    })
    .catch((error) => {
      // Handle any errors that occur during the request.
      throw error; // Propagate the error to be handled by the caller.
    });
};
