import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { fetchPolicies } from '../../../services/policyService';
import Icons from '../../../utils/icons/Icons';
import { Button, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './PolicyList.scss';

const { CreateIcon, EditIcon, AssignmentTurnedInIcon } = Icons;

const PolicyList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [policies, setPolicies] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchPolicies()
      .then((response) => setPolicies(response))
      .catch((error) => console.error('Error fetching policies:', error));
  }, []);

  const handleCreate = () => {
    navigate('/policies/create');
  };

  const columns = [
    { field: 'title', headerName: t('policyList.title'), flex: 1 },
    {
      field: 'description',
      headerName: t('policyList.description'),
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>
            {params.value.length > 50
              ? `${params.value.substring(0, 50)}...`
              : params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'company_name',
      headerName: t('policyList.corporateName'),
      flex: 1.5,
    },
    {
      field: 'edit',
      headerName: t('policyList.edit'),
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={t('policyList.edit')}>
          <IconButton
            onClick={() => navigate(`/policies/${params.row.id}/edit`)}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'assign',
      headerName: t('policyList.assign'),
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={t('policyList.assign')}>
          <IconButton
            onClick={() => navigate(`/policies/${params.row.id}/assign`)}
            color="secondary"
          >
            <AssignmentTurnedInIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="policy-list-container">
      <h2>{t('policyList.policyListTitle')}</h2>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreate}
        className="create-button"
        startIcon={<CreateIcon />}
      >
        {t('policyList.createPolicy')}
      </Button>
      <div className="policy-list">
        <DataGrid
          rows={policies}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageSize,
              },
            },
          }}
          pagination
          autoHeight
          disableColumnMenu
          disableSelectionOnClick
          componentsProps={{
            pagination: {
              rowsPerPageOptions: [5, 10, 20],
              labelRowsPerPage: t('policyList.rowsPerPage'),
            },
          }}
        />
      </div>
    </div>
  );
};

export default PolicyList;
