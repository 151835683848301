import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Paper,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  createUser,
  fetchUsersRoles,
  fetchReportingManagers,
  checkEmailExists,
  fetchUserLevels, // Import the new method
} from '../../../services/userService';
import { fetchCorporates } from '../../../services/corporateService';
import { useTranslation } from 'react-i18next';
import './UserCreate.scss';
import { generatePassword } from '../../../utils/passwordGenerator/PasswordGenerator';

const UserCreate = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    userType: '',
    corporateId: '',
    managerId: '',
    levelId: '', // Add levelId to formData
  });
  const [userTypes, setUserTypes] = useState([]);
  const [corporates, setCorporates] = useState([]);
  const [managers, setManagers] = useState([]);
  const [userLevels, setUserLevels] = useState([]); // Add state for userLevels
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsersRoles()
      .then((data) => {
        setUserTypes(data);
        console.log('Fetched user types:', data);
      })
      .catch((error) => console.error('Error fetching user types:', error));
  }, []);

  useEffect(() => {
    fetchUserLevels()
      .then((data) => {
        setUserLevels(data);
        console.log('Fetched user levels:', data);
      })
      .catch((error) => console.error('Error fetching user levels:', error));
  }, []);

  useEffect(() => {
    if (
      formData.userType &&
      (formData.userType === 1 ||
        formData.userType === 2 ||
        formData.userType === 3)
    ) {
      fetchCorporates()
        .then((data) => {
          if (data && data.length > 0) {
            setCorporates([data[0]]);
            setFormData((prevData) => ({
              ...prevData,
              corporateId: data[0].id,
            }));
          }
          console.log('Fetched first corporate:', data[0]);
        })
        .catch((error) => console.error('Error fetching corporates:', error));
    } else {
      fetchCorporates()
        .then((data) => {
          setCorporates(data);
          setFormData((prevData) => ({ ...prevData, corporateId: '' }));
          console.log('Fetched corporates:', data);
        })
        .catch((error) => console.error('Error fetching corporates:', error));
    }
  }, [formData.userType]);

  useEffect(() => {
    if (
      formData.userType &&
      formData.corporateId &&
      formData.userType !== 1 &&
      formData.userType !== 4
    ) {
      fetchReportingManagers(formData.userType, formData.corporateId)
        .then((data) => {
          setManagers(data);
          console.log('Fetched managers:', data);
        })
        .catch((error) => console.error('Error fetching managers:', error));
    } else {
      setManagers([]);
    }
  }, [formData.userType, formData.corporateId]);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
  };

  const validateName = (name) => {
    const re = /^[A-Za-z]{3,}$/;
    return re.test(name);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let newErrors = { ...errors };

    if (name === 'email') {
      if (!validateEmail(value)) {
        newErrors.email = t('userCreate.validation.invalidEmail');
      } else {
        try {
          const emailExists = await checkEmailExists(value);
          if (emailExists) {
            newErrors.email = t('userCreate.validation.emailExists');
          } else {
            delete newErrors.email;
          }
        } catch (error) {
          console.error('Error checking email existence:', error);
        }
      }
    }

    if (name === 'password' && !validatePassword(value)) {
      newErrors.password = t('userCreate.validation.weakPassword');
    } else {
      delete newErrors.password;
    }

    if ((name === 'firstName' || name === 'lastName') && !validateName(value)) {
      newErrors[name] = t('userCreate.validation.invalidName');
    } else {
      delete newErrors[name];
    }

    setErrors(newErrors);

    if (
      name === 'userType' &&
      (parseInt(value) === 1 || parseInt(value) === 4)
    ) {
      setFormData((prevData) => ({ ...prevData, managerId: '' }));
      setManagers([]);
    }
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setFormData({ ...formData, password: newPassword });

    if (!validatePassword(newPassword)) {
      setErrors({
        ...errors,
        password: t('userCreate.validation.weakPassword'),
      });
    } else {
      let newErrors = { ...errors };
      delete newErrors.password;
      setErrors(newErrors);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      password,
      userType,
      corporateId,
      managerId,
      levelId, // Include levelId in destructuring
    } = formData;
    let newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = t('userCreate.validation.invalidEmail');
    } else {
      try {
        const emailExists = await checkEmailExists(email);
        if (emailExists) {
          newErrors.email = t('userCreate.validation.emailExists');
        }
      } catch (error) {
        console.error('Error checking email existence:', error);
      }
    }
    if (!validatePassword(password)) {
      newErrors.password = t('userCreate.validation.weakPassword');
    }
    if (!validateName(firstName)) {
      newErrors.firstName = t('userCreate.validation.invalidName');
    }
    if (!validateName(lastName)) {
      newErrors.lastName = t('userCreate.validation.invalidName');
    }
    if (!userType) {
      newErrors.userType = t('userCreate.validation.userTypeRequired');
    }
    if (!corporateId) {
      newErrors.corporateId = t('userCreate.validation.corporateIdRequired');
    }
    if (!levelId) {
      newErrors.levelId = t('userCreate.validation.levelIdRequired');
    }

    if (Object.keys(newErrors).length === 0) {
      try {
        await createUser({
          ...formData,
          name: `${firstName} ${lastName}`,
        });
        navigate('/users');
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          const newErrors = {};
          error.response.data.errors.forEach((err) => {
            switch (err.code) {
              case 'firstName_invalid':
                newErrors.firstName = t('userCreate.validation.invalidName');
                break;
              case 'lastName_invalid':
                newErrors.lastName = t('userCreate.validation.invalidName');
                break;
              case 'email_invalid':
                newErrors.email = t('userCreate.validation.invalidEmail');
                break;
              case 'password_invalid':
                newErrors.password = t('userCreate.validation.weakPassword');
                break;
              case 'userType_required':
                newErrors.userType = t(
                  'userCreate.validation.userTypeRequired'
                );
                break;
              case 'corporateId_required':
                newErrors.corporateId = t(
                  'userCreate.validation.corporateIdRequired'
                );
                break;
              case 'levelId_required':
                newErrors.levelId = t('userCreate.validation.levelIdRequired');
                break;
              case 'managerId_required':
                newErrors.managerId = t(
                  'userCreate.validation.managerIdRequired'
                );
                break;
              default:
                newErrors.general = t('userCreate.validation.unknown_error');
            }
          });
          console.log('Errors:', newErrors);
          setErrors(newErrors);
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrors({ general: error.response.data.message });
        } else {
          console.error('Error creating user:', error);
        }
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Paper className="user-create-root">
      <Typography variant="h4" className="form-title">
        {t('userCreate.addNewUser')}
      </Typography>
      <form onSubmit={handleSubmit} className="user-create-form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('userCreate.firstName')}
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.firstName}
              helperText={errors.firstName}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('userCreate.lastName')}
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.lastName}
              helperText={errors.lastName}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('userCreate.email')}
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.email}
              helperText={errors.email}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('userCreate.password')}
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
            <Button
              onClick={handleGeneratePassword}
              variant="contained"
              color="primary"
              className="generate-button"
            >
              {t('userCreate.generatePassword')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!!errors.userType}
              className="form-control"
            >
              <InputLabel
                className="textField-label select-label"
                sx={{ backgroundColor: 'white', px: 1 }}
              >
                {t('userCreate.userType')}
              </InputLabel>
              <Select
                name="userType"
                value={formData.userType}
                onChange={handleChange}
              >
                {userTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.role_name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.userType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!!errors.corporateId}
              className="form-control"
            >
              <InputLabel
                className="textField-label select-label"
                sx={{ backgroundColor: 'white', px: 1 }}
              >
                {t('userCreate.corporateId')}
              </InputLabel>
              <Select
                name="corporateId"
                value={formData.corporateId}
                onChange={handleChange}
              >
                {corporates.map((corporate) => (
                  <MenuItem key={corporate.id} value={corporate.id}>
                    {corporate.company_name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.corporateId}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!!errors.levelId}
              className="form-control"
            >
              <InputLabel
                className="textField-label select-label"
                sx={{ backgroundColor: 'white', px: 1 }}
              >
                {t('userCreate.levelId')}
              </InputLabel>
              <Select
                name="levelId"
                value={formData.levelId}
                onChange={handleChange}
              >
                {userLevels.map((level) => (
                  <MenuItem key={level.id} value={level.id}>
                    {level.level_name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.levelId}</FormHelperText>
            </FormControl>
          </Grid>
          {formData.userType &&
            formData.userType !== 1 &&
            formData.userType !== 4 &&
            formData.corporateId && (
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  required
                  error={!!errors.managerId}
                  className="form-control"
                >
                  <InputLabel
                    className="textField-label select-label"
                    sx={{ backgroundColor: 'white', px: 1 }}
                  >
                    {t('userCreate.managerId')}
                  </InputLabel>
                  <Select
                    name="managerId"
                    value={formData.managerId}
                    onChange={handleChange}
                  >
                    {managers.map((manager) => (
                      <MenuItem key={manager.id} value={manager.id}>
                        {`${manager.first_name} ${manager.last_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.managerId}</FormHelperText>
                </FormControl>
              </Grid>
            )}
        </Grid>
        {errors.general && (
          <Typography variant="body2" className="errorText">
            {errors.general}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="submit-button"
        >
          {t('userCreate.addUser')}
        </Button>
      </form>
    </Paper>
  );
};

export default UserCreate;
