import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Grid, Typography, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createCorporate } from '../../../services/corporateService';
import './CorporateCreate.scss';

const CorporateCreate = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    corporateName: '',
    contactName: '',
    contactEmail: '',
    contactNumber: '',
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateName = (name) => {
    const re = /^[A-Za-z\s]{3,}$/;
    return re.test(name);
  };

  const validatePhoneNumber = (number) => {
    const re = /^[0-9]{10,15}$/;
    return re.test(number);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let newErrors = { ...errors };

    if (name === 'contactEmail' && !validateEmail(value)) {
      newErrors.contactEmail = t('corporateCreate.validations.invalidEmail');
    } else {
      delete newErrors.contactEmail;
    }

    if (name === 'corporateName' && !validateName(value)) {
      newErrors.corporateName = t('corporateCreate.validations.invalidName');
    } else {
      delete newErrors.corporateName;
    }

    if (name === 'contactName' && !validateName(value)) {
      newErrors.contactName = t('corporateCreate.validations.invalidName');
    } else {
      delete newErrors.contactName;
    }

    if (name === 'contactNumber' && !validatePhoneNumber(value)) {
      newErrors.contactNumber = t(
        'corporateCreate.validations.invalidPhoneNumber'
      );
    } else {
      delete newErrors.contactNumber;
    }

    setErrors(newErrors);
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.corporateName || !validateName(formData.corporateName)) {
      newErrors.corporateName = t('corporateCreate.validations.invalidName');
    }
    if (!formData.contactName || !validateName(formData.contactName)) {
      newErrors.contactName = t('corporateCreate.validations.invalidName');
    }
    if (!formData.contactEmail || !validateEmail(formData.contactEmail)) {
      newErrors.contactEmail = t('corporateCreate.validations.invalidEmail');
    }
    if (
      !formData.contactNumber ||
      !validatePhoneNumber(formData.contactNumber)
    ) {
      newErrors.contactNumber = t(
        'corporateCreate.validations.invalidPhoneNumber'
      );
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});

    if (validate()) {
      createCorporate(formData)
        .then(() => {
          navigate('/corporates');
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            const newErrors = {};
            error.response.data.errors.forEach((err) => {
              switch (err.code) {
                case 'corporateName_invalid':
                  newErrors.corporateName = t(
                    'corporateCreate.validations.invalidName'
                  );
                  break;
                case 'contactName_invalid':
                  newErrors.contactName = t(
                    'corporateCreate.validations.invalidName'
                  );
                  break;
                case 'contactEmail_invalid':
                  newErrors.contactEmail = t(
                    'corporateCreate.validations.invalidEmail'
                  );
                  break;
                case 'contactNumber_invalid':
                  newErrors.contactNumber = t(
                    'corporateCreate.validations.invalidPhoneNumber'
                  );
                  break;
                default:
                  newErrors.general = t(
                    'corporateCreate.validations.unknown_error'
                  );
              }
            });
            console.log('Errors:', newErrors);
            setErrors(newErrors);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setErrors({ general: error.response.data.message });
          } else {
            console.error('Error creating corporate:', error);
          }
        });
    }
  };

  return (
    <Paper className="corporate-create-root">
      <Typography variant="h4" className="form-title">
        {t('corporateCreate.addNewCorporate')}
      </Typography>
      <form onSubmit={handleSubmit} className="corporate-create-form">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('corporateCreate.corporateName')}
              name="corporateName"
              value={formData.corporateName}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.corporateName}
              helperText={errors.corporateName}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('corporateCreate.contactName')}
              name="contactName"
              value={formData.contactName}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.contactName}
              helperText={errors.contactName}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('corporateCreate.contactEmail')}
              name="contactEmail"
              value={formData.contactEmail}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.contactEmail}
              helperText={errors.contactEmail}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('corporateCreate.contactNumber')}
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.contactNumber}
              helperText={errors.contactNumber}
              InputLabelProps={{
                className: 'textField-label',
                sx: { backgroundColor: 'white', px: 1 },
              }}
            />
          </Grid>
        </Grid>
        {errors.general && (
          <Typography variant="body2" className="errorText">
            {errors.general}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="submit-button"
        >
          {t('corporateCreate.addCorporate')}
        </Button>
      </form>
    </Paper>
  );
};

export default CorporateCreate;
