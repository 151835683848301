import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  InputLabel,
  Grid,
  Card,
  CardContent,
  IconButton,
  Modal,
  Box,
  Switch,
  FormHelperText,
} from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import { fetchCorporates } from '../../../services/corporateService';
import {
  createPolicy,
  fetchFieldsWithValues,
  fetchPolicyTypes, // Import the service to fetch policy types
} from '../../../services/policyService';
import { useAuth } from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import './PolicyCreate.scss';

const steps = [
  'policyCreate.generalInfo',
  'policyCreate.addRules',
  'policyCreate.reviewPolicy',
];

const PolicyCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [policy, setPolicy] = useState({
    title: '',
    description: '',
    corporateId: '',
    policyTypeId: '', // Add policyTypeId to the state
    rules: [],
  });
  const [corporates, setCorporates] = useState([]);
  const [fields, setFields] = useState([]);
  const [policyTypes, setPolicyTypes] = useState([]); // State to store policy types
  const [openRuleModal, setOpenRuleModal] = useState(false);
  const [newRule, setNewRule] = useState({
    field: '',
    operator: '',
    value: '',
  });
  const { user } = useAuth();
  const [errors, setErrors] = useState({});

  const handleNext = () => {
    const newErrors = {};
    if (activeStep === 0) {
      if (!policy.title || policy.title.length < 4) {
        newErrors.title = t('policyCreate.validation.policyTitleInvalid');
      }
      if (!policy.description || policy.description.length < 10) {
        newErrors.description = t(
          'policyCreate.validation.policyDescriptionInvalid'
        );
      }
      if (!policy.corporateId) {
        newErrors.corporateId = t('policyCreate.validation.corporateRequired');
      }
      if (!policy.policyTypeId) {
        // Validate the policy type
        newErrors.policyTypeId = t(
          'policyCreate.validation.policyTypeRequired'
        );
      }
    }
    if (activeStep === 1 && policy.rules.length === 0) {
      newErrors.rules = t('policyCreate.validation.atLeastOneRule');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPolicy({ ...policy, [name]: value });
  };

  const handleRuleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedRules = policy.rules.map((rule, i) =>
      i === index ? { ...rule, [name]: value } : rule
    );
    setPolicy({ ...policy, rules: updatedRules });
  };

  const handleRemoveRule = (index) => {
    const updatedRules = policy.rules.filter((_, i) => i !== index);
    setPolicy({ ...policy, rules: updatedRules });
  };

  const fetchCorporateData = async () => {
    const response = await fetchCorporates();
    if (user.role_id === 1) {
      response.shift(); // Remove the first corporate if user is system_admin
    }
    setCorporates(response);
  };

  const fetchFieldsData = async () => {
    try {
      const fieldsData = await fetchFieldsWithValues();
      setFields(fieldsData);
    } catch (error) {
      console.error('Error fetching fields data:', error);
    }
  };

  const fetchPolicyTypesData = async () => {
    try {
      const types = await fetchPolicyTypes();
      setPolicyTypes(types);
    } catch (error) {
      console.error('Error fetching policy types:', error);
    }
  };

  const handleSubmit = async () => {
    console.log('Collected Policy Data:', policy);
    try {
      await createPolicy(policy);
      navigate(`/policies`);
    } catch (error) {
      setErrors({
        submit: t('policyCreate.validation.policyCreatedError'),
      });
    }
  };

  const handleOpenRuleModal = () => {
    setOpenRuleModal(true);
  };

  const handleCloseRuleModal = () => {
    setOpenRuleModal(false);
    setNewRule({
      field: '',
      operator: '',
      value: '',
    });
  };

  const handleAddRule = () => {
    const isDuplicate = policy.rules.some(
      (rule) =>
        rule.field === newRule.field &&
        rule.operator === newRule.operator &&
        rule.value === newRule.value
    );

    const isRedundant = policy.rules.some((rule) => {
      if (rule.field === newRule.field) {
        const ruleValue = rule.value;
        const newRuleValue = newRule.value;

        if (!isNaN(parseFloat(ruleValue)) && !isNaN(parseFloat(newRuleValue))) {
          return (
            (rule.operator === '<=' &&
              newRule.operator === '>=' &&
              parseFloat(ruleValue) === parseFloat(newRuleValue)) ||
            (rule.operator === '>=' &&
              newRule.operator === '<=' &&
              parseFloat(ruleValue) === parseFloat(newRuleValue))
          );
        }

        if (isNaN(parseFloat(ruleValue)) && isNaN(parseFloat(newRuleValue))) {
          return (
            (rule.operator === '<=' &&
              newRule.operator === '>=' &&
              ruleValue === newRuleValue) ||
            (rule.operator === '>=' &&
              newRule.operator === '<=' &&
              ruleValue === newRuleValue)
          );
        }
      }
      return false;
    });

    const isContradictory = policy.rules.some((rule) => {
      if (rule.field === newRule.field) {
        const ruleValue = rule.value;
        const newRuleValue = newRule.value;

        if (!isNaN(parseFloat(ruleValue)) && !isNaN(parseFloat(newRuleValue))) {
          return (
            (rule.operator === '<=' &&
              newRule.operator === '>=' &&
              parseFloat(ruleValue) === parseFloat(newRuleValue)) ||
            (rule.operator === '>=' &&
              newRule.operator === '<=' &&
              parseFloat(ruleValue) === parseFloat(newRuleValue)) ||
            (rule.operator === '==' &&
              newRule.operator === '!=' &&
              parseFloat(ruleValue) === parseFloat(newRuleValue)) ||
            (rule.operator === '!=' &&
              newRule.operator === '==' &&
              parseFloat(ruleValue) === parseFloat(newRuleValue))
          );
        }

        if (isNaN(parseFloat(ruleValue)) && isNaN(parseFloat(newRuleValue))) {
          return (
            (rule.operator === '<=' &&
              newRule.operator === '>=' &&
              ruleValue === newRuleValue) ||
            (rule.operator === '>=' &&
              newRule.operator === '<=' &&
              ruleValue === newRuleValue) ||
            (rule.operator === '==' &&
              newRule.operator === '!=' &&
              ruleValue === newRuleValue) ||
            (rule.operator === '!=' &&
              newRule.operator === '==' &&
              ruleValue === newRuleValue)
          );
        }
      }
      return false;
    });

    if (isDuplicate) {
      setErrors({
        newRule: t('policyCreate.validation.duplicateRule'),
      });
    } else if (isRedundant) {
      setErrors({
        newRule: t('policyCreate.validation.redundantRule'),
      });
    } else if (isContradictory) {
      setErrors({
        newRule: t('policyCreate.validation.contradictoryRule'),
      });
    } else {
      setErrors({});
      setPolicy({
        ...policy,
        rules: [...policy.rules, { ...newRule, enabled: true }],
      });
      handleCloseRuleModal();
    }
  };

  const handleToggleRule = (index) => {
    setPolicy((prevPolicy) => {
      const updatedRules = prevPolicy.rules.map((rule, i) => {
        if (i === index) {
          return { ...rule, enabled: !rule.enabled };
        }
        return rule;
      });
      return { ...prevPolicy, rules: updatedRules };
    });
  };

  useEffect(() => {
    fetchCorporateData();
    fetchFieldsData();
    fetchPolicyTypesData(); // Fetch policy types when component mounts
  }, []);

  const getFieldLabel = (fieldId) => {
    const field = fields.find((f) => f.id === fieldId);
    return field ? field.label : fieldId;
  };

  const getValueLabel = (fieldId, valueId) => {
    const field = fields.find((f) => f.id === fieldId);
    const value = field ? field.values.find((v) => v.id === valueId) : null;
    console.log('Getting value label for:', fieldId, valueId, value);
    return value ? value.value : valueId;
  };

  return (
    <Container className="policy-create-container">
      <Paper elevation={3} className="paper">
        <Typography variant="h4" component="h2" gutterBottom>
          {t('policyCreate.createPolicy')}
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === 0 && (
            <div className="step-content">
              <TextField
                label={t('policyCreate.policyTitle')}
                name="title"
                value={policy.title}
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
                error={!!errors.title}
                helperText={errors.title}
              />
              <TextField
                label={t('policyCreate.policyDescription')}
                name="description"
                value={policy.description}
                onChange={handleChange}
                multiline
                rows={4}
                required
                fullWidth
                margin="normal"
                error={!!errors.description}
                helperText={errors.description}
              />
              <FormControl
                fullWidth
                margin="normal"
                error={!!errors.corporateId}
              >
                <InputLabel>{t('policyCreate.corporate')}</InputLabel>
                <Select
                  name="corporateId"
                  value={policy.corporateId}
                  onChange={handleChange}
                  required
                >
                  {corporates.map((corporate) => (
                    <MenuItem key={corporate.id} value={corporate.id}>
                      {corporate.company_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.corporateId}</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                error={!!errors.policyTypeId}
              >
                <InputLabel>{t('policyCreate.policyType')}</InputLabel>
                <Select
                  name="policyTypeId"
                  value={policy.policyTypeId}
                  onChange={handleChange}
                  required
                >
                  {policyTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.policy_type}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.policyTypeId}</FormHelperText>
              </FormControl>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  color="primary"
                >
                  {t('policyCreate.next')}
                </Button>
              </Box>
            </div>
          )}
          {activeStep === 1 && (
            <div className="step-content">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h4">
                    {t('policyCreate.addRules')}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={handleOpenRuleModal}
                  >
                    {t('policyCreate.addRule')}
                  </Button>
                  {errors.rules && (
                    <Typography
                      variant="body2"
                      color="error"
                      className="validation-error"
                    >
                      {errors.rules}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div className="rule-list">
                    {policy.rules.map((rule, index) => (
                      <Card
                        key={index}
                        className="rule-card"
                        sx={{ marginBottom: 2 }}
                      >
                        <CardContent className="rule-content">
                          <Typography>{getFieldLabel(rule.field)}</Typography>
                          <Typography>{rule.operator}</Typography>
                          <Typography>
                            {getValueLabel(rule.field, rule.value)}
                          </Typography>
                        </CardContent>
                        <div className="rule-actions">
                          <Switch
                            checked={rule.enabled}
                            onChange={() => handleToggleRule(index)}
                            color="primary"
                          />
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleRemoveRule(index)}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      </Card>
                    ))}
                  </div>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button onClick={handleBack}>{t('policyCreate.back')}</Button>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  color="primary"
                  ml={2}
                >
                  {t('policyCreate.next')}
                </Button>
              </Box>
            </div>
          )}
          {activeStep === 2 && (
            <div className="step-content">
              <Typography variant="h6" component="h4" gutterBottom>
                {t('policyCreate.reviewPolicy')}
              </Typography>
              <Box mb={2}>
                <Card sx={{ marginBottom: 2 }}>
                  <CardContent>
                    <Typography variant="h6" component="h5">
                      {t('policyCreate.policyInfo')}
                    </Typography>
                    <Typography variant="body1">
                      <strong>{t('policyCreate.title')}:</strong> {policy.title}
                    </Typography>
                    <Typography variant="body1">
                      <strong>{t('policyCreate.description')}:</strong>{' '}
                      {policy.description}
                    </Typography>
                    <Typography variant="body1">
                      <strong>{t('policyCreate.corporateName')}:</strong>{' '}
                      {
                        corporates.find((c) => c.id === policy.corporateId)
                          ?.company_name
                      }
                    </Typography>
                    <Typography variant="body1">
                      <strong>{t('policyCreate.policyType')}:</strong>{' '}
                      {
                        policyTypes.find(
                          (type) => type.id === policy.policyTypeId
                        )?.policy_type
                      }
                    </Typography>
                    <Button
                      onClick={() => setActiveStep(0)}
                      variant="outlined"
                      color="primary"
                    >
                      {t('policyCreate.edit')}
                    </Button>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h5">
                      {t('policyCreate.rules')}
                    </Typography>
                    {policy.rules.map((rule, index) => (
                      <Typography key={index} variant="body2">
                        {getFieldLabel(rule.field)} {rule.operator}{' '}
                        {getValueLabel(rule.field, rule.value)}
                      </Typography>
                    ))}
                    <Button
                      onClick={() => setActiveStep(1)}
                      variant="outlined"
                      color="primary"
                    >
                      {t('policyCreate.edit')}
                    </Button>
                  </CardContent>
                </Card>
              </Box>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button onClick={handleBack}>{t('policyCreate.back')}</Button>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  ml={2}
                >
                  {t('policyCreate.create')}
                </Button>
              </Box>
              {errors.submit && (
                <Typography
                  variant="body2"
                  color="error"
                  className="validation-error"
                >
                  {errors.submit}
                </Typography>
              )}
            </div>
          )}
        </div>
      </Paper>

      <Modal
        open={openRuleModal}
        onClose={handleCloseRuleModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Modal background overlay
        }}
      >
        <Box
          sx={{
            backgroundColor: '#384256',
            color: 'white',
            padding: 3,
            outline: 'none',
            borderRadius: 2,
            maxWidth: 500,
            width: '100%',
            boxShadow: 3,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {t('policyCreate.addCustomRule')}
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('policyCreate.field')}</InputLabel>
            <Select
              name="field"
              value={newRule.field}
              onChange={(e) =>
                setNewRule({ ...newRule, field: e.target.value })
              }
              error={!!errors.newRule}
            >
              {fields.map((field) => (
                <MenuItem key={field.id} value={field.id}>
                  {field.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.newRule}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('policyCreate.operator')}</InputLabel>
            <Select
              name="operator"
              value={newRule.operator}
              onChange={(e) =>
                setNewRule({ ...newRule, operator: e.target.value })
              }
            >
              <MenuItem value="==">{t('policyCreate.operatorEquals')}</MenuItem>
              <MenuItem value="!=">
                {t('policyCreate.operatorNotEquals')}
              </MenuItem>
              <MenuItem value="<">
                {t('policyCreate.operatorLessThan')}
              </MenuItem>
              <MenuItem value=">">
                {t('policyCreate.operatorGreaterThan')}
              </MenuItem>
              <MenuItem value="<=">
                {t('policyCreate.operatorLessThanOrEqual')}
              </MenuItem>
              <MenuItem value=">=">
                {t('policyCreate.operatorGreaterThanOrEqual')}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('policyCreate.value')}</InputLabel>
            <Select
              name="value"
              value={newRule.value}
              onChange={(e) =>
                setNewRule({ ...newRule, value: e.target.value })
              }
              disabled={!newRule.field}
            >
              {newRule.field &&
                fields
                  .find((field) => field.id === newRule.field)
                  ?.values.map((val) => (
                    <MenuItem key={val.id} value={val.id}>
                      {val.value}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <Box
            sx={{
              marginTop: 2,
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
            }}
          >
            <Button onClick={handleCloseRuleModal} color="secondary">
              {t('policyCreate.cancel')}
            </Button>
            <Button onClick={handleAddRule} color="primary">
              {t('policyCreate.addRule')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default PolicyCreate;
