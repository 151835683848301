// src/components/menu/Menu.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.scss';
import { useTranslation } from 'react-i18next';
import { menu } from '../../data.ts';
import Icons from '../../utils/icons/Icons';
import { useAuth } from '../../contexts/AuthContext';

const Menu = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  if (!user) return null;

  return (
    <div className="menu">
      {menu
        .filter((item) => item.roles.includes(user.role))
        .map((item) => (
          <div className="item" key={item.id}>
            <span className="title">{t(item.title)}</span>
            {item.listItems
              .filter((listItem) => listItem.roles.includes(user.role))
              .map((listItem) => {
                const IconComponent = Icons[listItem.icon];
                return (
                  <Link
                    to={listItem.url}
                    className="listItem"
                    key={listItem.id}
                  >
                    {IconComponent && <IconComponent />}
                    <span className="listItemTitle">{t(listItem.title)}</span>
                  </Link>
                );
              })}
          </div>
        ))}
    </div>
  );
};

export default Menu;
