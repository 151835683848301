import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import {
  fetchExpenseCategories,
  fetchExpenseById,
  updateExpense,
} from '../../../services/expenseService';
import {
  fetchCorporates,
  fetchCorporate,
} from '../../../services/corporateService';
import { fetchUsersByCorporateId } from '../../../services/userService';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/AuthContext';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Paper,
  Typography,
  Link,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PreviewIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import './ExpenseEdit.scss';

const EditExpense = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();

  const [categories, setCategories] = useState([]);
  const [corporates, setCorporates] = useState([]);
  const [users, setUsers] = useState([]);
  const [receipt, setReceipt] = useState(null);
  const [existingReceiptUrl, setExistingReceiptUrl] = useState('');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const [formData, setFormData] = useState({
    amount: '',
    expense_date: '',
    category_id: '',
    description: '',
    corporate_id: user.role_id !== 1 ? user.corporate_id : '',
    user_id: user.role_id === 6 ? user.id : '',
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setLoading(true);
    fetchExpenseById(id)
      .then((expense) => {
        setFormData({
          amount: expense.amount,
          expense_date: expense.expense_date,
          category_id: expense.category_id,
          description: expense.description,
          corporate_id: expense.corporate_id,
          user_id: expense.user_id,
        });
        console.log('URL Signed:', expense.receipt_presigned_url);
        setExistingReceiptUrl(expense.receipt_presigned_url);
        setLoading(false);
      })
      .catch(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: t('Failed to load expense data'),
        }));
        setLoading(false);
      });

    fetchExpenseCategories().then(setCategories);

    if (user.role_id === 1) {
      fetchCorporates().then(setCorporates);
    } else {
      fetchCorporate(user.corporate_id).then((data) => {
        setCorporates([data]);
      });
    }
  }, [id, user.role_id, user.corporate_id, t]);

  useEffect(() => {
    if (formData.corporate_id) {
      fetchUsersByCorporateId(formData.corporate_id).then(setUsers);
    }
  }, [formData.corporate_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.amount || isNaN(formData.amount) || formData.amount <= 0) {
      newErrors.amount = t('Amount must be a positive number.');
    }
    if (!formData.expense_date) {
      newErrors.expense_date = t('Date is required.');
    }
    if (!formData.category_id) {
      newErrors.category_id = t('Category is required.');
    }
    if (!formData.description || formData.description.length < 10) {
      newErrors.description = t(
        'Description must be at least 10 characters long.'
      );
    }
    if (user.role_id === 1 && !formData.corporate_id) {
      newErrors.corporate_id = t('Corporate is required.');
    }
    if (!formData.user_id) {
      newErrors.user_id = t('User is required.');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});

    if (validate()) {
      setLoading(true);

      const expenseData = new FormData();
      expenseData.append('amount', formData.amount);
      expenseData.append('expense_date', formData.expense_date);
      expenseData.append('category_id', formData.category_id);
      expenseData.append('description', formData.description);
      expenseData.append('corporate_id', formData.corporate_id);
      expenseData.append('user_id', formData.user_id);
      if (receipt instanceof File) {
        expenseData.append('receipt', receipt);
      }

      // Log the submitted data for debugging purposes
      console.log('Submitting Expense Data:');
      for (let [key, value] of expenseData.entries()) {
        console.log(`${key}: ${value}`);
      }

      updateExpense(id, expenseData)
        .then(() => {
          navigate('/expenses');
        })
        .catch((error) => {
          const newErrors = {};
          if (error.response && error.response.status === 400) {
            const validationErrors = error.response.data.errors;
            validationErrors.forEach((err) => {
              switch (err.code) {
                case 'amount_invalid':
                  newErrors.amount = err.message;
                  break;
                case 'expense_date_invalid':
                  newErrors.expense_date = err.message;
                  break;
                case 'category_required':
                  newErrors.category_id = err.message;
                  break;
                case 'description_invalid':
                  newErrors.description = err.message;
                  break;
                case 'user_id_required':
                  newErrors.user_id = err.message;
                  break;
                default:
                  newErrors.general = t('An unexpected error occurred.');
              }
            });
          } else if (error.response && error.response.status === 500) {
            newErrors.general =
              error.response.data.error ||
              error.response.data.message ||
              t('An unexpected error occurred. Please try again later.');
          } else {
            newErrors.general =
              error?.response?.data?.message || t('Failed to update expense');
          }
          setErrors(newErrors);
          setLoading(false);
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setReceipt(acceptedFiles[0]);
    },
    accept: '.jpg,.jpeg,.png,.pdf',
    maxFiles: 1,
  });

  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
  };

  return (
    <Container className="expense-edit-container" maxWidth="sm">
      <Paper elevation={3} className="paper">
        <Typography variant="h4" component="h2" gutterBottom>
          {t('Edit Expense')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label={t('Amount')}
            name="amount"
            type="number"
            value={formData.amount || ''}
            onChange={handleChange}
            error={!!errors.amount}
            helperText={errors.amount}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('Date')}
            name="expense_date"
            type="date"
            value={formData.expense_date || ''}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            error={!!errors.expense_date}
            helperText={errors.expense_date}
            required
            fullWidth
            margin="normal"
          />
          {user.role_id === 1 && (
            <FormControl
              fullWidth
              margin="normal"
              error={!!errors.corporate_id}
            >
              <InputLabel>{t('Corporate')}</InputLabel>
              <Select
                name="corporate_id"
                value={formData.corporate_id || ''}
                onChange={handleChange}
                required
              >
                {corporates.map((corporate) => (
                  <MenuItem key={corporate.id} value={corporate.id}>
                    {corporate.company_name}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2" color="error">
                {errors.corporate_id}
              </Typography>
            </FormControl>
          )}
          <FormControl fullWidth margin="normal" error={!!errors.user_id}>
            <InputLabel>{t('User')}</InputLabel>
            <Select
              name="user_id"
              value={formData.user_id || ''}
              onChange={handleChange}
              required
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.full_name}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body2" color="error">
              {errors.user_id}
            </Typography>
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!errors.category_id}>
            <InputLabel>{t('Category')}</InputLabel>
            <Select
              name="category_id"
              value={formData.category_id || ''}
              onChange={handleChange}
              required
            >
              <MenuItem value="" disabled>
                {t('Select a category')}
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.category_name}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body2" color="error">
              {errors.category_id}
            </Typography>
          </FormControl>
          <TextField
            label={t('Description')}
            name="description"
            value={formData.description || ''}
            onChange={handleChange}
            multiline
            rows={4}
            error={!!errors.description}
            helperText={errors.description}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal" error={!!errors.receipt}>
            <div>
              {existingReceiptUrl && !receipt && (
                <div style={{ marginBottom: '10px' }}>
                  <Typography variant="body2">
                    {t('Current Receipt:')}
                  </Typography>
                  <IconButton
                    onClick={() => setIsPreviewOpen(true)}
                    color="primary"
                  >
                    <PreviewIcon />
                  </IconButton>
                </div>
              )}
              <div
                {...getRootProps()}
                style={{
                  border: '2px dashed #ccc',
                  borderRadius: '4px',
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <input {...getInputProps()} />
                <CloudUploadIcon style={{ fontSize: '48px', color: '#999' }} />
                <Typography variant="body2">
                  {receipt instanceof File
                    ? receipt.name
                    : t(
                        'Drag & drop your receipt here, or click to select one'
                      )}
                </Typography>
              </div>
            </div>
            {errors.receipt && (
              <Typography variant="body2" color="error">
                {errors.receipt}
              </Typography>
            )}
          </FormControl>
          {errors.general && (
            <Typography variant="body2" color="error">
              {errors.general}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className="submit-button"
            disabled={loading}
          >
            {loading ? t('Updating...') : t('Update Expense')}
          </Button>
        </form>
      </Paper>

      <Dialog
        open={isPreviewOpen}
        onClose={handlePreviewClose}
        maxWidth="md"
        fullWidth
      >
        <DialogActions>
          <IconButton onClick={handlePreviewClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          {existingReceiptUrl.endsWith('.pdf') ? (
            <embed
              src={existingReceiptUrl}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          ) : (
            <img
              src={existingReceiptUrl}
              alt="Receipt Preview"
              style={{ maxWidth: '100%' }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default EditExpense;
