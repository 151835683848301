import axiosInstance from './axiosConfig';

export const fetchCorporates = () => {
  return axiosInstance
    .get('/v1/corporates')
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchCorporate = (id) => {
  return axiosInstance
    .get(`/v1/corporates/${id}`)
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createCorporate = (corporate) => {
  return axiosInstance
    .post('/v1/corporates', corporate)
    .then((response) => {
      console.log(response.data); // Log response data
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateCorporate = (id, corporate) => {
  return axiosInstance
    .put(`/v1/corporates/${id}`, corporate)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
