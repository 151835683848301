import './Footer.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <span>{t('app_name_app')}</span>
      <span>{t('copy_rights')}</span>
    </div>
  );
};

export default Footer;
