import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTripDetails } from '../../../services/flightService'; // Import the trip details service
import './FlightTicket.scss';

const FlightTicket = () => {
  const location = useLocation();
  const { uniqueID } = location.state || {};
  const [tripDetails, setTripDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (uniqueID) {
      // Call the trip details API
      getTripDetails(uniqueID)
        .then((data) => {
          setTripDetails(data?.TripDetailsResponse?.TripDetailsResult);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching trip details:', error);
          setLoading(false);
        });
    }
  }, [uniqueID]);

  if (loading) {
    return <div>Loading trip details...</div>;
  }

  if (!tripDetails) {
    return <div>Error fetching trip details.</div>;
  }

  const itinerary = tripDetails?.TravelItinerary || {};
  const passengers = itinerary?.ItineraryInfo?.CustomerInfos || [];
  const reservationItems = itinerary?.ItineraryInfo?.ReservationItems || [];
  const pricing = itinerary?.ItineraryInfo?.ItineraryPricing || {};

  return (
    <div className="flight-ticket">
      <h1>Flight Ticket</h1>

      <div className="ticket-details">
        {/* Booking Details */}
        <div className="trip-info">
          <h2>Booking Details</h2>
          <div className="ticket-item">
            <strong>Booking Status:</strong>{' '}
            <span>{itinerary.BookingStatus}</span>
          </div>
          <div className="ticket-item">
            <strong>Ticket Status:</strong>{' '}
            <span>{itinerary.TicketStatus}</span>
          </div>
          <div className="ticket-item">
            <strong>PNR:</strong>{' '}
            <span>{reservationItems[0]?.ReservationItem?.AirlinePNR}</span>
          </div>
          <div className="ticket-item">
            <strong>Destination:</strong> <span>{itinerary.Destination}</span>
          </div>
        </div>

        {/* Passenger Information */}
        <div className="passenger-info">
          <h2>Passenger Information</h2>
          {passengers.map((passenger, index) => (
            <div key={index} className="passenger-item">
              <div className="ticket-item">
                <strong>Passenger Name:</strong>{' '}
                <span>{`${passenger.CustomerInfo.PassengerTitle} ${passenger.CustomerInfo.PassengerFirstName} ${passenger.CustomerInfo.PassengerLastName}`}</span>
              </div>
              <div className="ticket-item">
                <strong>eTicket Number:</strong>{' '}
                <span>{passenger.CustomerInfo.eTicketNumber}</span>
              </div>
              <div className="ticket-item">
                <strong>Passenger Type:</strong>{' '}
                <span>{passenger.CustomerInfo.PassengerType}</span>
              </div>
            </div>
          ))}
        </div>

        {/* Flight Information */}
        <div className="flight-info">
          <h2>Flight Details</h2>
          {reservationItems.map((item, index) => (
            <div key={index} className="flight-item">
              <div className="ticket-item">
                <strong>Flight Number:</strong>{' '}
                <span>{item.ReservationItem.FlightNumber}</span>
              </div>
              <div className="ticket-item">
                <strong>Departure:</strong>{' '}
                <span>
                  {item.ReservationItem.DepartureAirportLocationCode} at{' '}
                  {new Date(
                    item.ReservationItem.DepartureDateTime
                  ).toLocaleString()}
                </span>
              </div>
              <div className="ticket-item">
                <strong>Arrival:</strong>{' '}
                <span>
                  {item.ReservationItem.ArrivalAirportLocationCode} at{' '}
                  {new Date(
                    item.ReservationItem.ArrivalDateTime
                  ).toLocaleString()}
                </span>
              </div>
              <div className="ticket-item">
                <strong>Cabin Class:</strong>{' '}
                <span>{item.ReservationItem.CabinClassText}</span>
              </div>
              <div className="ticket-item">
                <strong>Baggage Allowance:</strong>{' '}
                <span>{item.ReservationItem.Baggage}</span>
              </div>
            </div>
          ))}
        </div>

        {/* Pricing Information */}
        <div className="pricing-info">
          <h2>Pricing Information</h2>
          <div className="ticket-item">
            <strong>Base Fare:</strong>{' '}
            <span>
              {pricing?.EquiFare?.Amount} {pricing?.EquiFare?.CurrencyCode}
            </span>
          </div>
          <div className="ticket-item">
            <strong>Taxes:</strong>{' '}
            <span>
              {pricing?.Tax?.Amount} {pricing?.Tax?.CurrencyCode}
            </span>
          </div>
          <div className="ticket-item">
            <strong>Total Fare:</strong>{' '}
            <span>
              {pricing?.TotalFare?.Amount} {pricing?.TotalFare?.CurrencyCode}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightTicket;
