import axiosInstance from './axiosConfig';

/**
 * Fetch all expenses.
 *
 * @returns {Promise} A promise that resolves to the list of all expenses.
 */
export const fetchAllExpenses = () => {
  return axiosInstance
    .get('/v1/expenses')
    .then((response) => response.data)
    .catch((error) => {
      // Handle error by throwing it to be caught by the calling function
      throw error;
    });
};

/**
 * Fetch expense by ID.
 *
 * @param {number} id - The ID of the expense to fetch.
 * @returns {Promise} A promise that resolves to the expense data.
 */
export const fetchExpenseById = (id) => {
  return axiosInstance
    .get(`/v1/expenses/${id}`)
    .then((response) => {
      console.log(response.data); // Log response data for debugging
      return response.data;
    })
    .catch((error) => {
      // Handle error by throwing it to be caught by the calling function
      throw error;
    });
};

/**
 * Create a new expense.
 *
 * @param {object} expenseData - The data for the new expense.
 * @returns {Promise} A promise that resolves to the created expense data.
 */
export const createExpense = (expenseData) => {
  return axiosInstance
    .post('/v1/expenses', expenseData)
    .then((response) => response.data)
    .catch((error) => {
      // Handle error by throwing it to be caught by the calling function
      throw error;
    });
};

/**
 * Update an expense by ID.
 *
 * @param {number} id - The ID of the expense to update.
 * @param {object} expenseData - The updated expense data.
 * @returns {Promise} A promise that resolves to the updated expense data.
 */
export const updateExpense = (id, expenseData) => {
  return axiosInstance
    .post(`/v1/expenses/${id}`, expenseData)
    .then((response) => response.data)
    .catch((error) => {
      // Handle error by throwing it to be caught by the calling function
      throw error;
    });
};

/**
 * Approve an expense by ID.
 *
 * @param {number} id - The ID of the expense to approve.
 * @returns {Promise} A promise that resolves to the approval result.
 */
export const approveExpense = (id) => {
  return axiosInstance
    .patch(`/v1/expenses/${id}/approve`)
    .then((response) => response.data)
    .catch((error) => {
      // Handle error by throwing it to be caught by the calling function
      throw error;
    });
};

/**
 * Reject an expense by ID.
 *
 * @param {number} id - The ID of the expense to reject.
 * @returns {Promise} A promise that resolves to the rejection result.
 */
export const rejectExpense = (id) => {
  return axiosInstance
    .patch(`/v1/expenses/${id}/reject`)
    .then((response) => response.data)
    .catch((error) => {
      // Handle error by throwing it to be caught by the calling function
      throw error;
    });
};

/**
 * Fetch all expense categories.
 *
 * @returns {Promise} A promise that resolves to the list of all expense categories.
 */
export const fetchExpenseCategories = () => {
  return axiosInstance
    .get('/v1/utils/expense-categories')
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
