// Import necessary React hooks and components from material-ui and other custom components
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CircularProgress, // Loading spinner to indicate progress
  Card, // Material UI Card component used to display flight details
  CardContent, // Content section inside the Card component
  Typography, // Typography for text formatting
  Button, // Button component for interaction
} from '@mui/material';
import { useBooking } from '../../../contexts/BookingContext'; // Custom hook to access booking data
import './FlightSelect.scss'; // Importing custom styles specific to this component
import { searchFlights } from '../../../services/flightService'; // Import the function to fetch flight search results from the API

// SelectFlight Component: This component is responsible for displaying flight search results
const FlightSelect = () => {
  const navigate = useNavigate(); // Use navigate hook for navigation between routes
  const { bookingData, updateBookingData, resetFlightResults } = useBooking(); // Access booking-related data and actions from BookingContext

  // Local state for handling loading and refresh states
  const [loading, setLoading] = useState(!bookingData.flightResults); // Loading state, default to true if no flight results
  const [hasRefreshed, setHasRefreshed] = useState(false); // Tracks if the page has been refreshed

  // useEffect hook to fetch flight results when the component mounts or when dependencies change
  useEffect(() => {
    const fetchFlights = async () => {
      // Check if there are no flight results, but there is searchCriteria, or if the page has been refreshed
      if (
        (!bookingData.flightResults && bookingData.searchCriteria) ||
        hasRefreshed
      ) {
        console.log('Fetching flight results using saved search criteria...');
        setLoading(true); // Set loading state to true while the API request is ongoing
        try {
          const response = await searchFlights(bookingData.searchCriteria); // Fetch flight results based on search criteria

          // Extract session_id and flight results
          const sessionId = response?.AirSearchResponse?.session_id;
          const flightResults =
            response?.AirSearchResponse?.AirSearchResult?.FareItineraries;

          if (!sessionId || !flightResults) {
            console.error('Error: Session ID or flight results are missing.');
            setLoading(false);
            return;
          }

          // Update bookingData with flight results and sessionId
          await updateBookingData({
            flightResults: flightResults,
            session_id: sessionId, // Save session ID for future use
          });

          setLoading(false); // Set loading to false after the API request completes
          setHasRefreshed(false); // Reset refresh flag after fetching new data
        } catch (error) {
          console.error('Error fetching flight results:', error); // Handle and log any errors encountered during the API request
          setLoading(false); // Ensure loading state is false even if the API request fails
        }
      } else if (!bookingData.flightResults && !bookingData.searchCriteria) {
        // If there are no search criteria, navigate the user back to the search form page
        navigate('/flights/search');
      }
    };

    fetchFlights(); // Trigger the API request
  }, [
    bookingData.flightResults, // Dependency: refetch flights if flight results are updated
    bookingData.searchCriteria, // Dependency: refetch flights if search criteria changes
    updateBookingData, // Dependency: ensure the hook knows about the latest updateBookingData method
    navigate, // Dependency: react-router's navigate to redirect
    hasRefreshed, // Dependency: refetch flights if the page has been refreshed
  ]);

  // useEffect to detect if the page was refreshed and reset flight results to trigger a new search
  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'reload') {
      console.log('Page was refreshed, triggering a new search...');
      resetFlightResults(); // Reset the stored flight results to ensure fresh data is fetched
      setHasRefreshed(true); // Set refresh flag to true
    }
  }, [resetFlightResults]);

  // Conditionally display a loading spinner if the data is still being fetched
  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress /> {/* Material UI's loading spinner */}
        <Typography variant="h6" className="loading-text">
          Loading flight results...
        </Typography>
      </div>
    );
  }

  // Handle flight selection and navigate to passenger info page
  const handleSelectFlight = (flight) => {
    // Log the flight data to inspect its structure
    console.log('Flight data on select:', flight);

    // Safely access the necessary properties using optional chaining
    const flightId =
      flight?.FareItinerary?.AirItineraryFareInfo?.FareSourceCode; // Use FareSourceCode as FlightId
    const sessionId = bookingData?.session_id; // Ensure sessionId is available in bookingData
    const fareSourceCode =
      flight?.FareItinerary?.AirItineraryFareInfo?.FareSourceCode; // This is the fare source code

    // Check if flightId, sessionId, or fareSourceCode are missing or undefined
    if (!flightId || !sessionId || !fareSourceCode) {
      console.error('Missing flight or session data:', {
        flightId,
        sessionId,
        fareSourceCode,
      });
      return; // Exit the function early if any necessary data is missing
    }

    // Update the booking context with the selected flight data
    updateBookingData({
      selectedFlight: flight,
      session_id: sessionId,
      fare_source_code: fareSourceCode,
    });

    // Navigate to the passenger info page after selecting the flight
    navigate('/flights/passenger-info');
  };

  // Extract flight data from the API response stored in the booking context
  const flights = bookingData.flightResults || []; // Ensure we're accessing the correct level

  // Log the flight data for debugging
  console.log('Flights to be displayed:', flights);

  // Return JSX to render the flight results
  return (
    <div className="select-flight">
      <Typography variant="h4" gutterBottom>
        Select Your Flight {/* Header for the flight selection */}
      </Typography>

      <div className="flight-options">
        {/* Loop through the list of flights and render each flight's details in a card */}
        {flights.length > 0 ? (
          flights.map((flight, index) => (
            <Card key={index} className="flight-card">
              <CardContent>
                {/* Display the marketing airline name */}
                <Typography variant="h6" className="flight-airline">
                  {
                    flight.FareItinerary?.OriginDestinationOptions[0]
                      ?.OriginDestinationOption[0]?.FlightSegment
                      ?.MarketingAirlineName
                  }
                </Typography>

                {/* Check if it's a return flight (round trip) */}
                {flight.FareItinerary?.DirectionInd === 'Return' ? (
                  <div className="return-flight">
                    {/* Render details for outbound flight segment */}
                    <div className="flight-segment">
                      <Typography variant="body1" gutterBottom>
                        <strong>Outbound Flight:</strong>{' '}
                      </Typography>
                      <Typography variant="body1">
                        {
                          flight.FareItinerary?.OriginDestinationOptions[0]
                            ?.OriginDestinationOption[0]?.FlightSegment
                            ?.DepartureAirportLocationCode
                        }{' '}
                        &rarr;{' '}
                        {
                          flight.FareItinerary?.OriginDestinationOptions[0]
                            ?.OriginDestinationOption[0]?.FlightSegment
                            ?.ArrivalAirportLocationCode
                        }
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Departure:{' '}
                        {new Date(
                          flight.FareItinerary?.OriginDestinationOptions[0]?.OriginDestinationOption[0]?.FlightSegment?.DepartureDateTime
                        ).toLocaleString()}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Arrival:{' '}
                        {new Date(
                          flight.FareItinerary?.OriginDestinationOptions[0]?.OriginDestinationOption[0]?.FlightSegment?.ArrivalDateTime
                        ).toLocaleString()}
                      </Typography>
                    </div>

                    {/* Render details for inbound flight segment */}
                    <div className="flight-segment">
                      <Typography variant="body1" gutterBottom>
                        <strong>Inbound Flight:</strong>{' '}
                      </Typography>
                      <Typography variant="body1">
                        {
                          flight.FareItinerary?.OriginDestinationOptions[1]
                            ?.OriginDestinationOption[0]?.FlightSegment
                            ?.DepartureAirportLocationCode
                        }{' '}
                        &rarr;{' '}
                        {
                          flight.FareItinerary?.OriginDestinationOptions[1]
                            ?.OriginDestinationOption[0]?.FlightSegment
                            ?.ArrivalAirportLocationCode
                        }
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Departure:{' '}
                        {new Date(
                          flight.FareItinerary?.OriginDestinationOptions[1]?.OriginDestinationOption[0]?.FlightSegment?.DepartureDateTime
                        ).toLocaleString()}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Arrival:{' '}
                        {new Date(
                          flight.FareItinerary?.OriginDestinationOptions[1]?.OriginDestinationOption[0]?.FlightSegment?.ArrivalDateTime
                        ).toLocaleString()}
                      </Typography>
                    </div>
                  </div>
                ) : (
                  // If it's a one-way flight, render the one-way flight segment
                  <div className="flight-segment">
                    <Typography variant="body1">
                      {
                        flight.FareItinerary?.OriginDestinationOptions[0]
                          ?.OriginDestinationOption[0]?.FlightSegment
                          ?.DepartureAirportLocationCode
                      }{' '}
                      &rarr;{' '}
                      {
                        flight.FareItinerary?.OriginDestinationOptions[0]
                          ?.OriginDestinationOption[0]?.FlightSegment
                          ?.ArrivalAirportLocationCode
                      }
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Departure:{' '}
                      {new Date(
                        flight.FareItinerary?.OriginDestinationOptions[0]?.OriginDestinationOption[0]?.FlightSegment?.DepartureDateTime
                      ).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Arrival:{' '}
                      {new Date(
                        flight.FareItinerary?.OriginDestinationOptions[0]?.OriginDestinationOption[0]?.FlightSegment?.ArrivalDateTime
                      ).toLocaleString()}
                    </Typography>
                  </div>
                )}

                {/* Display the total fare */}
                <Typography variant="h6" className="flight-price">
                  $
                  {
                    flight.FareItinerary?.AirItineraryFareInfo?.ItinTotalFares
                      ?.TotalFare?.Amount
                  }{' '}
                  {
                    flight.FareItinerary?.AirItineraryFareInfo?.ItinTotalFares
                      ?.TotalFare?.CurrencyCode
                  }
                </Typography>

                {/* Button to select the flight and proceed to the checkout page */}
                <Button
                  variant="contained"
                  color="primary"
                  className="select-button"
                  onClick={() => handleSelectFlight(flight)}
                >
                  Select
                </Button>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography variant="h6" className="no-flights-text">
            No flights available.
          </Typography>
        )}
      </div>
    </div>
  );
};

export default FlightSelect;
